import {Button, Col, InputRef, List, Row, Skeleton, Space, Tag, Typography} from "antd";
import React from "react";
import {
    ACCEPTANCE_MODES,
    APInvoiceTrackerDataRenderType,
    CURRENT_SHIPMENT_STATUSES,
    CurrentShipmentLogItemRenderType,
    DISTRIBUTOR_TYPES,
    FreightLogItemizedEntryRenderType,
    FreightLogItemizedRowRenderType,
    FreightLogRowRenderType,
    InquiryItemRenderType,
    InvoiceData,
    InvoiceDataRenderType,
    LedgerDataRenderType,
    OMSItemRenderType,
    OMSMap,
    OMSTrackerItemRenderType,
    POSSIBLE_OMS_TRACKER_ISSUES,
    SHIP_TO_VALUES,
    SUPPLIER_USERS,
    SellerSnapExportDataRenderType,
} from "../../types/OmegaTypes";
import {EditableColumnType} from "../omega/table/EditableCell";
import {GetExpandedFilter} from "./ExpandedFilterDropdown";
import {
    getASINRenderer,
    getBoolRenderer,
    getCopyCellRenderer,
    getDateRenderer,
    getDefaultFilterSorter,
    getDefaultDateSorter,
    getMaxPriceCellRenderer,
    getMinPriceCellRenderer,
    getModifiedCellRenderer,
    getNumberFilterSorter,
    getNumberRenderer,
    getPriceRenderer,
    getSalesRankCellRenderer,
    getShareRenderer,
    getURLRenderer,
    RED,
    getPOTrackerNotesCellRenderer,
    getBooleanFilter,
    getArrayRenderer,
    highlightOnCondition,
    getSellPriceCellRenderer,
    getDateFilter,
} from "./TableFilterSorters";
import NotesRenderer from "./NotesRenderer";
import {CARRIERS} from "../../types/CarrierType";
import dayjs from "dayjs";
import {copyToClipboard} from "../omega/ItemBrowser";
import {MailOutlined} from "@ant-design/icons";
import StrictNotesRenderer from "./StrictNotesRenderer";
import {isValidUrl} from "./common";
// import { SendToSlack } from '../../services/OmegaService';
// import { SLACK_CHANNEL_IDS } from './common';

export const makeColumnsEditable = <T,>(
    columns: EditableColumnType<T>[],
    handleSave?: (record: T, column?: keyof T) => void
): EditableColumnType<T>[] => {
    if (!handleSave) return columns;

    return columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record: T, index?: number) => {
                // Call the previous onCell function to not lose
                // previous values
                const onCellRes = col.onCell ? col.onCell(record, index) : undefined;
                return {
                    ...onCellRes,
                    record,
                    inputType: col.inputType || "text",
                    dataIndex: col.dataIndex,
                    inputOptions: col.inputOptions,
                    title: col.title,
                    editable: col.editable,
                    required: col.required,
                    handleSave: (record: T) => {
                        handleSave(record, col.dataIndex as keyof T);
                    },
                };
            },
        };
    }) as EditableColumnType<T>[];
};

export const makeMasterColumns = (searchInputRef: React.RefObject<InputRef>): EditableColumnType<OMSItemRenderType>[] => [
    {
        title: "ID",
        dataIndex: "_id",
        key: "_id",
        width: "135px",
        // fixed: "left",
        ...GetExpandedFilter([], "_id"),
    },
    {
        title: "Name",
        dataIndex: "Supplier_Name",
        key: "Supplier_Name",
        width: "135px",
        editable: true,
        inputType: "suppliers",
        // fixed: "left",
        // ...getDefaultFilterSorter('Supplier_Name', searchInputRef),
        ...GetExpandedFilter([], "Supplier_Name"),
    },
    {
        title: "Date",
        dataIndex: "Supplier_Date",
        key: "Supplier_Date",
        width: "135px",
        editable: true,
        inputType: "date",
        ...getDateRenderer(),
        ...getDateFilter("Supplier_Date"),
    },
    {
        title: "PO",
        dataIndex: "Supplier_PO",
        key: "Supplier_PO",
        width: "135px",
        editable: true,
        // fixed: "left",
        ...GetExpandedFilter([], "Supplier_PO"),
    },
    {
        title: "SO",
        dataIndex: "Supplier_SO",
        key: "Supplier_SO",
        width: "135px",
        editable: true,
        // fixed: "left",
        ...GetExpandedFilter([], "Supplier_SO"),
    },
    {
        title: "SKU",
        dataIndex: "Supplier_SKU",
        key: "Supplier_SKU",
        width: "135px",
        editable: true,
        // fixed: "left",
        ...GetExpandedFilter([], "Supplier_SKU"),
    },
    {
        title: "Title",
        dataIndex: "Supplier_Title",
        key: "Supplier_Title",
        width: "235px",
        editable: true,
        // fixed: "left",
        // ...getDefaultFilterSorter('Supplier_Title', searchInputRef),
        ...GetExpandedFilter([], "Supplier_Title"),
    },
    {
        title: "Notes",
        dataIndex: "Supplier_Notes",
        key: "Supplier_Notes",
        width: "135px",
        editable: true,
        // fixed: "left",
        ...getDefaultFilterSorter("Supplier_Notes", searchInputRef),
    },
    {
        title: "UPC",
        dataIndex: "UPC",
        key: "UPC",
        editable: true,
        width: "110px",
        ...GetExpandedFilter([], "UPC"),
    },
    {
        title: "Quantity",
        dataIndex: "Quantity",
        key: "Quantity",
        width: "110px",
        editable: true,
        inputType: "int",
        ...getNumberFilterSorter("Quantity"),
        ...getNumberRenderer(),
    },
    {
        title: "Cost",
        dataIndex: "Cost",
        key: "Cost",
        width: "110px",
        editable: true,
        inputType: "price",
        ...getNumberFilterSorter("Cost"),
        ...getPriceRenderer(),
    },
    {
        title: "Ship Requested",
        dataIndex: "Ship_Requested",
        key: "Ship_Requested",
        editable: true,
        inputType: "date",
        width: "110px",
        ...getDefaultFilterSorter("Ship_Requested", searchInputRef),
        ...getDateRenderer(),
    },
    {
        title: "MAP",
        dataIndex: "MAP",
        key: "MAP",
        width: "110px",
        editable: true,
        inputType: "price",
        ...getNumberFilterSorter("MAP"),
        ...getPriceRenderer(""),
    },
    {
        title: "S1 Pushed?",
        dataIndex: "S1_Pushed?",
        key: "S1_Pushed?",
        editable: true,
        width: "110px",
        inputType: "yes/no",
        ...getDefaultFilterSorter("S1_Pushed?", searchInputRef),
    },
    {
        title: "S1 Pushed By",
        dataIndex: "S1_Pushed_By",
        key: "S1_Pushed_By",
        editable: true,
        width: "110px",
        ...getDefaultFilterSorter("S1_Pushed_By", searchInputRef),
    },
    {
        title: "Checked?",
        dataIndex: "Checked?",
        key: "Checked?",
        editable: true,
        inputType: "yes/no",
        width: "110px",
        ...getDefaultFilterSorter("Checked?", searchInputRef),
    },
    {
        title: "UPC Match?",
        dataIndex: "Upc Match?",
        key: "Upc Match?",
        editable: true,
        inputType: "yes/no",
        width: "110px",
        ...getDefaultFilterSorter("Upc Match?", searchInputRef),
    },
    {
        title: "ASIN",
        dataIndex: "ASIN",
        key: "ASIN",
        editable: false,
        width: "110px",
        ...getASINRenderer(),
        ...GetExpandedFilter([], "ASIN"),
        ...getCopyCellRenderer("ASIN"),
    },
    {
        title: "Parent",
        dataIndex: "ParentASIN",
        key: "ParentASIN",
        width: "135px",
        sorter: {
            compare: (a, b) => (a.ParentASIN! > b.ParentASIN! ? 1 : a.ParentASIN === b.ParentASIN ? 0 : -1),
            multiple: 2,
        },
        editable: true,
        ...getASINRenderer(),
        ...GetExpandedFilter([], "ParentASIN"),
        ...getCopyCellRenderer("ParentASIN"),
    },
    {
        title: "AMZ Title",
        dataIndex: "AMZ_Title",
        key: "AMZ_Title",
        width: "230px",
        editable: true,
        // ...getDefaultFilterSorter('AMZ_Title', searchInputRef),
        ...GetExpandedFilter([], "AMZ_Title"),
    },
    {
        title: "AMZ Size Tier",
        dataIndex: "AMZ_SizeTier",
        key: "AMZ_SizeTier",
        editable: true,
        width: "110px",
        ...getDefaultFilterSorter("AMZ_SizeTier", searchInputRef),
    },
    {
        title: "AMZ Orphan",
        dataIndex: "AMZ_Orphan",
        key: "AMZ_Orphan",
        editable: true,
        width: "110px",
        ...getDefaultFilterSorter("AMZ_Orphan", searchInputRef),
    },
    {
        title: "S2 Pushed?",
        dataIndex: "S2_Pushed?",
        key: "S2_Pushed?",
        editable: true,
        inputType: "yes/no",
        width: "110px",
        ...getDefaultFilterSorter("S2_Pushed?", searchInputRef),
    },
    {
        title: "S2 Pushed By",
        dataIndex: "S2_Pushed_By",
        key: "S2_Pushed_By",
        editable: true,
        width: "110px",
        ...getDefaultFilterSorter("S2_Pushed_By", searchInputRef),
    },
    {
        title: "Min Price",
        dataIndex: "Min Price",
        key: "Min Price",
        width: "110px",
        editable: true,
        required: false,
        inputType: "price",
        ...getNumberFilterSorter("Min Price"),
        ...getPriceRenderer(),
    },
    {
        title: "Max Price/List",
        dataIndex: "Max Price/List",
        key: "Max Price/List",
        editable: true,
        inputType: "price",
        width: "110px",
        ...getNumberFilterSorter("Max Price/List"),
        ...getPriceRenderer(),
    },
    {
        title: "Sell Price",
        dataIndex: "Sell Price",
        key: "Sell Price",
        editable: true,
        inputType: "price",
        width: "110px",
        ...getNumberFilterSorter("Sell Price"),
        ...getPriceRenderer(),
    },
    {
        title: "Seasonal Tag",
        dataIndex: "Seasonal Tag",
        key: "Seasonal Tag",
        editable: true,
        inputType: "seasonal tags",
        width: "110px",
        ...getDefaultFilterSorter("Seasonal Tag", searchInputRef),
    },
    {
        title: "Replenishable?",
        dataIndex: "Replenishable?",
        key: "Replenishable?",
        editable: true,
        inputType: "bool",
        width: "110px",
        ...getBoolRenderer(),
        ...getDefaultFilterSorter("Replenishable?", searchInputRef),
    },
    {
        title: "Order Type",
        dataIndex: "OrderType",
        key: "OrderType",
        editable: true,
        inputType: "order types",
        width: "110px",
        ...getDefaultFilterSorter("OrderType", searchInputRef),
    },
    {
        title: "Vendor Type",
        dataIndex: "VendorType",
        key: "VendorType",
        editable: true,
        inputType: "vendor types",
        width: "110px",
        ...getDefaultFilterSorter("VendorType", searchInputRef),
    },
    {
        title: "Req Cancel?",
        dataIndex: "Req_Cancel?",
        key: "Req_Cancel?",
        editable: true,
        inputType: "yes/no",
        width: "110px",
        ...getDefaultFilterSorter("Req_Cancel?", searchInputRef),
    },
    {
        title: "Listing Issues?",
        dataIndex: "Listing Issues?",
        key: "Listing Issues?",
        width: "110px",
        inputType: "yes/no",
        editable: true,
        ...getDefaultFilterSorter("Listing Issues?", searchInputRef),
    },
    {
        title: "Shipped",
        dataIndex: "Shipped",
        key: "Shipped",
        width: "90px",
        ...getNumberFilterSorter("Shipped"),
        ...getNumberRenderer(),
    },
    {
        title: "Unshipped",
        dataIndex: "Unshipped",
        key: "Unshipped",
        width: "110px",
        ...getNumberFilterSorter("Unshipped"),
        ...getNumberRenderer(),
    },
    {
        title: "Ship Now",
        dataIndex: "Ship Now",
        key: "Ship Now",
        editable: true,
        inputType: "int",
        required: false,
        width: "110px",
        ...getNumberFilterSorter("Ship Now"),
        ...getNumberRenderer(),
    },
    {
        title: "Ship Later",
        dataIndex: "Ship Later",
        key: "Ship Later",
        editable: true,
        inputType: "int",
        required: false,
        width: "110px",
        ...getNumberFilterSorter("Ship Later"),
        ...getNumberRenderer(),
    },
    {
        title: "S3 Pushed?",
        dataIndex: "S3_Pushed?",
        key: "S3_Pushed?",
        width: "110px",
        inputType: "yes/no",
        editable: true,
        ...getDefaultFilterSorter("S3_Pushed?", searchInputRef),
    },
    {
        title: "S3 Pushed By",
        dataIndex: "S3_Pushed_By",
        key: "S3_Pushed_By",
        width: "110px",
        editable: true,
        ...getDefaultFilterSorter("S3_Pushed_By", searchInputRef),
    },
    {
        title: "Prelisted IL?",
        dataIndex: "Prelisted IL?",
        key: "Prelisted IL?",
        width: "110px",
        inputType: "yes/no",
        editable: true,
        ...GetExpandedFilter(["YES", "NO"], "Prelisted IL?"),
    },
    {
        title: "S4 Pushed?",
        dataIndex: "S4_Pushed?",
        key: "S4_Pushed?",
        width: "110px",
        inputType: "yes/no",
        editable: true,
        ...getDefaultFilterSorter("S4_Pushed?", searchInputRef),
    },
    {
        title: "S4 Pushed By",
        dataIndex: "S4_Pushed_By",
        key: "S4_Pushed_By",
        width: "110px",
        editable: true,
        ...getDefaultFilterSorter("S4_Pushed_By", searchInputRef),
    },
];

export const makeStage1Columns = (searchInputRef: React.RefObject<InputRef>): EditableColumnType<OMSItemRenderType>[] => [
    {
        title: "Name",
        dataIndex: "Supplier_Name",
        key: "Supplier_Name",
        width: "135px",
        editable: true,
        inputType: "suppliers",
        // fixed: "left",
        ...getDefaultFilterSorter("Supplier_Name", searchInputRef),
    },
    {
        title: "Date",
        dataIndex: "Supplier_Date",
        key: "Supplier_Date",
        width: "135px",
        editable: true,
        inputType: "date",
        // fixed: "left",
        ...getDefaultFilterSorter("Supplier_Date", searchInputRef),
        ...getDateRenderer(),
    },
    {
        title: "PO",
        dataIndex: "Supplier_PO",
        key: "Supplier_PO",
        width: "135px",
        editable: true,
        // fixed: "left",
        ...GetExpandedFilter([], "Supplier_PO"),
    },
    {
        title: "SO",
        dataIndex: "Supplier_SO",
        key: "Supplier_SO",
        width: "135px",
        editable: true,
        // fixed: "left",
        ...GetExpandedFilter([], "Supplier_SO"),
    },
    {
        title: "SKU",
        dataIndex: "Supplier_SKU",
        key: "Supplier_SKU",
        width: "135px",
        editable: true,
        // fixed: "left",
        ...GetExpandedFilter([], "Supplier_SKU"),
    },
    {
        title: "Title",
        dataIndex: "Supplier_Title",
        key: "Supplier_Title",
        width: "135px",
        editable: true,
        // fixed: "left",
        ...getDefaultFilterSorter("Supplier_Title", searchInputRef),
    },
    {
        title: "Notes",
        dataIndex: "Supplier_Notes",
        key: "Supplier_Notes",
        width: "135px",
        editable: true,
        // fixed: "left",
        ...getDefaultFilterSorter("Supplier_Notes", searchInputRef),
    },
    {
        title: "ASIN",
        dataIndex: "ASIN",
        key: "ASIN",
        editable: true,
        width: "110px",
        ...getASINRenderer(),
        ...GetExpandedFilter([], "ASIN"),
        ...getCopyCellRenderer("ASIN"),
    },
    {
        title: "UPC",
        dataIndex: "UPC",
        key: "UPC",
        editable: true,
        width: "110px",
        ...GetExpandedFilter([], "UPC"),
    },
    {
        title: "Quantity",
        dataIndex: "Quantity",
        key: "Quantity",
        width: "110px",
        editable: true,
        inputType: "int",
        ...getNumberFilterSorter("Quantity"),
        ...getNumberRenderer(),
    },
    {
        title: "Cost",
        dataIndex: "Cost",
        key: "Cost",
        width: "110px",
        editable: true,
        inputType: "price",
        ...getNumberFilterSorter("Cost"),
        ...getPriceRenderer(),
    },
    {
        title: "Ship Requested",
        dataIndex: "Ship_Requested",
        key: "Ship_Requested",
        editable: true,
        inputType: "date",
        width: "110px",
        ...getDefaultFilterSorter("Ship_Requested", searchInputRef),
        ...getDateRenderer(),
    },
    {
        title: "Replenishable?",
        dataIndex: "Replenishable?",
        key: "Replenishable?",
        editable: true,
        inputType: "bool",
        width: "110px",
        ...getBoolRenderer(),
        ...getDefaultFilterSorter("Replenishable?", searchInputRef),
    },
    {
        title: "Order Type",
        dataIndex: "OrderType",
        key: "OrderType",
        editable: true,
        inputType: "order types",
        width: "110px",
        ...getDefaultFilterSorter("OrderType", searchInputRef),
    },
    {
        title: "Vendor Type",
        dataIndex: "VendorType",
        key: "VendorType",
        editable: true,
        inputType: "vendor types",
        width: "110px",
        ...getDefaultFilterSorter("VendorType", searchInputRef),
    },
    {
        title: "MAP",
        dataIndex: "MAP",
        key: "MAP",
        width: "110px",
        editable: true,
        inputType: "price",
        ...getNumberFilterSorter("MAP"),
        ...getPriceRenderer(""),
    },
];

export const makeStage2Columns = (
    searchInputRef: React.RefObject<InputRef>,
    maxSalesRank: number
): EditableColumnType<OMSItemRenderType>[] => [
    {
        title: "ID",
        dataIndex: "_id",
        key: "_id",
        width: "135px",
        // fixed: "left",
        ...GetExpandedFilter([], "_id"),
    },
    {
        title: "PO",
        dataIndex: "Supplier_PO",
        key: "Supplier_PO",
        width: "135px",
        editable: true,
        // fixed: "left",
        ...GetExpandedFilter([], "Supplier_PO"),
    },
    {
        title: "UPC",
        dataIndex: "UPC",
        key: "UPC",
        editable: true,
        width: "110px",
        ...GetExpandedFilter([], "UPC"),
    },
    {
        title: "Cost",
        dataIndex: "Cost",
        key: "Cost",
        width: "110px",
        editable: true,
        inputType: "price",
        ...getNumberFilterSorter("Cost"),
        ...getPriceRenderer(),
    },
    {
        title: "Quantity",
        dataIndex: "Quantity",
        key: "Quantity",
        width: "110px",
        editable: true,
        inputType: "int",
        ...getNumberFilterSorter("Quantity"),
        ...getNumberRenderer(),
    },
    {
        title: "Title",
        dataIndex: "Supplier_Title",
        key: "Supplier_Title",
        width: "135px",
        editable: true,
        // fixed: "left",
        ...getDefaultFilterSorter("Supplier_Title", searchInputRef),
    },
    {
        title: "Notes",
        dataIndex: "Supplier_Notes",
        key: "Supplier_Notes",
        width: "135px",
        editable: true,
        // fixed: "left",
        ...getDefaultFilterSorter("Supplier_Notes", searchInputRef),
    },
    {
        title: "Replenishable?",
        dataIndex: "Replenishable?",
        key: "Replenishable?",
        editable: true,
        inputType: "bool",
        width: "110px",
        ...getBoolRenderer(),
        ...getDefaultFilterSorter("Replenishable?", searchInputRef),
    },
    {
        title: "OMS Tag",
        dataIndex: "OMS_Tag",
        key: "OMS_Tag",
        editable: true,
        width: "110px",
        ...getDefaultFilterSorter("OMS_Tag", searchInputRef),
    },
    {
        title: "ASIN",
        dataIndex: "ASIN",
        key: "ASIN",
        editable: true,
        width: "110px",
        ...getASINRenderer(),
        ...GetExpandedFilter([], "ASIN"),
        ...getCopyCellRenderer("ASIN"),
    },
    {
        title: "Sales Rank",
        dataIndex: "AMZ_SalesRank",
        key: "AMZ_SalesRank",
        editable: false,
        width: "110px",
        ...getNumberFilterSorter("AMZ_SalesRank"),
        ...getSalesRankCellRenderer(maxSalesRank),
    },
    {
        title: "Amazon Title",
        dataIndex: "AMZ_Title",
        key: "AMZ_Title",
        editable: false,
        width: "200px",
        ...GetExpandedFilter([], "AMZ_Title"),
    },
    {
        title: "ASINCount",
        dataIndex: "ASINCount",
        key: "ASINCount",
        editable: false,
        width: "105px",
        ...getNumberFilterSorter("ASINCount"),
        onCell: (data) => {
            if (data.ASINCount > 1) {
                let style = {
                    backgroundColor: `rgba(255, 0, 0, 0.5)`,
                };
                return {style};
            } else {
                return {};
            }
        },
    },
    {
        title: "PO_ASINCount",
        dataIndex: "PO_ASINCount",
        key: "PO_ASINCount",
        editable: false,
        width: "125px",
        ...getNumberFilterSorter("PO_ASINCount"),
        onCell: (data) => {
            if (data.PO_ASINCount > 1) {
                let style = {
                    backgroundColor: `rgba(255, 0, 0, 0.5)`,
                };
                return {style};
            } else {
                return {};
            }
        },
    },
    {
        title: "Size Tier",
        dataIndex: "AMZ_SizeTier",
        key: "AMZ_SizeTier",
        editable: false,
        width: "110px",
        ...getDefaultFilterSorter("AMZ_SizeTier", searchInputRef),
    },
    {
        title: "Orphan",
        dataIndex: "AMZ_Orphan",
        key: "AMZ_Orphan",
        editable: false,
        width: "110px",
        ...getDefaultFilterSorter("AMZ_Orphan", searchInputRef),
    },
];

export const makeStage3Columns = (searchInputRef: React.RefObject<InputRef>): EditableColumnType<OMSItemRenderType>[] => [
    {
        title: "ID",
        dataIndex: "_id",
        key: "_id",
        width: "135px",
        // fixed: "left",
        ...GetExpandedFilter([], "_id"),
    },
    {
        title: "PO",
        dataIndex: "Supplier_PO",
        key: "Supplier_PO",
        width: "135px",
        editable: true,
        // fixed: "left",
        ...GetExpandedFilter([], "Supplier_PO"),
        extendedFilterClickHandling: true,
    },
    {
        title: "ASIN",
        dataIndex: "ASIN",
        key: "ASIN",
        editable: false,
        width: "110px",
        ...getASINRenderer(),
        ...GetExpandedFilter([], "ASIN"),
        ...getCopyCellRenderer("ASIN"),
        extendedFilterClickHandling: true,
    },
    {
        title: "Image",
        key: "azImg",
        dataIndex: "azImg",
        width: "125px",
        render: (text: string, record: any) => {
            return record.KeepaData?.imagesCSV ? (
                <a target="_blank" rel="noopener noreferrer" href={`https://www.amazon.com/dp/${record.ASIN}/?th=1&psc=1`}>
                    <img
                        src={`https://images-na.ssl-images-amazon.com/images/I/${record.KeepaData?.imagesCSV?.split(",")[0]}`}
                        height={125}
                        width={125}
                        alt="Amazon product"
                    />
                </a>
            ) : (
                <a target="_blank" rel="noopener noreferrer" href={`https://www.amazon.com/dp/${record.ASIN}/?th=1&psc=1`}>
                    <Skeleton.Image active={true} style={{height: 125, width: 125}} />
                </a>
            );
        },
    },
    {
        title: "Amazon Title",
        dataIndex: "AMZ_Title",
        key: "AMZ_Title",
        editable: false,
        width: "300px",
        ...GetExpandedFilter([], "AMZ_Title"),
    },
    {
        title: "Quantity",
        dataIndex: "Quantity",
        key: "Quantity",
        width: "110px",
        editable: true,
        inputType: "int",
        ...getNumberFilterSorter("Quantity"),
        ...getNumberRenderer(),
    },
    {
        title: "Cost",
        dataIndex: "Cost",
        key: "Cost",
        width: "110px",
        editable: true,
        inputType: "price",
        ...getNumberFilterSorter("Cost"),
        ...getPriceRenderer(),
    },
    {
        title: "MAP",
        dataIndex: "MAP",
        key: "MAP",
        width: "110px",
        editable: true,
        inputType: "price",
        ...getNumberFilterSorter("MAP"),
        ...getPriceRenderer(""),
    },
    {
        title: "Parent",
        dataIndex: "ParentASIN",
        key: "ParentASIN",
        width: "135px",
        sorter: {
            compare: (a, b) => (a.ParentASIN! > b.ParentASIN! ? 1 : a.ParentASIN === b.ParentASIN ? 0 : -1),
            multiple: 2,
        },
        editable: true,
        ...getASINRenderer(),
        ...GetExpandedFilter([], "ParentASIN", undefined, true),
        ...getCopyCellRenderer("ParentASIN"),

        extendedFilterClickHandling: true,
    },
    {
        title: "Min Price",
        dataIndex: "Min Price",
        key: "Min Price",
        width: "110px",
        editable: true,
        inputType: "price",
        ...getNumberFilterSorter("Min Price"),
        ...getPriceRenderer(),
        ...getMinPriceCellRenderer(),
    },
    {
        title: "Max Price/List",
        dataIndex: "Max Price/List",
        key: "Max Price/List",
        editable: true,
        inputType: "price",
        width: "110px",
        ...getNumberFilterSorter("Max Price/List"),
        ...getPriceRenderer(),
        ...getMaxPriceCellRenderer(),
    },
    {
        title: "Sell Price",
        dataIndex: "Sell Price",
        key: "Sell Price",
        editable: true,
        inputType: "price",
        width: "110px",
        ...getNumberFilterSorter("Sell Price"),
        ...getPriceRenderer(),
        ...getSellPriceCellRenderer(),
    },
    {
        title: "Seasonal Tag",
        dataIndex: "Seasonal Tag",
        key: "Seasonal Tag",
        editable: true,
        inputType: "seasonal tags",
        width: "110px",
        ...getDefaultFilterSorter("Seasonal Tag", searchInputRef),
    },
    {
        title: "ROI",
        dataIndex: "ROI",
        key: "ROI",
        editable: false,
        inputType: "int",
        width: "110px",
        ...getNumberFilterSorter("ROI"),
        ...getShareRenderer(2),
    },
    {
        title: "Keepa",
        key: "keepaGraph",
        dataIndex: "ASIN",
        width: "325px",
        render: (text: string, record: OMSItemRenderType) => {
            if (!text) {
                return "Not found";
            }
            return (
                <a target="_blank" rel="noopener noreferrer" href={`https://keepa.com/#!product/1-${text}`}>
                    <img
                        src={`https://graph.keepa.com/pricehistory.png?domain=com&bb=1&salesrank=1&asin=${text}&range=365`}
                        height={125}
                        width={325}
                        alt="Keepa graph"
                    />
                </a>
            );
        },
    },
    {
        title: "Req Cancel?",
        dataIndex: "Req_Cancel?",
        key: "Req_Cancel?",
        editable: true,
        inputType: "yes/no",
        width: "110px",
        ...getDefaultFilterSorter("Req_Cancel?", searchInputRef),
    },
    {
        title: "Listing Issues?",
        dataIndex: "Listing Issues?",
        key: "Listing Issues?",
        width: "110px",
        inputType: "yes/no",
        editable: true,
        ...getDefaultFilterSorter("Listing Issues?", searchInputRef),
    },
    {
        title: "Ship Now",
        dataIndex: "Ship Now",
        key: "Ship Now",
        editable: true,
        inputType: "int",
        required: false,
        width: "110px",
        ...getNumberFilterSorter("Ship Now"),
        ...getNumberRenderer(),
    },
    {
        title: "Ship Later",
        dataIndex: "Ship Later",
        key: "Ship Later",
        editable: true,
        inputType: "int",
        required: false,
        width: "110px",
        ...getNumberFilterSorter("Ship Later"),
        ...getNumberRenderer(),
    },
    {
        title: "S3 Pushed?",
        dataIndex: "S3_Pushed?",
        key: "S3_Pushed?",
        width: "110px",
        inputType: "yes/no",
        editable: true,
        ...getDefaultFilterSorter("S3_Pushed?", searchInputRef),
    },
    {
        title: "Ship Total",
        dataIndex: "Ship_Total",
        key: "Ship_Total",
        editable: false,
        inputType: "int",
        width: "110px",
        ...getNumberFilterSorter("Ship_Total"),
        onCell: (data) => {
            let style: React.CSSProperties = {};
            if (data["Ship_Total"] !== data.Quantity) {
                style = {
                    backgroundColor: RED,
                };
            }
            return {style};
        },
    },
    {
        title: "Shipped",
        dataIndex: "Shipped",
        key: "Shipped",
        width: "90px",
        ...getNumberFilterSorter("Shipped"),
        ...getNumberRenderer(),
    },
    {
        title: "Package Weight",
        dataIndex: "PackageWeight",
        key: "PackageWeight",
        editable: true,
        inputType: "float",
        width: "110px",
        ...getNumberFilterSorter("PackageWeight"),
        ...getNumberRenderer(3),
    },
    {
        title: "Referral Fee",
        dataIndex: "ReferralFee",
        key: "ReferralFee",
        width: "110px",
        ...getNumberFilterSorter("ReferralFee"),
        ...getPriceRenderer(),
    },
    {
        title: "Fulfillment Fee",
        dataIndex: "FulfillmentFee",
        key: "FulfillmentFee",
        editable: true,
        inputType: "price",
        width: "110px",
        ...getNumberFilterSorter("FulfillmentFee"),
        ...getPriceRenderer(),
    },
    {
        title: "Ship Fee",
        dataIndex: "ShipFee",
        key: "ShipFee",
        width: "110px",
        ...getNumberFilterSorter("ShipFee"),
        ...getPriceRenderer(),
    },
    {
        title: "Profit",
        dataIndex: "Profit",
        key: "Profit",
        editable: false,
        inputType: "price",
        width: "110px",
        ...getNumberFilterSorter("Profit"),
        ...getPriceRenderer(),
    },
    {
        title: "MVSP",
        dataIndex: "MVSP",
        key: "MVSP",
        editable: false,
        inputType: "price",
        width: "110px",
        ...getNumberFilterSorter("MVSP"),
        ...getPriceRenderer(),
    },
    {
        title: "Desired ROI",
        dataIndex: "DesiredROI",
        key: "DesiredROI",
        editable: true,
        inputType: "int",
        width: "110px",
        ...getNumberFilterSorter("DesiredROI"),
        ...getShareRenderer(),
    },
    {
        title: "Round Up",
        dataIndex: "RoundUp",
        key: "RoundUp",
        editable: false,
        inputType: "int",
        width: "110px",
        ...getNumberFilterSorter("RoundUp"),
        ...getPriceRenderer(),
        ...getCopyCellRenderer("RoundUp"),
    },
];

export const makeOMSImportColumns = (
    searchInputRef: React.RefObject<InputRef>,
    dbData: OMSMap
): EditableColumnType<OMSItemRenderType>[] => [
    {
        title: "ID",
        dataIndex: "_id",
        key: "_id",
        width: "135px",
        // fixed: "left",
        ...GetExpandedFilter([], "_id"),
    },
    {
        title: "Name",
        dataIndex: "Supplier_Name",
        key: "Supplier_Name",
        width: "235px",
        editable: true,
        inputType: "suppliers",
        // fixed: "left",
        ...getDefaultFilterSorter("Supplier_Name", searchInputRef),
        ...getModifiedCellRenderer("Supplier_Name", dbData, "_id"),
    },
    {
        title: "Date",
        dataIndex: "Supplier_Date",
        key: "Supplier_Date",
        width: "135px",
        editable: true,
        inputType: "date",
        // fixed: "left",
        ...getDefaultFilterSorter("Supplier_Date", searchInputRef),
        ...getDateRenderer(),
        ...getModifiedCellRenderer("Supplier_Date", dbData, "_id", (val: string) => dayjs(val).format("MM/DD/YYYY")),
    },
    {
        title: "PO",
        dataIndex: "Supplier_PO",
        key: "Supplier_PO",
        width: "135px",
        editable: true,
        // fixed: "left",
        ...GetExpandedFilter([], "Supplier_PO"),
        ...getModifiedCellRenderer("Supplier_PO", dbData, "_id"),
    },
    {
        title: "SO",
        dataIndex: "Supplier_SO",
        key: "Supplier_SO",
        width: "135px",
        editable: true,
        // fixed: "left",
        ...GetExpandedFilter([], "Supplier_SO"),
        ...getModifiedCellRenderer("Supplier_SO", dbData, "_id"),
    },
    {
        title: "SKU",
        dataIndex: "Supplier_SKU",
        key: "Supplier_SKU",
        width: "135px",
        editable: true,
        // fixed: "left",
        ...GetExpandedFilter([], "Supplier_SKU"),
        ...getModifiedCellRenderer("Supplier_SKU", dbData, "_id"),
    },
    {
        title: "Title",
        dataIndex: "Supplier_Title",
        key: "Supplier_Title",
        width: "235px",
        editable: true,
        // fixed: "left",
        ...getDefaultFilterSorter("Supplier_Title", searchInputRef),
        ...getModifiedCellRenderer("Supplier_Title", dbData, "_id"),
    },
    {
        title: "Notes",
        dataIndex: "Supplier_Notes",
        key: "Supplier_Notes",
        width: "135px",
        editable: true,
        // fixed: "left",
        ...getDefaultFilterSorter("Supplier_Notes", searchInputRef),
        ...getModifiedCellRenderer("Supplier_Notes", dbData, "_id"),
    },
    {
        title: "UPC",
        dataIndex: "UPC",
        key: "UPC",
        editable: true,
        width: "110px",
        ...GetExpandedFilter([], "UPC"),
        ...getModifiedCellRenderer("UPC", dbData, "_id"),
    },
    {
        title: "Quantity",
        dataIndex: "Quantity",
        key: "Quantity",
        width: "110px",
        editable: true,
        inputType: "int",
        ...getNumberFilterSorter("Quantity"),
        ...getNumberRenderer(),
        ...getModifiedCellRenderer("Quantity", dbData, "_id"),
    },
    {
        title: "Cost",
        dataIndex: "Cost",
        key: "Cost",
        width: "110px",
        editable: true,
        inputType: "price",
        ...getNumberFilterSorter("Cost"),
        ...getPriceRenderer(),
        ...getModifiedCellRenderer("Cost", dbData, "_id"),
    },
    {
        title: "Ship Requested",
        dataIndex: "Ship_Requested",
        key: "Ship_Requested",
        editable: true,
        inputType: "date",
        width: "110px",
        ...getDefaultFilterSorter("Ship_Requested", searchInputRef),
        ...getDateRenderer(),
        ...getModifiedCellRenderer("Ship_Requested", dbData, "_id", (val: string) => dayjs(val).format("MM/DD/YYYY")),
    },
    {
        title: "Replenishable?",
        dataIndex: "Replenishable?",
        key: "Replenishable?",
        editable: true,
        inputType: "bool",
        width: "110px",
        ...getBoolRenderer(),
        ...getDefaultFilterSorter("Replenishable?", searchInputRef),
        ...getModifiedCellRenderer("Replenishable?", dbData, "_id"),
    },
    {
        title: "Order Type",
        dataIndex: "OrderType",
        key: "OrderType",
        editable: true,
        inputType: "order types",
        width: "110px",
        ...getDefaultFilterSorter("OrderType", searchInputRef),
        ...getModifiedCellRenderer("OrderType", dbData, "_id"),
    },
    {
        title: "Vendor Type",
        dataIndex: "VendorType",
        key: "VendorType",
        editable: true,
        inputType: "vendor types",
        width: "110px",
        ...getDefaultFilterSorter("VendorType", searchInputRef),
        ...getModifiedCellRenderer("VendorType", dbData, "_id"),
    },
    {
        title: "MAP",
        dataIndex: "MAP",
        key: "MAP",
        width: "110px",
        editable: true,
        inputType: "price",
        ...getNumberFilterSorter("MAP"),
        ...getPriceRenderer(""),
        ...getModifiedCellRenderer("MAP", dbData, "_id"),
    },
    {
        title: "S1 Pushed?",
        dataIndex: "S1_Pushed?",
        key: "S1_Pushed?",
        editable: true,
        width: "110px",
        inputType: "yes/no",
        ...getDefaultFilterSorter("S1_Pushed?", searchInputRef),
        ...getModifiedCellRenderer("S1_Pushed?", dbData, "_id"),
    },
    {
        title: "S1 Pushed By",
        dataIndex: "S1_Pushed_By",
        key: "S1_Pushed_By",
        editable: true,
        width: "110px",
        ...getDefaultFilterSorter("S1_Pushed_By", searchInputRef),
        ...getModifiedCellRenderer("S1_Pushed_By", dbData, "_id"),
    },
    {
        title: "Checked?",
        dataIndex: "Checked?",
        key: "Checked?",
        editable: true,
        inputType: "yes/no",
        width: "110px",
        ...getDefaultFilterSorter("Checked?", searchInputRef),
        ...getModifiedCellRenderer("Checked?", dbData, "_id"),
    },
    {
        title: "UPC Match?",
        dataIndex: "Upc Match?",
        key: "Upc Match?",
        editable: true,
        inputType: "yes/no",
        width: "110px",
        ...getDefaultFilterSorter("Upc Match?", searchInputRef),
        ...getModifiedCellRenderer("Upc Match?", dbData, "_id"),
    },
    {
        title: "ASIN",
        dataIndex: "ASIN",
        key: "ASIN",
        editable: false,
        width: "110px",
        ...getASINRenderer(),
        ...GetExpandedFilter([], "ASIN"),
        ...getModifiedCellRenderer("ASIN", dbData, "_id"),
    },
    {
        title: "AMZ Title",
        dataIndex: "AMZ_Title",
        key: "AMZ_Title",
        width: "110px",
        editable: true,
        ...getDefaultFilterSorter("AMZ_Title", searchInputRef),
        ...getModifiedCellRenderer("AMZ_Title", dbData, "_id"),
    },
    {
        title: "AMZ Size Tier",
        dataIndex: "AMZ_SizeTier",
        key: "AMZ_SizeTier",
        editable: true,
        width: "110px",
        ...getDefaultFilterSorter("AMZ_SizeTier", searchInputRef),
        ...getModifiedCellRenderer("AMZ_SizeTier", dbData, "_id"),
    },
    {
        title: "AMZ Orphan",
        dataIndex: "AMZ_Orphan",
        key: "AMZ_Orphan",
        editable: true,
        width: "110px",
        ...getDefaultFilterSorter("AMZ_Orphan", searchInputRef),
        ...getModifiedCellRenderer("AMZ_Orphan", dbData, "_id"),
    },
    {
        title: "S2 Pushed?",
        dataIndex: "S2_Pushed?",
        key: "S2_Pushed?",
        editable: true,
        inputType: "yes/no",
        width: "110px",
        ...getDefaultFilterSorter("S2_Pushed?", searchInputRef),
        ...getModifiedCellRenderer("S2_Pushed?", dbData, "_id"),
    },
    {
        title: "S2 Pushed By",
        dataIndex: "S2_Pushed_By",
        key: "S2_Pushed_By",
        editable: true,
        width: "110px",
        ...getDefaultFilterSorter("S2_Pushed_By", searchInputRef),
        ...getModifiedCellRenderer("S2_Pushed_By", dbData, "_id"),
    },
    {
        title: "Min Price",
        dataIndex: "Min Price",
        key: "Min Price",
        width: "110px",
        editable: true,
        required: false,
        inputType: "price",
        ...getNumberFilterSorter("Min Price"),
        ...getPriceRenderer(),
        ...getModifiedCellRenderer("Min Price", dbData, "_id"),
    },
    {
        title: "Max Price/List",
        dataIndex: "Max Price/List",
        key: "Max Price/List",
        editable: true,
        inputType: "price",
        width: "110px",
        ...getNumberFilterSorter("Max Price/List"),
        ...getPriceRenderer(),
        ...getModifiedCellRenderer("Max Price/List", dbData, "_id"),
    },
    {
        title: "Sell Price",
        dataIndex: "Sell Price",
        key: "Sell Price",
        editable: true,
        inputType: "price",
        width: "110px",
        ...getNumberFilterSorter("Sell Price"),
        ...getPriceRenderer(),
    },
    {
        title: "Seasonal Tag",
        dataIndex: "Seasonal Tag",
        key: "Seasonal Tag",
        editable: true,
        inputType: "seasonal tags",
        width: "110px",
        ...getDefaultFilterSorter("Seasonal Tag", searchInputRef),
        ...getModifiedCellRenderer("Seasonal Tag", dbData, "_id"),
    },
    {
        title: "Req Cancel?",
        dataIndex: "Req_Cancel?",
        key: "Req_Cancel?",
        editable: true,
        inputType: "yes/no",
        width: "110px",
        ...getDefaultFilterSorter("Req_Cancel?", searchInputRef),
        ...getModifiedCellRenderer("Req_Cancel?", dbData, "_id"),
    },
    {
        title: "Listing Issues?",
        dataIndex: "Listing Issues?",
        key: "Listing Issues?",
        width: "110px",
        inputType: "yes/no",
        editable: true,
        ...getDefaultFilterSorter("Listing Issues?", searchInputRef),
        ...getModifiedCellRenderer("Listing Issues?", dbData, "_id"),
    },
    {
        title: "Ship Now",
        dataIndex: "Ship Now",
        key: "Ship Now",
        editable: true,
        inputType: "int",
        required: false,
        width: "110px",
        ...getNumberFilterSorter("Ship Now"),
        ...getNumberRenderer(),
        ...getModifiedCellRenderer("Ship Now", dbData, "_id"),
    },
    {
        title: "Ship Later",
        dataIndex: "Ship Later",
        key: "Ship Later",
        editable: true,
        inputType: "int",
        required: false,
        width: "110px",
        ...getNumberFilterSorter("Ship Later"),
        ...getNumberRenderer(),
        ...getModifiedCellRenderer("Ship Later", dbData, "_id"),
    },
    {
        title: "S3 Pushed?",
        dataIndex: "S3_Pushed?",
        key: "S3_Pushed?",
        width: "110px",
        inputType: "yes/no",
        editable: true,
        ...getDefaultFilterSorter("S3_Pushed?", searchInputRef),
        ...getModifiedCellRenderer("S3_Pushed?", dbData, "_id"),
    },
    {
        title: "S3 Pushed By",
        dataIndex: "S3_Pushed_By",
        key: "S3_Pushed_By",
        width: "110px",
        editable: true,
        ...getDefaultFilterSorter("S3_Pushed_By", searchInputRef),
        ...getModifiedCellRenderer("S3_Pushed_By", dbData, "_id"),
    },
    {
        title: "Shipped",
        dataIndex: "Shipped",
        key: "Shipped",
        width: "90px",
        ...getNumberFilterSorter("Shipped"),
        ...getNumberRenderer(),
    },
    {
        title: "Prelisted IL?",
        dataIndex: "Prelisted IL?",
        key: "Prelisted IL?",
        width: "110px",
        inputType: "yes/no",
        editable: true,
        ...GetExpandedFilter(["YES", "NO"], "Prelisted IL?"),
        ...getModifiedCellRenderer("Prelisted IL?", dbData, "_id"),
    },
    {
        title: "S4 Pushed?",
        dataIndex: "S4_Pushed?",
        key: "S4_Pushed?",
        width: "110px",
        inputType: "yes/no",
        editable: true,
        ...getDefaultFilterSorter("S4_Pushed?", searchInputRef),
        ...getModifiedCellRenderer("S4_Pushed", dbData, "_id"),
    },
    {
        title: "S4 Pushed By",
        dataIndex: "S4_Pushed_By",
        key: "S4_Pushed_By",
        width: "110px",
        editable: true,
        ...getDefaultFilterSorter("S4_Pushed_By", searchInputRef),
        ...getModifiedCellRenderer("S4_Pushed_By", dbData, "_id"),
    },
];

export const makeInvoiceColumns = (searchInputRef: React.RefObject<InputRef>): EditableColumnType<InvoiceDataRenderType>[] => [
    {
        title: "Idx",
        dataIndex: "key",
        key: "key",
        width: "15px",
        align: "center",
        ...getDefaultFilterSorter("key", searchInputRef),
    },
    {
        title: "ID",
        dataIndex: "InvoiceId",
        key: "InvoiceId",
        editable: true,
        width: "135px",
        // fixed: "left",
        ...GetExpandedFilter([], "InvoiceId"),
    },
    {
        title: "Name",
        dataIndex: "Supplier_Name",
        key: "Supplier_Name",
        width: "135px",
        editable: true,
        inputType: "suppliers",
        // fixed: "left",
        ...getDefaultFilterSorter("Supplier_Name", searchInputRef),
    },
    {
        title: "PO",
        dataIndex: "Supplier_PO",
        key: "Supplier_PO",
        width: "135px",
        editable: true,
        // fixed: "left",
        ...GetExpandedFilter([], "Supplier_PO"),
    },
    {
        title: "Dropbox URL",
        dataIndex: "Dropbox_URL",
        key: "Dropbox_URL",
        width: "135px",
        editable: true,
        // fixed: "left",
        ...getDefaultFilterSorter("Dropbox_URL", searchInputRef),
        ...getURLRenderer(),
    },
];

export const makeLedgerColumns = (searchInputRef: React.RefObject<InputRef>): EditableColumnType<LedgerDataRenderType>[] => [
    {
        title: "Idx",
        dataIndex: "key",
        key: "key",
        width: "40px",
        align: "center",
        ...GetExpandedFilter([], "key", undefined, true),
    },
    {
        title: "Name",
        dataIndex: "Supplier_Name",
        key: "Supplier_Name",
        width: "135px",
        editable: true,
        inputType: "suppliers",
        ...getDefaultFilterSorter("Supplier_Name", searchInputRef),
    },
    {
        title: "PO",
        dataIndex: "Supplier_PO",
        key: "Supplier_PO",
        width: "135px",
        editable: true,
        ...GetExpandedFilter([], "Supplier_PO"),
    },
    {
        title: "SO",
        dataIndex: "Supplier_SO",
        key: "Supplier_SO",
        width: "135px",
        editable: true,
        ...GetExpandedFilter([], "Supplier_SO"),
    },
    {
        title: "Invoice Number",
        dataIndex: "InvoiceId",
        key: "InvoiceId",
        editable: true,
        width: "135px",
        ...GetExpandedFilter([], "InvoiceId"),
    },
    {
        title: "Distributor Invoice",
        dataIndex: "DistributorInvoiceId",
        key: "DistributorInvoiceId",
        editable: true,
        width: "135px",
        ...GetExpandedFilter([], "DistributorInvoiceId"),
    },
    {
        title: "Notes",
        dataIndex: "Notes",
        key: "Notes",
        width: "135px",
        editable: true,
        ...getDefaultFilterSorter("Notes", searchInputRef),
    },
    {
        title: "Is Commission",
        dataIndex: "IsCommission",
        key: "IsCommission",
        width: "135px",
        editable: true,
        inputType: "bool",
        ...getBoolRenderer(),
        ...getDefaultFilterSorter("IsCommission", searchInputRef),
    },
    {
        title: "Paid Amount",
        dataIndex: "PaidAmount",
        key: "PaidAmount",
        width: "110px",
        editable: true,
        required: true,
        inputType: "price",
        ...getNumberFilterSorter("PaidAmount"),
        ...getPriceRenderer(),
    },
    {
        title: "Paid Date",
        dataIndex: "PaidDate",
        key: "PaidDate",
        editable: true,
        required: true,
        inputType: "date",
        width: "110px",
        ...getDefaultDateSorter("PaidDate", searchInputRef),
        ...getDateRenderer(),
    },
    {
        title: "Payment Method",
        dataIndex: "PaymentMethod",
        key: "PaymentMethod",
        width: "135px",
        editable: true,
        ...getDefaultFilterSorter("PaymentMethod", searchInputRef),
    },
    {
        title: "Credit Id",
        dataIndex: "CreditId",
        key: "CreditId",
        width: "105px",
        editable: true,
        ...getDefaultFilterSorter("CreditId", searchInputRef),
    },
];

export const makeSellerSnapExportColumns = (
    searchInputRef: React.RefObject<InputRef>
): EditableColumnType<SellerSnapExportDataRenderType>[] => [
    {
        title: "Idx",
        dataIndex: "key",
        key: "key",
        width: "40px",
        align: "center",
        ...getDefaultFilterSorter("key", searchInputRef),
    },
    {
        title: "SKU",
        dataIndex: "sku",
        key: "sku",
        width: "135px",
        editable: true,
        ...getDefaultFilterSorter("sku", searchInputRef),
    },
    {
        title: "Cost",
        dataIndex: "cost",
        key: "cost",
        width: "135px",
        editable: true,
        inputType: "price",
        ...getNumberFilterSorter("cost"),
        ...getPriceRenderer(),
        ...highlightOnCondition((data: SellerSnapExportDataRenderType) => data.cost == null),
    },
    {
        title: "Min Price",
        dataIndex: "min_price",
        key: "min_price",
        width: "135px",
        editable: true,
        inputType: "price",
        ...getNumberFilterSorter("min_price"),
        ...getPriceRenderer(),
        ...highlightOnCondition(
            (data: SellerSnapExportDataRenderType) => data.min_price == null || (data.MAP && data.min_price !== data.MAP)
        ),
    },
    {
        title: "Max Price",
        dataIndex: "max_price",
        key: "max_price",
        width: "135px",
        editable: true,
        inputType: "price",
        ...getNumberFilterSorter("max_price"),
        ...getPriceRenderer(),
        ...highlightOnCondition(
            (data: SellerSnapExportDataRenderType) => data.max_price == null || (data.MAP && data.max_price !== data.MAP)
        ),
    },
    {
        title: "Repricing",
        dataIndex: "repricing",
        key: "repricing",
        width: "135px",
        editable: true,
        inputType: "bool",
        ...getBooleanFilter("repricing"),
        ...getBoolRenderer(),
        ...highlightOnCondition((data: SellerSnapExportDataRenderType) => !data.repricing),
    },
    {
        title: "Reprice Preset",
        dataIndex: "reprice_config_preset",
        key: "reprice_config_preset",
        width: "135px",
        editable: true,
        ...getDefaultFilterSorter("reprice_config_preset", searchInputRef),
    },
    {
        title: "Tags",
        dataIndex: "tags",
        key: "tags",
        editable: true,
        width: "110px",
        inputType: "seasonal tags",
        ...getDefaultFilterSorter("tags", searchInputRef),
        ...highlightOnCondition((data: SellerSnapExportDataRenderType) => !data.tags),
    },
    {
        title: "ASIN",
        dataIndex: "ASIN",
        key: "ASIN",
        editable: false,
        width: "110px",
        ...getASINRenderer(),
        ...GetExpandedFilter([], "ASIN"),
        ...getCopyCellRenderer("ASIN"),
    },
    {
        title: "Parent",
        dataIndex: "ParentASIN",
        key: "ParentASIN",
        width: "135px",
        sorter: {
            compare: (a, b) => (a.ParentASIN! > b.ParentASIN! ? 1 : a.ParentASIN === b.ParentASIN ? 0 : -1),
            multiple: 2,
        },
        editable: true,
        ...getASINRenderer(),
        ...GetExpandedFilter([], "ParentASIN"),
        ...getCopyCellRenderer("ParentASIN"),
    },
    {
        title: "Sell Price",
        dataIndex: "Sell Price",
        key: "Sell Price",
        editable: true,
        inputType: "price",
        width: "110px",
        ...getNumberFilterSorter("Sell Price"),
        ...getPriceRenderer(),
    },
    {
        title: "MAP",
        dataIndex: "MAP",
        key: "MAP",
        width: "110px",
        editable: true,
        inputType: "price",
        ...getNumberFilterSorter("MAP"),
        ...getPriceRenderer(""),
        ...highlightOnCondition(
            (data: SellerSnapExportDataRenderType) => data.MAP && data["Sell Price"] && data.MAP < data["Sell Price"] * 0.9
        ),
    },
    {
        title: "Fulfillment",
        dataIndex: "Fulfillment",
        key: "Fulfillment",
        editable: true,
        width: "110px",
        ...getDefaultFilterSorter("Fulfillment", searchInputRef),
    },
    {
        title: "Supplier",
        dataIndex: "Supplier",
        key: "Supplier",
        width: "135px",
        editable: true,
        inputType: "suppliers",
        // fixed: "left",
        ...getDefaultFilterSorter("Supplier", searchInputRef),
    },
    {
        title: "Purchase Date",
        dataIndex: "PurchaseDate",
        key: "PurchaseDate",
        editable: true,
        inputType: "date",
        width: "110px",
        ...getDefaultDateSorter("PurchaseDate", searchInputRef),
        ...getDateRenderer(),
    },
    {
        title: "Source",
        dataIndex: "Source",
        key: "Source",
        width: "135px",
        editable: true,
        ...getDefaultFilterSorter("Source", searchInputRef),
    },
];

export const makePOTrackerColumns = (
    searchInputRef: React.RefObject<InputRef>,
    handleSave?: (row: OMSTrackerItemRenderType) => void
): EditableColumnType<OMSTrackerItemRenderType>[] => [
    {
        title: "Idx",
        dataIndex: "key",
        key: "key",
        width: "40px",
        fixed: "left",
        // className: 'yellow-column',
        align: "center",
        ...getDefaultFilterSorter("key", searchInputRef),
    },
    {
        title: "Name",
        dataIndex: "Supplier_Name",
        key: "Supplier_Name",
        width: "135px",
        inputType: "suppliers",
        fixed: "left",
        // className: 'yellow-column',
        align: "center",
        ...GetExpandedFilter([], "Supplier_Name"),
    },
    {
        title: "PO",
        dataIndex: "Supplier_PO",
        key: "Supplier_PO",
        width: "135px",
        fixed: "left",
        // className: 'yellow-column',
        align: "center",
        ...GetExpandedFilter([], "Supplier_PO"),
    },
    {
        title: "SO",
        dataIndex: "Supplier_SO",
        key: "Supplier_SO",
        width: "135px",
        // className: 'yellow-column',
        align: "center",
        ...GetExpandedFilter([], "Supplier_SO"),
    },
    {
        title: "Total Units",
        dataIndex: "Total Units",
        key: "Total Units",
        width: "135px",
        className: "yellow-column",
        align: "center",
        ...getNumberFilterSorter("Total Units"),
        ...getNumberRenderer(),
    },
    {
        title: "Order Amount",
        dataIndex: "Order Amount",
        key: "Order Amount",
        width: "135px",
        className: "yellow-column",
        align: "center",
        ...getNumberFilterSorter("Order Amount"),
        ...getPriceRenderer(""),
    },
    {
        title: "Prelisted IL?",
        dataIndex: "Prelisted IL?",
        key: "Prelisted IL?",
        width: "135px",
        className: "blue-column",
        align: "center",
        ...GetExpandedFilter(["YES", "NO"], "Prelisted IL?"),
    },
    {
        title: "Has Instructions?",
        dataIndex: "Routing Instructions",
        key: "Routing Instructions",
        width: "135px",
        className: "blue-column",
        align: "center",
        render: (val) => (val ? "YES" : ""),
        ...getDefaultFilterSorter("Routing Instructions"),
    },
    {
        title: "Includes ShipLater?",
        dataIndex: "Includes ShipLater?",
        key: "Includes ShipLater?",
        width: "135px",
        className: "blue-column",
        align: "center",
        ...getDefaultFilterSorter("Includes ShipLater?", searchInputRef),
        ...getBoolRenderer(),
    },
    {
        title: "Shipped To",
        dataIndex: "Shipped To",
        key: "Shipped To",
        width: "135px",
        className: "shipping-info-column",
        align: "center",
        ...getDefaultFilterSorter("Shipped To", searchInputRef),
    },
    {
        title: "Ship_Requested",
        dataIndex: "Ship_Requested",
        key: "Ship_Requested",
        width: "110px",
        className: "shipping-info-column",
        align: "center",
        ...getDefaultDateSorter("Ship_Requested", searchInputRef),
        ...getDateRenderer(),
    },
    {
        title: "Units Shipped",
        dataIndex: "Units Shipped",
        key: "Units Shipped",
        width: "110px",
        className: "shipping-info-column",
        align: "center",
        ...getNumberFilterSorter("Units Shipped"),
        ...getNumberRenderer(undefined, ""),
    },
    {
        title: "Units Unshipped",
        dataIndex: "Units Unshipped",
        key: "Units Unshipped",
        width: "110px",
        className: "shipping-info-column",
        align: "center",
        ...getNumberFilterSorter("Units Unshipped"),
        ...getNumberRenderer(undefined, ""),
    },
    {
        title: "Units Unshipped Value",
        dataIndex: "Units Unshipped Value",
        key: "Units Unshipped Value",
        width: "110px",
        className: "shipping-info-column",
        align: "center",
        ...getNumberFilterSorter("Units Unshipped Value"),
        ...getPriceRenderer(""),
    },
    {
        title: "# of Shipments",
        dataIndex: "# of Shipments",
        key: "# of Shipments",
        width: "110px",
        className: "shipping-info-column",
        align: "center",
        ...getNumberFilterSorter("# of Shipments"),
        ...getNumberRenderer(undefined, ""),
    },
    {
        title: "Total Freight Costs",
        dataIndex: "Total Freight Costs",
        key: "Total Freight Costs",
        width: "135px",
        className: "shipping-info-column",
        align: "center",
        ...getNumberFilterSorter("Total Freight Costs"),
        ...getPriceRenderer(""),
    },
    {
        title: "Last Shipment Date",
        dataIndex: "Last Shipment Date",
        key: "Last Shipment Date",
        width: "110px",
        className: "shipping-info-column",
        align: "center",
        ...getDefaultDateSorter("Last Shipment Date", searchInputRef),
        ...getDateRenderer(),
    },
    {
        title: "Payment Notes",
        dataIndex: "PaymentNotes",
        key: "PaymentNotes",
        width: "135px",
        className: "payment-info-column",
        align: "center",
        inputType: "text",
        editable: true,
        ...GetExpandedFilter([], "PaymentNotes"),
    },
    {
        title: "Invoice Added",
        dataIndex: "Invoice Added",
        key: "Invoice Added",
        width: "135px",
        className: "invoice-info-column",
        align: "center",
        ...getDefaultFilterSorter("Invoice Added", searchInputRef),
    },
    {
        title: "Invoice #",
        dataIndex: "Invoice #",
        key: "Invoice #",
        width: "135px",
        className: "invoice-info-column",
        align: "center",
        ...getDefaultFilterSorter("Invoice #", searchInputRef),
    },
    {
        title: "Amount Owed",
        dataIndex: "Amount Owed",
        key: "Amount Owed",
        width: "135px",
        className: "invoice-info-column",
        align: "center",
        ...getNumberFilterSorter("Amount Owed"),
        ...getPriceRenderer(""),
    },
    {
        title: "Amount Paid",
        dataIndex: "Amount Paid",
        key: "Amount Paid",
        width: "135px",
        className: "invoice-info-column",
        align: "center",
        ...getNumberFilterSorter("Amount Paid"),
        ...getPriceRenderer(""),
    },
    {
        title: "Amount Due",
        dataIndex: "Amount Due",
        key: "Amount Due",
        width: "135px",
        className: "invoice-info-column",
        align: "center",
        ...getNumberFilterSorter("Amount Due"),
        ...getPriceRenderer(""),
    },
    {
        title: "Date Paid",
        dataIndex: "Date Paid",
        key: "Date Paid",
        width: "110px",
        className: "invoice-info-column",
        align: "center",
        ...getDefaultDateSorter("Date Paid", searchInputRef),
        ...getDateRenderer(),
    },
    {
        title: "Connected users",
        dataIndex: "ConnectedUsers",
        key: "ConnectedUsers",
        width: "150px",
        inputType: "supplier users",
        editable: true,
        ...GetExpandedFilter([...SUPPLIER_USERS], "ConnectedUsers", undefined, false, true),
        render: (users) => (
            <Space direction="vertical" align="center" style={{width: "100%"}}>
                {users?.map((user: string, idx: number) => (
                    <Tag style={{marginRight: 0}} key={idx}>
                        {user}
                    </Tag>
                )) ?? []}
            </Space>
        ),
    },
    {
        title: "Issues",
        dataIndex: "Issues",
        key: "Issues",
        width: "85px",
        ...GetExpandedFilter(POSSIBLE_OMS_TRACKER_ISSUES.slice(), "Issues"),
        render: (issues) => (
            <Space direction="vertical" align="center" style={{width: "100%"}}>
                {issues?.map((issue: string, idx: number) => (
                    <Tag style={{marginRight: 0}} key={idx}>
                        {issue.toUpperCase()}
                    </Tag>
                )) ?? []}
            </Space>
        ),
    },
    {
        title: "Notes",
        dataIndex: "Notes",
        key: "Notes",
        width: "325px",
        // editable: true,
        ...getDefaultFilterSorter("Notes", searchInputRef),
        ...getPOTrackerNotesCellRenderer("Notes"),
        render: (value, record) => (
            <NotesRenderer
                notes={value}
                editable={!!handleSave}
                onFinish={(notes) => {
                    const newRecord: OMSTrackerItemRenderType = {
                        ...record,
                        Notes: notes,
                    };
                    handleSave?.(newRecord);
                }}
            />
        ),
    },
    {
        title: "Routing Instructions",
        dataIndex: "Routing Instructions",
        key: "Routing Instructions",
        width: "200px",
        editable: true,
        ...getDefaultFilterSorter("Routing Instructions", searchInputRef),
        ...getPOTrackerNotesCellRenderer("Routing Instructions"),
    },
];

export const makeAPTrackerColumns = (searchInputRef: React.RefObject<InputRef>): EditableColumnType<APInvoiceTrackerDataRenderType>[] => [
    {
        title: "Idx",
        dataIndex: "key",
        key: "key",
        width: "40px",
        align: "center",
        ...getDefaultFilterSorter("key", searchInputRef),
    },
    {
        title: "Id",
        dataIndex: "_id",
        key: "_id",
        width: "70px",
        align: "center",
        ...getDefaultFilterSorter("_id", searchInputRef),
        render: (text, record) => (
            <div style={{overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", maxWidth: "70px"}}>{text}</div>
        ),
        // copy cell value on click
        onCell: (record, rowIndex) => ({
            onClick: () => {
                copyToClipboard(record._id ?? "", "ID");
            },
        }),
    },
    {
        title: "Issue Date",
        dataIndex: "IssueDate",
        key: "IssueDate",
        width: "110px",
        editable: true,
        inputType: "date",
        ...getDefaultDateSorter("IssueDate", searchInputRef),
        ...getDateRenderer(),
    },
    {
        title: "Due Date",
        dataIndex: "DueDate",
        key: "DueDate",
        width: "110px",
        editable: true,
        inputType: "date",
        ...getDefaultDateSorter("DueDate", searchInputRef),
        ...getDateRenderer(),
    },
    {
        title: "TrPay Notes",
        dataIndex: "TrackerPaymentNotes",
        key: "TrackerPaymentNotes",
        width: "135px",
        ...getDefaultFilterSorter("TrackerPaymentNotes", searchInputRef),
    },
    {
        title: "Accepts CC?",
        dataIndex: "ccAcceptance",
        key: "ccAcceptance",
        width: "70px",
        ...GetExpandedFilter([...ACCEPTANCE_MODES], "ccAcceptance"),
    },
    {
        title: "Payment Process",
        dataIndex: "paymentProcess",
        key: "paymentProcess",
        width: "100px",
        ...getDefaultFilterSorter("paymentProcess", searchInputRef),
    },
    {
        title: "Vendor Type",
        dataIndex: "VendorType",
        key: "VendorType",
        width: "85px",
        ...getDefaultFilterSorter("VendorType", searchInputRef),
    },
    {
        title: "Brand",
        dataIndex: "Brand",
        key: "Brand",
        width: "135px",
        editable: true,
        inputType: "suppliers",
        ...GetExpandedFilter([], "Brand"),
    },
    {
        title: "Distributor",
        dataIndex: "Distributor",
        key: "Distributor",
        width: "135px",
        editable: true,
        inputType: "select",
        inputOptions: {
            selectableValues: DISTRIBUTOR_TYPES.slice(),
        },
        ...GetExpandedFilter([], "Distributor"),
    },
    {
        title: "PO",
        dataIndex: "Supplier_PO",
        key: "Supplier_PO",
        width: "135px",
        editable: true,
        ...GetExpandedFilter([], "Supplier_PO"),
    },
    {
        title: "SO",
        dataIndex: "Supplier_SO",
        key: "Supplier_SO",
        width: "135px",
        editable: true,
        ...GetExpandedFilter([], "Supplier_SO"),
    },
    {
        title: "Invoice ID",
        dataIndex: "InvoiceId",
        key: "InvoiceId",
        editable: true,
        width: "135px",
        ...GetExpandedFilter([], "InvoiceId"),
    },
    {
        title: "Distributor Invoice ID",
        dataIndex: "DistributorInvoiceId",
        key: "DistributorInvoiceId",
        editable: true,
        width: "135px",
        ...GetExpandedFilter([], "DistributorInvoiceId"),
    },
    {
        title: "Invoice Notes",
        dataIndex: "InvoiceNotes",
        key: "InvoiceNotes",
        width: "135px",
        editable: true,
        ...getDefaultFilterSorter("InvoiceNotes", searchInputRef),
    },
    {
        title: "Order Amount",
        dataIndex: "OrderAmount",
        key: "OrderAmount",
        width: "135px",
        editable: true,
        inputType: "price",
        required: true,
        ...getNumberFilterSorter("OrderAmount"),
        ...getPriceRenderer(""),
    },
    {
        title: "Payment Notes",
        dataIndex: "PaymentNotes",
        key: "PaymentNotes",
        width: "135px",
        editable: true,
        ...getDefaultFilterSorter("PaymentNotes", searchInputRef),
    },
    {
        title: "Paid Amount",
        dataIndex: "PaidAmount",
        key: "PaidAmount",
        width: "135px",
        ...getNumberFilterSorter("PaidAmount"),
        ...getPriceRenderer(""),
    },
    {
        title: "Paid Date",
        dataIndex: "PaidDate",
        key: "PaidDate",
        width: "110px",
        ...getDefaultDateSorter("PaidDate", searchInputRef),
        ...getDateRenderer(),
    },
    {
        title: "Is Paid",
        dataIndex: "IsPaid",
        key: "IsPaid",
        width: "110px",
        editable: true,
        inputType: "bool",
        ...getBooleanFilter("IsPaid"),
        ...getBoolRenderer(),
    },
    {
        title: "Is Credit",
        dataIndex: "IsCredit",
        key: "IsCredit",
        width: "110px",
        editable: true,
        inputType: "bool",
        ...getBooleanFilter("IsCredit"),
        ...getBoolRenderer(),
    },
    {
        title: "Period",
        dataIndex: "periodIndex",
        key: "periodIndex",
        width: "135px",
        ...getNumberFilterSorter("periodIndex"),
    },
    {
        title: "Jake's Notes",
        dataIndex: "OtherNotes",
        key: "OtherNotes",
        width: "135px",
        editable: true,
        ...getDefaultFilterSorter("OtherNotes", searchInputRef),
    },
    {
        title: "Invoice File",
        dataIndex: "InvoiceFileURL",
        key: "InvoiceFileURL",
        width: "135px",
        editable: true,
        ...getDefaultFilterSorter("InvoiceFileURL", searchInputRef),
        ...getURLRenderer(),
    },
];

export const makeFreightLogColumns = (searchInputRef: React.RefObject<InputRef>): EditableColumnType<FreightLogRowRenderType>[] => [
    {
        title: "Idx",
        dataIndex: "key",
        key: "key",
        width: "40px",
        align: "center",
        ...getDefaultFilterSorter("key", searchInputRef),
    },
    {
        title: "Name",
        dataIndex: "Supplier_Name",
        key: "Supplier_Name",
        width: "135px",
        editable: true,
        inputType: "suppliers",
        ...getDefaultFilterSorter("Supplier_Name", searchInputRef),
    },
    {
        title: "PO",
        dataIndex: "Supplier_PO",
        key: "Supplier_PO",
        width: "135px",
        editable: true,
        ...GetExpandedFilter([], "Supplier_PO"),
    },
    {
        title: "SO",
        dataIndex: "Supplier_SO",
        key: "Supplier_SO",
        width: "135px",
        editable: true,
        ...GetExpandedFilter([], "Supplier_SO"),
    },
    {
        title: "BOL #",
        dataIndex: "BolId",
        key: "BolId",
        editable: true,
        width: "135px",
        ...GetExpandedFilter([], "BolId"),
    },
    {
        title: "Ship Requested",
        dataIndex: "Ship_Requested",
        key: "Ship_Requested",
        editable: true,
        inputType: "date",
        width: "110px",
        ...getDefaultDateSorter("Ship_Requested", searchInputRef),
        ...getDateRenderer(),
    },
    {
        title: "Ship Date Booked",
        dataIndex: "ShipDateBooked",
        key: "ShipDateBooked",
        editable: true,
        inputType: "date",
        width: "110px",
        ...getDefaultDateSorter("ShipDateBooked", searchInputRef),
        ...getDateRenderer(),
    },
    {
        title: "# of items",
        dataIndex: "ItemsNum",
        key: "ItemsNum",
        width: "80px",
        editable: true,
        inputType: "int",
        ...getNumberFilterSorter("ItemsNum"),
        ...getNumberRenderer(undefined, ""),
    },
    {
        title: "Total Cost",
        dataIndex: "TotalCost",
        key: "TotalCost",
        width: "90px",
        editable: true,
        inputType: "price",
        ...getNumberFilterSorter("TotalCost"),
        ...getPriceRenderer(""),
    },
    {
        title: "Cost/Unit",
        dataIndex: "UnitCost",
        key: "UnitCost",
        width: "90px",
        required: true,
        ...getNumberFilterSorter("UnitCost"),
        ...getPriceRenderer(""),
    },
    {
        title: "Shipped To",
        dataIndex: "ShippedTo",
        key: "ShippedTo",
        width: "75px",
        align: "center",
        editable: true,
        ...getDefaultFilterSorter("ShippedTo", searchInputRef),
    },
    {
        title: "Booked?",
        dataIndex: "Booked",
        key: "Booked",
        width: "80px",
        editable: true,
        inputType: "bool",
        ...getBooleanFilter("Booked"),
        ...getBoolRenderer(),
    },
    {
        title: "Shipment Plan File",
        dataIndex: "ShipmentPlanFile",
        key: "ShipmentPlanFile",
        width: "135px",
        editable: true,
        ...getDefaultFilterSorter("ShipmentPlanFile", searchInputRef),
        ...getURLRenderer(),
    },
];

export const makeFreightLogItemizedEntryColumns = (
    searchInputRef: React.RefObject<InputRef>
): EditableColumnType<FreightLogItemizedEntryRenderType>[] => [
    {
        title: "Idx",
        dataIndex: "key",
        key: "key",
        width: "40px",
        align: "center",
        ...getDefaultFilterSorter("key", searchInputRef),
        fixed: "left",
    },
    {
        title: "Title",
        dataIndex: "Supplier_Title",
        key: "Supplier_Title",
        width: "235px",
        editable: true,
        fixed: "left",
        ...getDefaultFilterSorter("Supplier_Title", searchInputRef),
    },
    {
        title: "UPC",
        dataIndex: "UPC",
        key: "UPC",
        editable: true,
        width: "110px",
        fixed: "left",
        ...GetExpandedFilter([], "UPC"),
    },
    {
        title: "SKU",
        dataIndex: "Supplier_SKU",
        key: "Supplier_SKU",
        width: "135px",
        editable: true,
        fixed: "left",
        ...GetExpandedFilter([], "Supplier_SKU"),
    },
    {
        title: "Quantity",
        dataIndex: "Quantity",
        key: "Quantity",
        width: "110px",
        editable: true,
        inputType: "int",
        fixed: "left",
        ...getNumberFilterSorter("Quantity"),
        ...getNumberRenderer(),
    },
    {
        title: "Cost",
        dataIndex: "Cost",
        key: "Cost",
        width: "90px",
        ...getNumberFilterSorter("Cost"),
        ...getPriceRenderer(""),
    },
    {
        title: "Supplier",
        dataIndex: "Supplier_Name",
        key: "Supplier_Name",
        width: "135px",
        editable: true,
        inputType: "suppliers",
        ...getDefaultFilterSorter("Supplier_Name", searchInputRef),
    },
    {
        title: "PO",
        dataIndex: "Supplier_PO",
        key: "Supplier_PO",
        width: "135px",
        editable: true,
        ...getDefaultFilterSorter("Supplier_PO", searchInputRef),
    },
    {
        title: "SO",
        dataIndex: "Supplier_SO",
        key: "Supplier_SO",
        width: "135px",
        editable: true,
        ...getDefaultFilterSorter("Supplier_SO", searchInputRef),
    },
    {
        title: "Shipment Tracker",
        dataIndex: "ShipmentTracker",
        key: "ShipmentTracker",
        width: "90px",
        editable: true,
        inputType: "int",
        ...getNumberFilterSorter("ShipmentTracker"),
        ...getNumberRenderer(undefined, ""),
    },
    {
        title: "BOL #",
        dataIndex: "BolId",
        key: "BolId",
        editable: true,
        width: "135px",
        ...GetExpandedFilter([], "BolId"),
    },
    {
        title: "Ship Requested",
        dataIndex: "Ship_Requested",
        key: "Ship_Requested",
        editable: true,
        inputType: "date",
        width: "110px",
        ...getDefaultDateSorter("Ship_Requested", searchInputRef),
        ...getDateRenderer(),
    },
    {
        title: "Ship Date Booked",
        dataIndex: "ShipDateBooked",
        key: "ShipDateBooked",
        editable: true,
        inputType: "date",
        width: "110px",
        ...getDefaultDateSorter("ShipDateBooked", searchInputRef),
        ...getDateRenderer(),
    },
    {
        title: "Units Shipped",
        dataIndex: "UnitsShipped",
        key: "UnitsShipped",
        width: "80px",
        editable: true,
        inputType: "int",
        ...getNumberFilterSorter("UnitsShipped"),
        ...getNumberRenderer(undefined, ""),
    },
    {
        title: "Units Backordered",
        dataIndex: "UnitsBackordered",
        key: "UnitsBackordered",
        width: "90px",
        editable: true,
        inputType: "int",
        ...getNumberFilterSorter("UnitsBackordered"),
        ...getNumberRenderer(undefined, ""),
    },
    {
        title: "Backorder Date",
        dataIndex: "BackorderDate",
        key: "BackorderDate",
        editable: true,
        inputType: "date",
        width: "110px",
        ...getDefaultDateSorter("BackorderDate", searchInputRef),
        ...getDateRenderer(),
    },
    {
        title: "Total Cost",
        dataIndex: "TotalCost",
        key: "TotalCost",
        width: "90px",
        editable: true,
        inputType: "price",
        ...getNumberFilterSorter("TotalCost"),
        ...getPriceRenderer(""),
    },
    {
        title: "Cost/Unit",
        dataIndex: "UnitCost",
        key: "UnitCost",
        width: "90px",
        required: true,
        ...getNumberFilterSorter("UnitCost"),
        ...getPriceRenderer(""),
    },
    {
        title: "Shipped To",
        dataIndex: "ShippedTo",
        key: "ShippedTo",
        width: "75px",
        align: "center",
        editable: true,
        ...getDefaultFilterSorter("ShippedTo", searchInputRef),
    },
    {
        title: "Booked?",
        dataIndex: "Booked",
        key: "Booked",
        width: "80px",
        editable: true,
        inputType: "bool",
        ...getBooleanFilter("Booked"),
        ...getBoolRenderer(),
    },
    {
        title: "Shipment Plan File",
        dataIndex: "ShipmentPlanFile",
        key: "ShipmentPlanFile",
        width: "135px",
        editable: true,
        ...getDefaultFilterSorter("ShipmentPlanFile", searchInputRef),
        ...getURLRenderer(),
    },
];

export const makeFreightLogItemizedColumns = (
    searchInputRef: React.RefObject<InputRef>
): EditableColumnType<FreightLogItemizedRowRenderType>[] => [
    {
        title: "Idx",
        dataIndex: "key",
        key: "key",
        width: "40px",
        align: "center",
        ...getDefaultFilterSorter("key", searchInputRef),
        fixed: "left",
    },
    {
        title: "Supplier",
        dataIndex: "Supplier_Name",
        key: "Supplier_Name",
        width: "135px",
        fixed: "left",
        ...GetExpandedFilter([], "Supplier_Name"),
    },
    {
        title: "PO",
        dataIndex: "Supplier_PO",
        key: "Supplier_PO",
        width: "135px",
        fixed: "left",
        ...GetExpandedFilter([], "Supplier_PO"),
    },
    {
        title: "SO",
        dataIndex: "Supplier_SO",
        key: "Supplier_SO",
        width: "135px",
        ...GetExpandedFilter([], "Supplier_SO"),
    },
    {
        title: "Shipment Tracker",
        dataIndex: "ShipmentTracker",
        key: "ShipmentTracker",
        width: "90px",
        ...getNumberFilterSorter("ShipmentTracker"),
        ...getNumberRenderer(undefined, ""),
    },
    {
        title: "ID",
        dataIndex: "ProductId",
        key: "ProductId",
        ...GetExpandedFilter([], "ProductId"),
    },
    {
        title: "BOL #",
        dataIndex: "BolId",
        key: "BolId",
        width: "135px",
        ...GetExpandedFilter([], "BolId"),
    },
    {
        title: "Ship Requested",
        dataIndex: "Ship_Requested",
        key: "Ship_Requested",
        width: "110px",
        ...getDefaultDateSorter("Ship_Requested", searchInputRef),
        ...getDateRenderer(),
    },
    {
        title: "Ship Date Booked",
        dataIndex: "ShipDateBooked",
        key: "ShipDateBooked",
        width: "110px",
        ...getDefaultDateSorter("ShipDateBooked", searchInputRef),
        ...getDateRenderer(),
    },
    {
        title: "Units Shipped",
        dataIndex: "UnitsShipped",
        key: "UnitsShipped",
        width: "80px",
        editable: true,
        inputType: "int",
        ...getNumberFilterSorter("UnitsShipped"),
        ...getNumberRenderer(undefined, ""),
    },
    {
        title: "Units Backordered",
        dataIndex: "UnitsBackordered",
        key: "UnitsBackordered",
        width: "90px",
        editable: true,
        inputType: "int",
        ...getNumberFilterSorter("UnitsBackordered"),
        ...getNumberRenderer(undefined, ""),
    },
    {
        title: "Backorder Date",
        dataIndex: "BackorderDate",
        key: "BackorderDate",
        editable: true,
        inputType: "date",
        width: "110px",
        ...getDefaultDateSorter("BackorderDate", searchInputRef),
        ...getDateRenderer(),
    },
    {
        title: "Total Cost",
        dataIndex: "TotalCost",
        key: "TotalCost",
        width: "90px",
        editable: true,
        inputType: "price",
        ...getNumberFilterSorter("TotalCost"),
        ...getPriceRenderer(""),
    },
    {
        title: "Cost/Unit",
        dataIndex: "UnitCost",
        key: "UnitCost",
        width: "90px",
        required: true,
        ...getNumberFilterSorter("UnitCost"),
        ...getPriceRenderer(""),
    },
    {
        title: "Shipped To",
        dataIndex: "ShippedTo",
        key: "ShippedTo",
        width: "75px",
        align: "center",
        editable: true,
        ...GetExpandedFilter([], "ShippedTo"),
    },
    {
        title: "Booked?",
        dataIndex: "Booked",
        key: "Booked",
        width: "80px",
        editable: true,
        inputType: "bool",
        ...getBooleanFilter("Booked"),
        ...getBoolRenderer(),
    },
    {
        title: "Shipment Plan File",
        dataIndex: "ShipmentPlanFile",
        key: "ShipmentPlanFile",
        width: "135px",
        editable: true,
        ...getDefaultFilterSorter("ShipmentPlanFile", searchInputRef),
        ...getURLRenderer(),
    },
];

export const makeCurrentShipmentLogColumns = (
    searchInputRef: React.RefObject<InputRef>,
    handleSave?: (row: CurrentShipmentLogItemRenderType) => void
): EditableColumnType<CurrentShipmentLogItemRenderType>[] => [
    {
        title: "Idx",
        dataIndex: "key",
        key: "key",
        width: "40px",
        align: "center",
        ...getDefaultFilterSorter("key", searchInputRef),
        fixed: "left",
    },
    {
        title: "Slack",
        dataIndex: "key",
        key: "key",
        width: "80px",
        align: "center",
        fixed: "left",
        render(value, record, index) {
            return (
                <Space size="small" dir="vertical" direction="vertical">
                    <Button
                        type="primary"
                        block
                        icon={<MailOutlined />}
                        onClick={() =>
                            copyToClipboard(
                                // SLACK_CHANNEL_IDS.TEST,
                                `${record.Supplier_Names.join(", ")} Shipment ${record["ShipTo"] ? `to ${record["ShipTo"]}` : ""} - ${
                                    record.Status
                                } - ETA: ${record.ETA ? dayjs(record.ETA).format("MM/DD") : "#N/A"}\n` +
                                    record.TrackerData?.map(
                                        (po) =>
                                            `\nPO: ${po.Supplier_PO} - ${
                                                po.ExistsInTracker
                                                    ? po.Prelisted === "YES"
                                                        ? "Prelisted"
                                                        : "needs to be prelisted"
                                                    : "Add to OMS"
                                            }`
                                    ).join(""),
                                "Slack Message"
                            )
                        }
                    >
                        Log
                    </Button>
                    <Button
                        type="primary"
                        block
                        icon={<MailOutlined />}
                        onClick={() =>
                            copyToClipboard(
                                `*Incoming Shipments*\n` +
                                    `${record.Supplier_Names.join(", ")} via ${record.Carrier}, ETA: ${
                                        record.ETA ? dayjs(record.ETA).format("MM/DD") : "#N/A"
                                    }\n` +
                                    record.TrackerData?.map((po) => `\nPO: ${po.Supplier_PO}`).join("") +
                                    `\n${record.Pallets ?? "??"} pallets, ${record.Units ?? "??"} units` +
                                    `\n${
                                        record.TrackerData?.every((po) => po.Prelisted === "YES") ? "Ready" : "Not ready"
                                    } to process. @Nathan`,
                                "Slack Message"
                            )
                        }
                    >
                        WH
                    </Button>
                </Space>
            );
        },
    },
    {
        title: "Supplier",
        dataIndex: "Supplier_Names",
        key: "Supplier_Names",
        width: "135px",
        fixed: "left",
        editable: true,
        inputType: "suppliers",
        inputOptions: {
            isArray: true,
        },
        ...getArrayRenderer(),
        ...GetExpandedFilter([], "Supplier_Names"),
    },
    {
        title: "Ship Date Booked",
        dataIndex: "ShipDateBooked",
        key: "ShipDateBooked",
        fixed: "left",
        editable: true,
        inputType: "date",
        width: "110px",
        ...getDefaultDateSorter("ShipDateBooked", searchInputRef),
        ...getDateRenderer(),
    },
    {
        title: "POs",
        dataIndex: "Supplier_POs",
        key: "Supplier_POs",
        width: "325px",
        inputType: "tags",
        inputOptions: {
            isArray: true,
        },
        editable: true,
        fixed: "left",
        // ...getArrayRenderer(),
        ...GetExpandedFilter([], "Supplier_POs"),
        render(value, record, index) {
            return (
                <List
                    header={
                        <Row style={{width: "100%", textAlign: "center"}}>
                            <Col span={8}>
                                <Typography.Text style={{fontSize: "9px", fontWeight: "bold"}}>PO</Typography.Text>
                            </Col>
                            <Col span={4}>
                                <Typography.Text style={{fontSize: "9px", fontWeight: "bold"}}>Tracker</Typography.Text>
                            </Col>
                            <Col span={4}>
                                <Typography.Text style={{fontSize: "9px", fontWeight: "bold"}}>Prelisted</Typography.Text>
                            </Col>
                            <Col span={4}>
                                <Typography.Text style={{fontSize: "9px", fontWeight: "bold"}}>Prep</Typography.Text>
                            </Col>
                            <Col span={4}>
                                <Typography.Text style={{fontSize: "9px", fontWeight: "bold"}}>Archived</Typography.Text>
                            </Col>
                        </Row>
                    }
                    bordered
                    size="small"
                    dataSource={record.TrackerData ?? (value ?? []).map((val: string) => ({Supplier_PO: val}))}
                    style={{overflow: "auto", maxHeight: "220px"}}
                    renderItem={(item: any) => {
                        return (
                            <List.Item style={{justifyContent: "center", padding: "4px 12px"}}>
                                <Row style={{width: "100%", textAlign: "center"}}>
                                    <Col span={8} style={{width: "100%", textAlign: "center"}}>
                                        <Typography.Text style={{fontSize: "10px"}}>{item.Supplier_PO}</Typography.Text>
                                    </Col>
                                    <Col span={4}>
                                        <Typography.Text style={{fontSize: "10px"}}>
                                            {record.TrackerData ? (item.ExistsInTracker ? "YES" : "NO") : "?"}
                                        </Typography.Text>
                                    </Col>
                                    <Col span={4}>
                                        <Typography.Text style={{fontSize: "10px"}}>
                                            {record.TrackerData ? item.Prelisted ?? "NO" : "?"}
                                        </Typography.Text>
                                    </Col>
                                    <Col span={4}>
                                        <Typography.Text style={{fontSize: "10px"}}>
                                            {record.TrackerData ? (item.HasInstructions ? "YES" : "NO") : "?"}
                                        </Typography.Text>
                                    </Col>
                                    <Col span={4}>
                                        <Typography.Text style={{fontSize: "10px"}}>
                                            {record.TrackerData ? item.IsArchived ?? "NO" : "?"}
                                        </Typography.Text>
                                    </Col>
                                </Row>
                            </List.Item>
                        );
                    }}
                />
            );
        },
    },
    {
        title: "Pallets",
        dataIndex: "Pallets",
        key: "Pallets",
        width: "80px",
        editable: true,
        inputType: "int",
        ...getNumberFilterSorter("Pallets"),
        ...getNumberRenderer(undefined, ""),
    },
    {
        title: "Units",
        dataIndex: "Units",
        key: "Units",
        width: "80px",
        editable: true,
        inputType: "int",
        ...getNumberFilterSorter("Units"),
        ...getNumberRenderer(undefined, ""),
    },
    {
        title: "Ship Value",
        dataIndex: "ShipValue",
        key: "ShipValue",
        width: "90px",
        required: true,
        editable: true,
        inputType: "price",
        ...getNumberFilterSorter("ShipValue"),
        ...getPriceRenderer(""),
    },
    {
        title: "Ship To",
        dataIndex: "ShipTo",
        key: "ShipTo",
        width: "90px",
        editable: true,
        inputType: "editable select",
        inputOptions: {
            selectableValues: SHIP_TO_VALUES.slice(),
        },
        ...GetExpandedFilter([], "ShipTo"),
    },
    {
        title: "Carrier",
        dataIndex: "Carrier",
        key: "Carrier",
        width: "135px",
        editable: true,
        inputType: "editable select",
        inputOptions: {
            selectableValues: CARRIERS.slice(),
        },
        ...GetExpandedFilter([], "Carrier"),
    },
    {
        title: "ETA",
        dataIndex: "ETA",
        key: "ETA",
        editable: true,
        inputType: "date",
        width: "110px",
        ...getDefaultDateSorter("ETA", searchInputRef),
        ...getDateRenderer(),
    },
    {
        title: "Arrived",
        dataIndex: "Arrived",
        key: "Arrived",
        editable: true,
        inputType: "date",
        width: "110px",
        ...getDefaultDateSorter("Arrived", searchInputRef),
        ...getDateRenderer(),
    },
    {
        title: "Shipment Plan File",
        dataIndex: "ShipmentPlan",
        key: "ShipmentPlan",
        width: "85px",
        editable: true,
        ...getDefaultFilterSorter("ShipmentPlan", searchInputRef),
        ...getURLRenderer(),
    },
    {
        title: "Add to FL?",
        dataIndex: "AddToFL",
        key: "AddToFL",
        width: "80px",
        editable: true,
        inputType: "bool",
        ...getBooleanFilter("AddToFL"),
        ...getBoolRenderer(),
    },
    {
        title: "Tracking",
        dataIndex: "Tracking",
        key: "Tracking",
        width: "135px",
        editable: true,
        ...GetExpandedFilter([], "Tracking"),
        ...getURLRenderer(),
    },
    {
        title: "Notes",
        dataIndex: "Notes",
        key: "Notes",
        width: "325px",
        // editable: true,
        ...getDefaultFilterSorter("Notes", searchInputRef),
        render: (value, record) => (
            <NotesRenderer
                notes={value}
                editable={!!handleSave}
                onFinish={(notes) => {
                    const newRecord: CurrentShipmentLogItemRenderType = {
                        ...record,
                        Notes: notes,
                    };
                    handleSave?.(newRecord);
                }}
            />
        ),
    },
    {
        title: "Status",
        dataIndex: "Status",
        key: "Status",
        width: "135px",
        editable: true,
        inputType: "select",
        inputOptions: {
            selectableValues: CURRENT_SHIPMENT_STATUSES.slice(),
        },
        ...GetExpandedFilter([], "Status"),
    },
];

export const makeInquiriesColumns = (
    searchInputRef: React.RefObject<InputRef>,
    handleSave?: (row: InquiryItemRenderType) => void
): EditableColumnType<InquiryItemRenderType>[] => [
    {
        title: "Idx",
        dataIndex: "key",
        key: "key",
        width: "40px",
        align: "center",
        ...GetExpandedFilter([], "key"),
        render(value, record, index) {
            return {
                children: (
                    <>
                        <b>{record.key as number}</b>
                        <br />
                        <i>{dayjs(record.Timestamp).format("MM/DD/YYYY")}</i>
                    </>
                ),
            };
        },
    },
    {
        title: "Status",
        dataIndex: "Status",
        key: "Status",
        width: "105px",
        editable: true,
        inputType: "text",
        ...GetExpandedFilter([], "Status"),
    },
    {
        title: "POs",
        dataIndex: "Supplier_POs",
        key: "Supplier_POs",
        width: "350px",
        inputType: "tags",
        inputOptions: {
            isArray: true,
        },
        fixed: "left",
        ...GetExpandedFilter([], "Supplier_POs"),
        render(value, record, index) {
            return (
                <List
                    header={
                        <Row style={{width: "100%", textAlign: "center"}}>
                            <Col span={6}>
                                <Typography.Text style={{fontSize: "9px", fontWeight: "bold"}}>PO</Typography.Text>
                            </Col>
                            <Col span={6}>
                                <Typography.Text style={{fontSize: "9px", fontWeight: "bold"}}>Quantity</Typography.Text>
                            </Col>
                            <Col span={6}>
                                <Typography.Text style={{fontSize: "9px", fontWeight: "bold"}}>Date</Typography.Text>
                            </Col>
                            <Col span={6}>
                                <Typography.Text style={{fontSize: "9px", fontWeight: "bold"}}>Invoice</Typography.Text>
                            </Col>
                        </Row>
                    }
                    key={record.ASIN}
                    bordered
                    size="small"
                    dataSource={record.Supplier_POs}
                    rowKey={(record) => record.Supplier_PO}
                    style={{overflowY: "auto", overflowX: "hidden", maxHeight: "220px", maxWidth: "100%"}}
                    renderItem={(item: any) => {
                        return (
                            <List.Item style={{justifyContent: "center", padding: "4px 12px"}}>
                                <Row style={{width: "100%", textAlign: "center"}}>
                                    <Col span={6} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        {item.Supplier_SO ? (
                                            <Typography.Text style={{fontSize: "12px"}}>
                                                <b>PO:</b> {item.Supplier_PO}
                                                <br />
                                                <b>SO:</b> {item.Supplier_SO}
                                            </Typography.Text>
                                        ) : (
                                            <Typography.Text style={{fontSize: "12px"}}>{item.Supplier_PO}</Typography.Text>
                                        )}
                                    </Col>
                                    <Col span={6} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <Typography.Text style={{fontSize: "10px"}}>
                                            {item.Shipped ?? 0} / {item.Quantity}
                                        </Typography.Text>
                                    </Col>
                                    <Col span={6} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <Typography.Text style={{fontSize: "10px"}}>
                                            {item.Supplier_Date ? dayjs(item.Supplier_Date).format("YYYY-MM-DD") : "N/A"}
                                        </Typography.Text>
                                    </Col>
                                    <Col span={6} style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
                                        <Typography.Text style={{fontSize: "10px"}}>
                                            {item.Invoices?.length > 0
                                                ? item.Invoices.map((invoice: InvoiceData) =>
                                                      isValidUrl(invoice.Dropbox_URL) ? (
                                                          <a
                                                              href={invoice.Dropbox_URL}
                                                              target="_blank"
                                                              rel="noreferrer"
                                                              onClick={(event) => event.stopPropagation()}
                                                              style={{
                                                                  wordWrap: "break-word",
                                                                  width: "125px",
                                                                  overflowWrap: "break-word",
                                                                  display: "block",
                                                                  wordBreak: "break-word",
                                                              }}
                                                          >
                                                              {invoice.InvoiceId ?? "Invoice"}
                                                          </a>
                                                      ) : (
                                                          item.Invoice
                                                      )
                                                  )
                                                : "N/A"}
                                        </Typography.Text>
                                    </Col>
                                </Row>
                            </List.Item>
                        );
                    }}
                />
            );
        },
    },
    // {
    //     title: "InvsToUse",
    //     dataIndex: "InvsToUse",
    //     key: "InvsToUse",
    //     width: "105px",
    //     editable: true,
    //     inputType: "text",
    //     ...GetExpandedFilter([], "InvsToUse"),
    // },
    {
        title: "Sold 365D",
        dataIndex: "UnitsSold",
        key: "UnitsSold",
        width: "80px",
        editable: false,
        inputType: "int",
        ...getNumberFilterSorter("UnitsSold"),
        ...getNumberRenderer(0, ""),
    },
    {
        title: "Stock",
        dataIndex: "UnitsInStock",
        key: "UnitsInStock",
        width: "80px",
        editable: false,
        inputType: "int",
        ...getNumberFilterSorter("UnitsInStock"),
        ...getNumberRenderer(0, ""),
    },
    {
        title: "ASIN",
        dataIndex: "ASIN",
        key: "ASIN",
        width: "75px",
        align: "center",
        editable: true,
        ...GetExpandedFilter([], "ASIN"),
    },
    {
        title: "Title",
        dataIndex: "Title",
        key: "Title",
        width: "175px",
        align: "center",
        editable: true,
        ...GetExpandedFilter([], "Title"),
    },
    {
        title: "UPC",
        dataIndex: "UPC",
        key: "UPC",
        width: "75px",
        align: "center",
        editable: true,
        ...GetExpandedFilter([], "UPC"),
    },
    {
        title: "SKU",
        dataIndex: "SKU",
        key: "SKU",
        width: "105px",
        align: "center",
        editable: true,
        ...GetExpandedFilter([], "SKU"),
    },
    {
        title: "Submitted?",
        dataIndex: "Submitted",
        key: "Submitted",
        width: "80px",
        editable: true,
        inputType: "bool",
        ...getBooleanFilter("Submitted"),
        ...getBoolRenderer(),
    },
    {
        title: "Notes",
        dataIndex: "Notes",
        key: "Notes",
        width: "325px",
        // editable: true,
        ...getDefaultFilterSorter("Notes", searchInputRef),
        render: (value, record) => (
            <StrictNotesRenderer
                key={record.key}
                notes={value}
                maxHeight={300}
                editable={!!handleSave}
                onFinish={(notes) => {
                    const newRecord: InquiryItemRenderType = {
                        ...record,
                        Notes: notes,
                    };
                    handleSave?.(newRecord);
                }}
            />
        ),
    },
];
