import {notification} from "antd";
import {AppState, ImmerStateCreator, PrintSlice} from "../../types/storeTypes";
import qz from "qz-tray";
import {generateLabel, printLabel} from "../../services/WarehouseService";

export const createPrintSlice: ImmerStateCreator<AppState, PrintSlice> = (set, get) => ({
    selectedPrinter: undefined,
    printOption: "IMAGE",
    printerLoading: false,
    printCount: 0,
    isVerified: false,
    setIsVerified: (verified) => {
        set((state) => {
            state.isVerified = verified;
        });
    },
    setSelectedPrinter: (printer) => {
        set((state) => {
            state.selectedPrinter = printer;
        });
    },
    setPrintOption: (option) => {
        set((state) => {
            state.printOption = option;
        });
    },
    handlePrint: async (item, quantity) => {
        const {selectedPrinter, printOption, printCount, isVerified} = get();
        set((state) => {
            state.printerLoading = true;
        });
        try {
            if (!item.fnsku) {
                throw new Error("FNSKU is missing. You have to first choose correct inventory data.");
            }
            if (!qz.websocket.isActive()) {
                throw new Error("Printer not connected");
            }
            if (!selectedPrinter) {
                throw new Error("No printer selected");
            }
            if (printCount === 1 && !isVerified) {
                throw new Error("To continue printing, please verify if the previous label has been printed correctly.");
            }
            const label = await generateLabel(item.fnsku, item.name || "N/A", item.upc || "N/A", item.centerId, printOption);

            await printLabel(selectedPrinter, label, quantity);
            set((state) => {
                state.printCount += 1;
            });
        } catch (error: any) {
            notification.error({message: "Error", description: error.message});
        }
        set((state) => {
            state.printerLoading = false;
        });
    },
});
