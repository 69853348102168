import React, {useCallback, useEffect, useState} from "react";
import {setupPrinter} from "../../../services/WarehouseService";
import {Button, notification, Row, Select, Space, Tooltip, Typography} from "antd";
import PrinterListDialog from "./PrinterListDialog";
import {useStore} from "../../../store/useStore";
import {PrinterOutlined} from "@ant-design/icons";
import VerifyLabelDialog from "./VerifyLabelDialog";

interface Props {}

const PrintHandler: React.FC<Props> = () => {
    const [printers, setPrinters] = useState<string[]>([]);
    const {selectedPrinter, setSelectedPrinter, printOption, setPrintOption, printCount, isVerified, setIsVerified} = useStore((state) => ({
        selectedPrinter: state.selectedPrinter,
        printOption: state.printOption,
        setSelectedPrinter: state.setSelectedPrinter,
        setPrintOption: state.setPrintOption,
        printCount: state.printCount,
        isVerified: state.isVerified,
        setIsVerified: state.setIsVerified,
    }));
    const [showPrinterDialog, setShowPrinterDialog] = useState<boolean>(false);
    const [showVerifyDialog, setShowVerifyDialog] = useState<boolean>(false);

    const startPrinter = useCallback(async () => {
        try {
            const foundPrinters = await setupPrinter();
            setPrinters(foundPrinters);
            const savedPrinter = localStorage.getItem("printerName");
            if (savedPrinter && foundPrinters.includes(savedPrinter)) {
                setSelectedPrinter(savedPrinter);
            }
        } catch (error: any) {
            notification.error({message: "Error", description: error.message});
        }
    }, [setPrinters, setSelectedPrinter]);

    useEffect(() => {
        startPrinter();
    }, [startPrinter]);

    let buttonColor: string | undefined = undefined;
    if (printCount === 1 && !isVerified) {
        buttonColor = "orange";
    } else if (isVerified) {
        buttonColor = "green";
    }
    return (
        <Row style={{justifyContent: "end"}}>
            <Space>
                <Typography.Text strong>Printer: </Typography.Text>
                <Typography.Text ellipsis={true} style={{width: 100}}>
                    {selectedPrinter || "None"}
                </Typography.Text>
                <Button onClick={() => setShowPrinterDialog(true)}>Choose</Button>
            </Space>
            <Select
                style={{width: 80, marginLeft: 8, marginRight: 8}}
                options={[
                    {label: "ZPL", value: "ZPL"},
                    {label: "Image", value: "IMAGE"},
                ]}
                value={printOption}
                onChange={setPrintOption}
            />
            <Tooltip title="Verify if the first label is correct">
                <Button
                    style={{color: buttonColor}}
                    shape="circle"
                    icon={<PrinterOutlined />}
                    disabled={printCount < 1}
                    onClick={() => setShowVerifyDialog(true)}
                />
            </Tooltip>

            <PrinterListDialog
                printers={printers}
                selectedPrinter={selectedPrinter}
                onSelectPrinter={(printer) => {
                    setSelectedPrinter(printer);
                    localStorage.setItem("printerName", printer);
                }}
                open={showPrinterDialog}
                onCancel={() => {
                    setShowPrinterDialog(false);
                }}
            />
            <VerifyLabelDialog
                open={showVerifyDialog}
                onCancel={() => setShowVerifyDialog(false)}
                onVerify={() => {
                    setIsVerified(true);
                    setShowVerifyDialog(false);
                }}
            />
        </Row>
    );
};

export default PrintHandler;
