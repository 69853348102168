import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {finishBatch, getReceivedBatches, saveReceivedProducts, updateShipments} from "../../../services/WarehouseService";
import {Batch, BatchCategory, InboundShipmentPlanStatus, WarehouseProduct, batchCategories} from "../../../types/WarehouseTypes";
import {createInboundShipmentPlan, createInboundShipment} from "../../../services/SPApiService";
import {parseBatches} from "./helpers";
import {Button, Divider, Space, Spin, Tabs, TabsProps, notification} from "antd";
import BatchTable from "./BatchTable";
import {
    InboundShipmentPlan,
    LabelPrepPreference,
    ShipmentStatus,
} from "@scaleleap/selling-partner-api-sdk/lib/api-models/fulfillment-inbound-api-model";
import ShipmentsInfoDialog from "./ShipmentsInfoDialog";
import {useStore} from "../../../store/useStore";
import PrintHandler from "./PrintHandler";

const ShipmentPlanningDashboard: React.FC = () => {
    const {printerLoading, handlePrintLabels, warehouseAddress} = useStore((state) => ({
        printerLoading: state.printerLoading,
        handlePrintLabels: state.handlePrint,
        warehouseAddress: state.warehouseAddress,
    }));
    const [scannedItems, setScannedItems] = useState<{
        [key in BatchCategory]?: WarehouseProduct[];
    }>({HazMat: []});

    const [shipmentPlans, setShipmentPlans] = useState<InboundShipmentPlan[]>([]);
    const [shipmentPlansStatuses, setShipmentPlansStatuses] = useState<InboundShipmentPlanStatus[]>([]);
    const [shipmentPlansLoading, setShipmentPlansLoading] = useState<boolean>(false);
    const [createShipmentsLoading, setCreateShipmentsLoading] = useState<boolean>(false);
    const [showPlacementOptions, setShowPlacementOptions] = useState<boolean>(false);
    const [currentCategory, setCurrentCategory] = useState<BatchCategory>(batchCategories[0]);
    const queryClient = useQueryClient();

    const {
        data: batches,
        isLoading: batchesLoading,
        isRefetching: batchesRefetching,
    } = useQuery({
        queryKey: ["shipment_planning_batches"],
        queryFn: async () => {
            const data = await getReceivedBatches();
            return data;
        },
    });

    const saveSubmittedProducts = async (products: WarehouseProduct[], submittedPlans: InboundShipmentPlan[]) => {
        const productsWithoutSKU = products.filter((product) => !product.sku);
        if (productsWithoutSKU.length > 0) {
            throw new Error(`Products without SKU: ${productsWithoutSKU.map((product) => product.asin).join(", ")}`);
        }
        const mergedItems: {[key: string]: number} = {};

        submittedPlans.forEach((plan) => {
            plan.Items.forEach((item) => {
                const {SellerSKU, Quantity} = item;
                if (mergedItems[SellerSKU]) {
                    mergedItems[SellerSKU] += Quantity;
                } else {
                    mergedItems[SellerSKU] = Quantity;
                }
            });
        });

        const mergedProducts: WarehouseProduct[] = products.map((product) => {
            const submittedQuantity = mergedItems[product.sku || ""] || 0;
            return {...product, submittedQuantity};
        });

        setScannedItems((prev) => ({...prev, [currentCategory]: mergedProducts}));

        await saveReceivedProducts(
            mergedProducts
                .filter((product) => product.submittedQuantity && product.submittedQuantity > 0)
                .map(({asin, batchId, submittedQuantity}) => ({asin, batchId, submittedQuantity}))
        );
    };

    const submittedProductsMutation = useMutation({
        mutationFn: async (submittedPlans: InboundShipmentPlan[]) => {
            await saveSubmittedProducts(scannedItems[currentCategory] || [], submittedPlans);
        },
        onSuccess: () => {},
        onError: (error: any) => {
            notification.error({
                message: "Could not save submitted products",
                description: error.message,
            });
        },
        retry: 3,
    });

    const finishBatchMutation = useMutation({
        mutationFn: async (category: BatchCategory) => {
            const batch = await finishBatch(category);
            await updateShipments();
            return batch;
        },
        onSuccess: async (newBatch: Batch | undefined) => {
            queryClient.refetchQueries({queryKey: ["batches"]});
            notification.success({
                message: "Success",
                description: "Successfully finished batch",
            });
            if (newBatch) {
                setScannedItems((prev) => ({...prev, [newBatch.category]: newBatch.items}));
                setShipmentPlans([]);
                setShipmentPlansStatuses([]);
                setShowPlacementOptions(false);
            }
        },
        onError: (error: any) => {
            notification.error({
                message: "Could not finish batch",
                description: error.message,
            });
        },
        retry: 3,
    });

    useEffect(() => {
        if (batches) {
            const items = parseBatches(batches);
            setScannedItems(items);
        }
    }, [batches]);

    const submitBatch = useCallback(
        async (items: WarehouseProduct[], category: BatchCategory) => {
            setShipmentPlansLoading(true);
            setCurrentCategory(category);
            try {
                if (!warehouseAddress) {
                    throw new Error("Warehouse address is not set");
                }

                const res = await createInboundShipmentPlan(items, warehouseAddress);
                if (!res.InboundShipmentPlans) {
                    throw new Error("Failed to create inbound shipment plan");
                }
                setShipmentPlans(res.InboundShipmentPlans);
                setShowPlacementOptions(true);
            } catch (error: any) {
                console.log(error);
                notification.error({
                    message: "Error",
                    description: error.message,
                });
            }
            setShipmentPlansLoading(false);
        },
        [warehouseAddress]
    );

    const createShipments = async (plans: InboundShipmentPlan[]) => {
        const statuses = shipmentPlansStatuses.map((status) => ({...status}));

        if (!warehouseAddress) {
            notification.error({
                message: "Warehouse address is not set",
            });
            return;
        }
        const shipmentName = `${new Date().toLocaleString("en-US")} WISC RAPIDS`;

        setCreateShipmentsLoading(true);

        for (const plan of plans) {
            const status = statuses.find((status) => status.shipmentId === plan.ShipmentId);
            try {
                if (status && status.status === "SUCCESS") {
                    continue;
                }

                await createInboundShipment(plan, shipmentName, LabelPrepPreference.SellerLabel, ShipmentStatus.Working, warehouseAddress);
                if (status) {
                    status.status = "SUCCESS";
                } else {
                    statuses.push({shipmentId: plan.ShipmentId, status: "SUCCESS"});
                }
            } catch (error: any) {
                console.log(error);
                if (status) {
                    status.status = "ERROR";
                    status.error = error.message;
                } else {
                    statuses.push({shipmentId: plan.ShipmentId, status: "ERROR", error: error.message});
                }
            }
        }
        submittedProductsMutation.mutate(
            shipmentPlans.filter((plan) => statuses.find((status) => status.shipmentId === plan.ShipmentId)?.status === "SUCCESS")
        );

        setShipmentPlansStatuses(statuses);
        setCreateShipmentsLoading(false);
    };

    const tableTitle = useCallback(
        (data: readonly WarehouseProduct[], category: BatchCategory) => (
            <Space direction="horizontal" style={{width: "100%", justifyContent: "start"}} split={<Divider type="vertical" />}>
                <Button
                    onClick={() => {
                        submitBatch(
                            data.map((item) => ({...item})),
                            category
                        );
                    }}
                >
                    Submit
                </Button>
            </Space>
        ),
        [submitBatch]
    );

    const globalIitems: TabsProps["items"] = useMemo(
        () =>
            batchCategories
                .filter((category) => (scannedItems[category] || []).length > 0)
                .map((category) => {
                    const items = scannedItems[category] || [];
                    const units = items.reduce((acc, item) => acc + item.quantity, 0);
                    return {
                        key: category,
                        label: `${category} (${items.length} SKUs, ${units} units)`,
                        children: (
                            <BatchTable
                                title={(data) => (category !== "Damaged" ? tableTitle(data, category) : null)}
                                items={items}
                                onPrintLabels={(item, quantity) => {
                                    handlePrintLabels(item, quantity);
                                }}
                            />
                        ),
                    };
                }),
        [scannedItems, tableTitle, handlePrintLabels]
    );

    return (
        <Spin spinning={batchesLoading || shipmentPlansLoading || batchesRefetching || printerLoading}>
            <PrintHandler />
            <Divider />
            <Tabs centered defaultActiveKey={batchCategories[0]} items={globalIitems} />
            <ShipmentsInfoDialog
                shipmentPlans={shipmentPlans}
                shipmentPlansStatuses={shipmentPlansStatuses}
                currentBatch={scannedItems[currentCategory] || []}
                open={showPlacementOptions}
                onClose={() => setShowPlacementOptions(false)}
                onCreateShipments={(ids) => {
                    createShipments(shipmentPlans.filter((plan) => ids.includes(plan.ShipmentId)));
                }}
                onFinishBatch={() => {
                    finishBatchMutation.mutate(currentCategory);
                }}
                createShipmentsLoading={createShipmentsLoading || submittedProductsMutation.isPending}
                finishBatchLoading={finishBatchMutation.isPending}
            />
        </Spin>
    );
};

export default ShipmentPlanningDashboard;
