import {Card, Col, Row, Statistic, Modal, Form, Input, Select, Divider, message, Tag} from "antd";
import React, {useState, useEffect, useCallback, Fragment} from "react";
import {EditOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import {useSupplierContext} from "../../contexts/SupplierContext";
import {
    POSSIBLE_GROWTH_PLANS,
    POSSIBLE_PAYMENT_METHODS,
    POSSIBLE_PAYMENT_TERMS,
    SUPPLIER_MANAGERS,
    SUPPLIER_USERS,
    SupplierDataValues,
} from "../../types/OmegaTypes";
import "./values/styles.css";
import {valueType} from "antd/es/statistic/utils";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";

const BRAND_VALUES_FORMATS: {
    [key in keyof SupplierDataValues]: any;
} = {
    brandManager: {span: 5},
    brandStrategist: {span: 5},
    brandStakeholders: {span: 5},
    salesRep: {span: 5},
    brandChannel: {span: 4},

    monthlyRevenue: {span: 6},
    ourRevenue: {span: 6},
    goalRevenue: {span: 6},
    growthPlan: {span: 3},
    isNegotiable: {span: 3},

    isDistributor: {span: 3},
    isExclusive: {span: 3},
    mapPolicyValue: {span: 3},
    b2bMapPolicy: {span: 3},
    couponable: {span: 4},
    paymentMethod: {span: 4},
    paymentTerms: {span: 4},
    isDistributorText: {
        span: 3,
        height: "100px",
    },
    isExclusiveText: {
        span: 3,
        height: "100px",
    },
    mapPolicyValueText: {
        span: 3,
        height: "100px",
    },
    b2bMapPolicyText: {
        span: 3,
        height: "100px",
    },
    couponableText: {
        span: 4,
        height: "100px",
    },
    paymentMethodText: {
        span: 4,
        height: "100px",
    },
    paymentTermsText: {
        span: 4,
        height: "100px",
    },

    bookingWindows: {
        span: 6,
        height: "100px",
    },
    bookingRevisionPolicy: {
        span: 6,
        height: "100px",
    },
    atsGuide: {
        span: 6,
        height: "100px",
    },
    orderNotes: {
        span: 6,
        height: "100px",
    },

    orderForm: {
        span: 6,
        height: "100px",
    },
    priceList: {
        span: 6,
        height: "100px",
    },
    discountRules: {
        span: 6,
        height: "100px",
    },
    shippingNotes: {
        span: 6,
        height: "100px",
    },

    // prepDelay: { span: 6 },
    // prepCost: { span: 6 },
    // shippingCost: { span: 6 },
    // returnRate: { span: 6 },
};

const BRAND_VALUES_LABELS: {
    [key in keyof SupplierDataValues]: string;
} = {
    // span of 8
    brandManager: "Lead Buyer",
    brandStrategist: "Brand Strategists",
    brandStakeholders: "Brand Stakeholders",
    salesRep: "Sales Rep",
    brandChannel: "Brand Channel",

    // span of 6
    monthlyRevenue: "Monthly Revenue",
    ourRevenue: "Our Revenue",
    goalRevenue: "Goal Revenue",
    growthPlan: "Growth Plan",
    isNegotiable: "Is Negotiable?",

    // span of 4
    isDistributor: "Is Distributor?",
    isExclusive: "Is Exclusive?",
    mapPolicyValue: "MAP Policy (Follow?)",
    b2bMapPolicy: "B2B Map Policy (Follow?)",
    couponable: "Couponable?",
    paymentMethod: "Payment Method",
    paymentTerms: "Payment Terms",
    isDistributorText: "(Text) Is Distributor?",
    isExclusiveText: "(Text) Is Exclusive?",
    mapPolicyValueText: "(Text) MAP Policy",
    b2bMapPolicyText: "(Text) B2B Map Policy",
    couponableText: "(Text) Couponable?",
    paymentMethodText: "(Text) Payment Method",
    paymentTermsText: "(Text) Payment Terms",

    // span of 6
    bookingWindows: "Booking Windows",
    bookingRevisionPolicy: "Booking Revision Policy",
    atsGuide: "How to get ATS?",
    orderNotes: "Order Notes",

    // span of 6
    orderForm: "Order Form",
    priceList: "Price List",
    discountRules: "Discount Rules",
    shippingNotes: "Shipping Notes",

    // span of 6
    // prepDelay: "Prep Delay",
    // prepCost: "Prep Cost",
    // shippingCost: "Shipping Cost",
    // returnRate: "Return Rate",
};

const BRAND_DEFAULT_VALUES: SupplierDataValues = {
    brandManager: "",
    brandStrategist: [],
    brandStakeholders: [],
    salesRep: "",
    brandChannel: "",

    monthlyRevenue: 0,
    ourRevenue: 0,
    goalRevenue: 0,
    growthPlan: "Maximize",
    isNegotiable: false,

    isDistributor: false,
    isExclusive: false,
    mapPolicyValue: false,
    b2bMapPolicy: false,
    couponable: false,
    paymentMethod: "",
    paymentTerms: "",
    isDistributorText: "",
    isExclusiveText: "",
    mapPolicyValueText: "",
    b2bMapPolicyText: "",
    couponableText: "",
    paymentMethodText: "",
    paymentTermsText: "",

    bookingWindows: "",
    bookingRevisionPolicy: "",
    atsGuide: "",
    orderNotes: "",

    orderForm: "",
    priceList: "",

    discountRules: "",
    shippingNotes: "",

    // prepDelay: 0,
    // prepCost: 0,
    // shippingCost: 0,
    // returnRate: 0,
};

const CreateValueDisplay = (supplierDataValues: SupplierDataValues, editValue: (dataIndex: keyof SupplierDataValues) => void) => {
    let currentSpanCount = 0;
    return Object.entries(BRAND_VALUES_LABELS).map(([dataIndex, label], idx) => {
        let currIndex = dataIndex as keyof SupplierDataValues;
        let currentColNode = (
            <Col key={`${idx}-${dataIndex}`} span={BRAND_VALUES_FORMATS[currIndex].span}>
                <Card bordered={false}>
                    <Statistic
                        className="value-statistic"
                        title={
                            <span>
                                {label} <EditOutlined onClick={() => editValue(currIndex)} />
                            </span>
                        }
                        value={supplierDataValues[currIndex] as valueType}
                        valueStyle={{
                            overflowY: "auto",
                            overflowWrap: "break-word",
                            maxWidth: "100%",
                            fontSize: "12px",
                            height: BRAND_VALUES_FORMATS[currIndex].height ?? null,
                        }}
                        formatter={(value) => {
                            if (typeof value === "boolean") {
                                return value ? "Yes" : "No";
                            } else if (typeof value === "string") {
                                return (
                                    <Markdown className={"markdown-div"} remarkPlugins={[remarkGfm]}>
                                        {value}
                                    </Markdown>
                                );
                            } else if (Array.isArray(value)) {
                                return value.map((val) => <Tag>{val}</Tag>);
                            }

                            return value.toString();
                        }}
                    />
                </Card>
            </Col>
        );

        currentSpanCount += BRAND_VALUES_FORMATS[currIndex].span;
        if (currentSpanCount === 24) {
            currentSpanCount = 0;
            return (
                <Fragment key={`${idx}-${dataIndex}-fragment`}>
                    {currentColNode}
                    <Col span={24} key={`${idx}-${dataIndex}-divider`}>
                        <Divider type="horizontal"></Divider>
                    </Col>
                </Fragment>
            );
        } else {
            return currentColNode;
        }
    });
};

const GetBrandValuesLabel = (dataIndex: keyof SupplierDataValues) => {
    return BRAND_VALUES_LABELS[dataIndex];
};

const GetBrandValuesEditInput = (dataIndex: keyof SupplierDataValues) => {
    switch (dataIndex) {
        case "isDistributor":
            return (
                <Select>
                    <Select.Option value={true}>Yes</Select.Option>
                    <Select.Option value={false}>No</Select.Option>
                </Select>
            );
        case "isExclusive":
            return (
                <Select>
                    <Select.Option value={true}>Yes</Select.Option>
                    <Select.Option value={false}>No</Select.Option>
                </Select>
            );
        case "mapPolicyValue":
            return (
                <Select>
                    <Select.Option value={true}>Yes</Select.Option>
                    <Select.Option value={false}>No</Select.Option>
                </Select>
            );
        case "b2bMapPolicy":
            return (
                <Select>
                    <Select.Option value={true}>Yes</Select.Option>
                    <Select.Option value={false}>No</Select.Option>
                </Select>
            );
        case "couponable":
            return (
                <Select>
                    <Select.Option value={true}>Yes</Select.Option>
                    <Select.Option value={false}>No</Select.Option>
                </Select>
            );
        case "isNegotiable":
            return (
                <Select>
                    <Select.Option value={true}>Yes</Select.Option>
                    <Select.Option value={false}>No</Select.Option>
                </Select>
            );
        case "paymentMethod":
            return (
                <Select>
                    {POSSIBLE_PAYMENT_METHODS.map((method, idx) => (
                        <Select.Option key={`1-${idx}`} value={method}>
                            {method}
                        </Select.Option>
                    ))}
                </Select>
            );
        case "growthPlan":
            return (
                <Select>
                    {POSSIBLE_GROWTH_PLANS.map((method, idx) => (
                        <Select.Option key={`5-${idx}`} value={method}>
                            {method}
                        </Select.Option>
                    ))}
                </Select>
            );
        case "paymentTerms":
            return (
                <Select>
                    {POSSIBLE_PAYMENT_TERMS.map((method, idx) => (
                        <Select.Option key={`2-${idx}`} value={method}>
                            {method}
                        </Select.Option>
                    ))}
                </Select>
            );
        case "brandStrategist":
            return (
                <Select mode="multiple">
                    {SUPPLIER_USERS.map((method, idx) => (
                        <Select.Option key={`3-${idx}`} value={method}>
                            {method}
                        </Select.Option>
                    ))}
                </Select>
            );
        case "brandStakeholders":
            return (
                <Select mode="multiple">
                    {[...SUPPLIER_USERS, ...SUPPLIER_MANAGERS].map((method, idx) => (
                        <Select.Option key={`3-${idx}`} value={method}>
                            {method}
                        </Select.Option>
                    ))}
                </Select>
            );
        case "brandManager":
            return (
                <Select>
                    {SUPPLIER_MANAGERS.map((method, idx) => (
                        <Select.Option key={`4-${idx}`} value={method}>
                            {method}
                        </Select.Option>
                    ))}
                </Select>
            );
        case "bookingWindows":
        case "bookingRevisionPolicy":
        case "atsGuide":
        case "orderNotes":
        case "orderForm":
        case "priceList":
        case "discountRules":
        case "shippingNotes":
        case "isDistributorText":
        case "isExclusiveText":
        case "mapPolicyValueText":
        case "b2bMapPolicyText":
        case "couponableText":
        case "paymentMethodText":
        case "paymentTermsText":
            return <Input.TextArea rows={4} />;
        default:
            return <Input title={`New ${dataIndex}`} />;
    }
};

const BrandValues: React.FC = () => {
    const {selectedSupplier: supplier, updateSupplier} = useSupplierContext();
    const [modal, contextHolder] = Modal.useModal();
    const [supplierDataValues, setSupplierDataValues] = useState<SupplierDataValues>(BRAND_DEFAULT_VALUES);
    const [tagForm] = Form.useForm();

    useEffect(() => {
        setSupplierDataValues({
            ...BRAND_DEFAULT_VALUES,
            ...(supplier?.dataValues ?? {}),
        });
    }, [supplier?.dataValues]);

    useEffect(() => {
        if (tagForm) {
            tagForm.setFieldsValue(supplierDataValues);
        }
    }, [tagForm, supplierDataValues]);

    const editValue = useCallback(
        (dataIndex: keyof SupplierDataValues) => {
            modal.confirm({
                title: `Fill in the values for this action`,
                icon: <ExclamationCircleOutlined />,
                content: (
                    <Form form={tagForm} name={`edit-${dataIndex}`} layout="vertical" preserve={true}>
                        <Form.Item name={dataIndex} key={dataIndex} label={`What should we set the ${GetBrandValuesLabel(dataIndex)} to?`}>
                            {GetBrandValuesEditInput(dataIndex)}
                        </Form.Item>
                    </Form>
                ),
                onOk() {
                    tagForm.validateFields().then((values) => {
                        console.log("bulk form values", values);
                        if (values[dataIndex] !== undefined) {
                            updateSupplier(
                                {
                                    name: supplier!.name,
                                    dataValues: {
                                        ...supplierDataValues,
                                        [dataIndex]: values[dataIndex],
                                    },
                                },
                                "values",
                                GetBrandValuesLabel(dataIndex),
                                `*${GetBrandValuesLabel(dataIndex)}* has been updated to: ${
                                    values[dataIndex]?.length > 100 ? values[dataIndex].slice(0, 100) + "..." : values[dataIndex]
                                }`
                            );

                            tagForm.setFieldsValue({
                                ...supplierDataValues,
                                [dataIndex]: values[dataIndex],
                            });
                        } else {
                            message.error("Please fill in the form");
                        }
                    });
                },
                onCancel() {
                    tagForm.setFieldsValue({...supplierDataValues});
                },
            });
        },
        [modal, tagForm, supplier, updateSupplier, supplierDataValues]
    );

    return (
        <>
            {contextHolder}
            <Row gutter={[8, 0]} key={"xdd-row"}>
                {CreateValueDisplay(supplierDataValues, editValue)}
            </Row>
        </>
    );
};

export default BrandValues;
