import {QuestionCircleOutlined} from "@ant-design/icons";
import {useQueryClient, useQuery} from "@tanstack/react-query";
import {Button, Divider, Modal, Popconfirm, Space, Spin} from "antd";
import dayjs, {Dayjs} from "dayjs";
import React, {useEffect, useState} from "react";
import {useAuth} from "../../contexts/AuthContext";
import {getOrderSummariesForIDs, getReturnData} from "../../services/OmegaService";
import {
    compareSupplierValues,
    deleteCollection,
    getCollections,
    getCollectionsForUser,
    getDashboardItems,
    getExperimentalInventory,
    getInventoryHealth,
    getProducts,
    getRecentOMS,
    getSellerSnap,
    getShipLater,
    getShippedItems,
    getSkuAsin,
} from "../../services/WholesaleService";
import {InventoryHealthItem, OMSItem, OrderSummaryByDaysAggData} from "../../types/OmegaTypes";
import {InventoryItem, ReturnData, SellerSnapItem, WholesaleItem} from "../../types/WholesaleItem";
import CollectionsDropdown from "../utilities/CollectionsDropdown";
import ExperimentalTable from "./ExperimentalTable";
import {getGlobalNotes} from "../../services/BrandService";
import {BrandDashboardItem, GlobalNoteItem} from "../../types/Brand";

const parseSheetData = (data: any[]) => {
    const headers = data[0];
    const actualData = data.slice(1).filter((row: string) => row[0]?.length > 0);

    const finalResult = [];
    for (const entry of actualData) {
        const entryObj = Object.fromEntries(headers.map((val: any, idx: number) => [val, entry[idx]]));
        finalResult.push(entryObj);
    }

    return finalResult;
};

const decideReturnRateForRow = (
    wholesaleItem: WholesaleItem,
    returnAsinData: ReturnData,
    returnParentData: ReturnData,
    returnCategoryData: ReturnData
) => {
    if (returnAsinData?.Orders > 50) {
        wholesaleItem.returnRate = returnAsinData.Returns / returnAsinData.Orders;
        wholesaleItem.returnUnsellableRate = returnAsinData.Unsellable / returnAsinData.Orders;
        wholesaleItem.returnConfidenceLevel = "ASIN";
    } else if (returnParentData?.Orders > 50) {
        wholesaleItem.returnRate = returnParentData.Returns / returnParentData.Orders;
        wholesaleItem.returnUnsellableRate = returnParentData.Unsellable / returnParentData.Orders;
        wholesaleItem.returnConfidenceLevel = "Parent";
    } else if (returnCategoryData?.Orders > 50) {
        wholesaleItem.returnRate = returnCategoryData.Returns / returnCategoryData.Orders;
        wholesaleItem.returnUnsellableRate = returnCategoryData.Unsellable / returnCategoryData.Orders;
        wholesaleItem.returnConfidenceLevel = "Category";
    } else {
        wholesaleItem.returnRate = 0.11;
        wholesaleItem.returnUnsellableRate = 0.022;
        wholesaleItem.returnConfidenceLevel = "Basic";
    }
};

const getOrderStatsTimeframe = (timeframe: string) => {
    switch (timeframe) {
        case "true":
            return "d30";
        case "":
            return "d7";
        case "sixty":
            return "d60";
        case "ninety":
            return "d90";
        default:
            return "d30";
    }
};

const WholesaleTab: React.FC<{saved: boolean; name: string}> = ({saved, name}) => {
    const [modal, contextHolder] = Modal.useModal();
    const {currentUser, userData} = useAuth();
    const [collection, setCollection] = useState<string>("");
    const [savedCollection, setSavedCollection] = useState<string>("");
    const [sellersnapTimespan, setSellersnapTimespan] = useState("true");
    const [tableData, setTableData] = useState<WholesaleItem[]>([]);
    const [showAll, setShowAll] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isSavedDataLoading, setIsSavedDataLoading] = useState(false);
    const [omsDataTimespan, setOmsDataTimespan] = useState(90);
    const [invTotalTimespan, setInvTotalTimespan] = useState(0);
    const queryClient = useQueryClient();

    const {data: mainData} = useQuery({
        queryKey: ["wholesale", collection, name],
        queryFn: () => (collection ? getProducts(collection) : []),
        staleTime: Infinity,
    });

    useEffect(() => {
        if (currentUser?.email?.includes("wholesale")) {
            setShowAll(true);
        }
    }, [currentUser]);

    useEffect(() => {
        if (collection) {
            window.history.replaceState(null, `Project Sourcing: ${collection}`, `/wholesale?collection=${encodeURIComponent(collection)}`);
        }
    }, [collection]);

    useEffect(() => {
        const localCollection = new URLSearchParams(window.location.href.split("?")[1]).get("collection");
        console.log("Replacing collection", collection, "with collection", localCollection);
        if (localCollection !== collection && localCollection) {
            setCollection(localCollection);
        }
    }, [collection, queryClient]);

    useEffect(() => {
        if (collection) {
            console.log("Invalidating cache due to collection change:", collection);
            queryClient.invalidateQueries({queryKey: ["wholesale", collection]});

            console.log("Set saved collection to:", collection.split("_")[0] + "_saved");
            const localSavedCollection = collection.split("_")[0] + "_saved";
            setSavedCollection(localSavedCollection);
        }
    }, [collection, queryClient]);

    // invalide saved collection on collection change
    useEffect(() => {
        if (savedCollection) {
            console.log("Invalidating saved collection:", savedCollection);
            queryClient.invalidateQueries({queryKey: ["wholesale", savedCollection]});
        }
    }, [savedCollection, queryClient]);

    const savedDataQuery = useQuery({
        queryKey: ["wholesale", savedCollection, name],
        queryFn: () => (savedCollection ? getProducts(savedCollection) : []),
        staleTime: Infinity,
    });

    const {data: inventoryData} = useQuery({
        queryKey: ["inventory_data", collection],
        queryFn: () =>
            currentUser!.getIdToken().then((token: string) =>
                getExperimentalInventory(
                    token,
                    mainData!.map((prod) => prod.ASIN)
                )
            ),
        staleTime: Infinity,
        enabled: !!(mainData && mainData.length > 0),
    });

    const {data: omsData} = useQuery({
        queryKey: ["recent_oms_data", collection],
        queryFn: () =>
            currentUser!.getIdToken().then((token: string) =>
                getRecentOMS(
                    token,
                    mainData!.map((prod) => prod.ASIN),
                    omsDataTimespan
                )
            ),
        staleTime: Infinity,
        enabled: !!(mainData && mainData.length > 0),
    });

    console.log("enabled??", mainData && mainData.length > 0);

    const {data: shippedItemsData} = useQuery({
        queryKey: ["shippedItems_data"],
        queryFn: () =>
            currentUser!.getIdToken().then((token: string) =>
                getShippedItems(
                    token,
                    omsDataTimespan,
                    mainData!.map((prod) => prod.ASIN)
                )
            ),
        staleTime: Infinity,
        enabled: !!(mainData && mainData.length > 0),
    });

    const {data: shipLaterData} = useQuery({
        queryKey: ["shipLater_data"],
        queryFn: () => currentUser!.getIdToken().then((token: string) => getShipLater(token)),
        staleTime: Infinity,
    });

    const {data: inventoryHealthData} = useQuery({
        queryKey: ["inventoryHealth_data"],
        queryFn: () => currentUser!.getIdToken().then((token: string) => getInventoryHealth(token)),
        staleTime: Infinity,
    });

    const {data: skuAsinData} = useQuery({
        queryKey: ["skuAsin_data"],
        queryFn: () => currentUser!.getIdToken().then((token: string) => getSkuAsin(token)),
        staleTime: Infinity,
    });

    const {data: returnAsinData} = useQuery({
        queryKey: ["returnASIN_data"],
        queryFn: () =>
            currentUser!
                .getIdToken()
                .then((token: string) => getReturnData(token, false))
                .then((data) => parseSheetData(data)),
        staleTime: Infinity,
    });

    const {data: returnCategoryData} = useQuery({
        queryKey: ["returnCategory_data"],
        queryFn: () =>
            currentUser!
                .getIdToken()
                .then((token: string) => getReturnData(token, true))
                .then((data) => parseSheetData(data)),
        staleTime: Infinity,
    });

    const {data: sellerSnapData} = useQuery({
        queryKey: ["sellersnap_data", sellersnapTimespan, collection],
        queryFn: () =>
            getSellerSnap(
                sellersnapTimespan,
                mainData?.map((prod) => prod.ASIN)
            ),
        staleTime: Infinity,
        enabled: !!(mainData && mainData.length > 0),
    });

    const {data: orderStatsData} = useQuery({
        queryKey: ["orderstats", collection],
        queryFn: () =>
            currentUser!.getIdToken().then((token: string) =>
                getOrderSummariesForIDs(
                    token,
                    "asin",
                    mainData!.map((prod) => prod.ASIN),
                    false,
                    true
                )
            ),
        staleTime: Infinity,
        enabled: !!(mainData && mainData.length > 0),
    });

    const {data: noteData} = useQuery({
        queryKey: ["global_note_data", collection],
        queryFn: () =>
            currentUser!.getIdToken().then((token: string) =>
                getGlobalNotes(
                    token,
                    "ASIN",
                    mainData!.map((prod) => prod.ASIN)
                )
            ),
        staleTime: Infinity,
        enabled: !!(mainData && mainData.length > 0),
    });

    const {data: dashboardData} = useQuery({
        queryKey: ["dashboard_data", collection],
        queryFn: () =>
            currentUser!.getIdToken().then((token: string) =>
                getDashboardItems(
                    token,
                    mainData!.map((prod) => prod.ASIN)
                )
            ),
        staleTime: Infinity,
        enabled: !!(mainData && mainData.length > 0),
    });

    useEffect(() => {
        if (mainData && mainData.some((item) => item.name?.toLowerCase().includes("under armour"))) {
            modal.warning({
                centered: true,
                title: "Restriction warning!",
                content: `UA item detected - please be sure to confirm the items' restrictions before buying!`,
            });
        }
    }, [mainData, modal]);

    // Use the saved collection data to overwrite initial item values, if they exist
    // Append inventory levels data
    useEffect(() => {
        console.log("RUNNING EFFECT");
        console.log("mainData", mainData);
        console.log("savedDataQuery", savedDataQuery.data);
        console.log("inventoryData", inventoryData);
        console.log("shippedItemsData", shippedItemsData);
        console.log("ssData", sellerSnapData);
        console.log("shipLaterData", shipLaterData);
        console.log("inventoryHealthData", inventoryHealthData);
        console.log("returnAsinData", returnAsinData);
        console.log("returnCategoryData", returnCategoryData);
        console.log("skuAsinData", skuAsinData);
        console.log("orderStatsData", orderStatsData);
        console.log("noteData", noteData);
        console.log("dashboardData", dashboardData);
        console.log("collection", collection);
        console.log("savedCollection", savedCollection);

        if (
            mainData &&
            savedDataQuery.data &&
            inventoryData &&
            omsData &&
            sellerSnapData &&
            shippedItemsData &&
            shipLaterData &&
            inventoryHealthData &&
            returnAsinData &&
            returnCategoryData &&
            skuAsinData &&
            orderStatsData &&
            noteData &&
            dashboardData &&
            collection?.split("_")?.[0] === savedCollection?.split("_")?.[0]
        ) {
            const newTableData: WholesaleItem[] = mainData.map((prod) => ({...prod, saved: false}));

            for (const prod of newTableData) {
                prod.saved = false;
            }

            // Update the item values
            savedDataQuery.data.forEach((prod) => {
                newTableData
                    .filter(
                        (tableProd) =>
                            tableProd.ASIN === prod.ASIN &&
                            compareSupplierValues(tableProd, prod) &&
                            ((prod.importKey !== undefined &&
                                tableProd.importKey !== undefined &&
                                tableProd.importKey === prod.importKey) ||
                                (prod.importKey === undefined && tableProd.importKey === undefined))
                    )
                    .forEach((row, idx) => {
                        Object.entries(prod).forEach(([property, value]) => {
                            row[property] = value;
                        });
                    });
            });

            const omsMap: {
                [key: string]: (OMSItem & {ShipDate: Dayjs})[];
            } = {};

            const shipLaterMap: {
                [key: string]: number;
            } = {};

            const ssMap: {
                [key: string]: SellerSnapItem[];
            } = {};

            const inventoryMap: {
                [key: string]: (InventoryItem & {ShippedQuantity: number})[];
            } = {};

            const inventoryHealthMap: {
                [key: string]: InventoryHealthItem[];
            } = {};

            const skuAsinMap: {
                [key: string]: string[];
            } = {};

            const returnAsinMap: {
                [key: string]: ReturnData;
            } = {};

            const returnParentMap: {
                [key: string]: ReturnData;
            } = {};

            const returnCategoryMap: {
                [key: string]: ReturnData;
            } = {};

            const orderStatsMap: {
                [key: string]: OrderSummaryByDaysAggData;
            } = {};

            const noteMap: {
                [key: string]: GlobalNoteItem[];
            } = {};

            const dashboardMap: {
                [key: string]: BrandDashboardItem[];
            } = {};

            returnAsinData.forEach((row) => {
                returnAsinMap[row.ASIN] = {
                    Orders: (returnAsinMap[row.ASIN]?.Orders ?? 0) + parseInt(row.Orders),
                    Returns: (returnAsinMap[row.ASIN]?.Returns ?? 0) + parseInt(row.Returns),
                    Unsellable: (returnAsinMap[row.ASIN]?.Unsellable ?? 0) + parseInt(row.Unsellable),
                };

                returnParentMap[row.Parent] = {
                    Orders: (returnParentMap[row.Parent]?.Orders ?? 0) + parseInt(row.Orders),
                    Returns: (returnParentMap[row.Parent]?.Returns ?? 0) + parseInt(row.Returns),
                    Unsellable: (returnParentMap[row.Parent]?.Unsellable ?? 0) + parseInt(row.Unsellable),
                };
            });

            returnCategoryData.forEach((row: any) => {
                returnCategoryMap[row.Category] = {
                    ...row,
                    Orders: parseInt(row.Orders),
                    Returns: parseInt(row.Returns),
                    Unsellable: parseInt(row.Unsellable),
                };
            });

            inventoryHealthData.forEach((item: InventoryHealthItem) => {
                if (inventoryHealthMap[item.asin]) {
                    inventoryHealthMap[item.asin].push(item);
                } else {
                    inventoryHealthMap[item.asin] = [item];
                }
            });

            omsData
                .filter((omsItem: any) => !omsItem.deleted && (omsItem.ASIN || omsItem.UPC) && omsItem.Ship_Requested)
                .forEach((omsItem: any) => {
                    if (omsItem.ASIN) {
                        if (omsMap[omsItem.ASIN]) {
                            omsMap[omsItem.ASIN].push(omsItem);
                        } else {
                            omsMap[omsItem.ASIN] = [omsItem];
                        }
                    } else {
                        if (omsItem.UPC) {
                            if (omsMap[omsItem.UPC]) {
                                omsMap[omsItem.UPC].push(omsItem);
                            } else {
                                omsMap[omsItem.UPC] = [omsItem];
                            }
                        }
                    }
                });

            console.log(omsData, omsMap);

            sellerSnapData.forEach((ssItem) => {
                if (ssMap[ssItem.asin]) {
                    ssMap[ssItem.asin].push(ssItem);
                } else {
                    ssMap[ssItem.asin] = [ssItem];
                }
            });

            skuAsinData.forEach((skuAsinItem) => {
                if (skuAsinMap[skuAsinItem.ASIN]) {
                    skuAsinMap[skuAsinItem.ASIN].push(skuAsinItem.SKU);
                } else {
                    skuAsinMap[skuAsinItem.ASIN] = [skuAsinItem.SKU];
                }
            });

            inventoryData.forEach((invItem: any) => {
                if (inventoryMap[invItem.asin]) {
                    inventoryMap[invItem.asin].push({
                        ...invItem,
                        ShippedQuantity: shippedItemsData[invItem.sku] || 0,
                    });
                } else {
                    inventoryMap[invItem.asin] = [
                        {
                            ...invItem,
                            ShippedQuantity: shippedItemsData[invItem.sku] || 0,
                        },
                    ];
                }
            });

            shipLaterData.forEach((shipLaterItem: any) => {
                shipLaterMap[shipLaterItem.ASIN] = shipLaterItem.Quantity;
            });

            orderStatsData.forEach((orderStatsItem: any) => {
                orderStatsMap[orderStatsItem.asin] = orderStatsItem;
            });

            noteData.forEach((noteItem: GlobalNoteItem) => {
                if (noteMap[noteItem.ASIN]) {
                    noteMap[noteItem.ASIN].push(noteItem);
                } else {
                    noteMap[noteItem.ASIN] = [noteItem];
                }
            });

            dashboardData.forEach((dashboardItem: BrandDashboardItem) => {
                if (dashboardMap[dashboardItem.ASIN]) {
                    dashboardMap[dashboardItem.ASIN].push(dashboardItem);
                } else {
                    dashboardMap[dashboardItem.ASIN] = [dashboardItem];
                }
            });

            newTableData.forEach((wholesaleItem) => {
                const omsItems = omsMap[wholesaleItem.ASIN] || [];
                const ssItems = ssMap[wholesaleItem.ASIN]?.filter((item) => item.days_in_stock > 0) || [];
                const invItems = inventoryMap[wholesaleItem.ASIN] || [];
                const invHealthItems = inventoryHealthMap[wholesaleItem.ASIN] || [];

                decideReturnRateForRow(
                    wholesaleItem,
                    returnAsinMap[wholesaleItem.ASIN],
                    returnParentMap[wholesaleItem.parentASIN],
                    returnCategoryMap[wholesaleItem.returnCategory]
                );

                wholesaleItem.ReturnASINData = returnAsinMap[wholesaleItem.ASIN];
                wholesaleItem.ReturnParentData = returnParentMap[wholesaleItem.parentASIN];
                wholesaleItem.ReturnCategoryData = returnCategoryMap[wholesaleItem.returnCategory];

                omsItems.sort((a, b) => dayjs(b.ShipDate).toDate().getTime() - dayjs(a.ShipDate).toDate().getTime());

                wholesaleItem.InventoryUnder90D = invHealthItems.reduce((acc, curr) => (acc += parseInt(curr["inv-age-0-to-90-days"])), 0);
                wholesaleItem.InventoryOver90D = invHealthItems.reduce(
                    (acc, curr) =>
                        (acc +=
                            parseInt(curr["inv-age-365-plus-days"]) +
                            parseInt(curr["inv-age-91-to-180-days"]) +
                            parseInt(curr["inv-age-181-to-270-days"]) +
                            parseInt(curr["inv-age-271-to-365-days"])),
                    0
                );

                wholesaleItem.ExistsInOMS = omsItems.length > 0;
                wholesaleItem.OMSItems = omsItems;

                console.log("invTotalTimespan", invTotalTimespan);
                wholesaleItem.IncomingQuantity = omsItems
                    .filter(
                        (item: OMSItem) =>
                            (dayjs().diff(dayjs(item.ShipDate), "day") <= 0 &&
                                (invTotalTimespan > 0 ? dayjs().diff(dayjs(item.ShipDate), "day") >= -invTotalTimespan : true)) ||
                            (item.ShipDateType === "SR" && dayjs().diff(dayjs(item.ShipDate), "day") >= 0)
                    )
                    .reduce((acc: number, curr: OMSItem) => (acc += curr.Quantity || 0), 0);

                wholesaleItem.IncomingQuantity30D = omsItems
                    .filter(
                        (item: OMSItem) =>
                            (dayjs().diff(dayjs(item.ShipDate), "day") <= 0 && dayjs().diff(dayjs(item.ShipDate), "day") >= -30) ||
                            (item.ShipDateType === "SR" && dayjs().diff(dayjs(item.ShipDate), "day") >= 0)
                    )
                    .reduce((acc: number, curr: OMSItem) => (acc += curr.Quantity || 0), 0);

                wholesaleItem.IncomingQuantity90D = omsItems
                    .filter(
                        (item: OMSItem) =>
                            (dayjs().diff(dayjs(item.ShipDate), "day") <= 0 && dayjs().diff(dayjs(item.ShipDate), "day") >= -90) ||
                            (item.ShipDateType === "SR" && dayjs().diff(dayjs(item.ShipDate), "day") >= 0)
                    )
                    .reduce((acc: number, curr: OMSItem) => (acc += curr.Quantity || 0), 0);

                wholesaleItem.ShipLater = shipLaterMap[wholesaleItem.ASIN] ?? 0;
                wholesaleItem.OMSQuantity = omsItems.reduce((acc, omsItem) => acc + (omsItem.Quantity ?? 0), 0);
                wholesaleItem.POs = omsItems
                    .map(
                        (item) =>
                            `${item.Supplier_SO || item.Supplier_PO} - ${item.ShipDate} - ${item.Quantity} units ${
                                item.ShipDateType === "LSD" ? " - LSD" : ""
                            }${item.IsPaid ? " - Paid" : ""}`
                    )
                    .filter((x, i, a) => a.indexOf(x) === i);

                wholesaleItem.OrdersOur7D = orderStatsMap[wholesaleItem.ASIN]?.["d7"].units ?? -1;
                wholesaleItem.OrdersOur14D = orderStatsMap[wholesaleItem.ASIN]?.["d14"].units ?? -1;
                wholesaleItem.OrdersOur30D = orderStatsMap[wholesaleItem.ASIN]?.["d30"].units ?? -1;
                wholesaleItem.OrdersOur60D = orderStatsMap[wholesaleItem.ASIN]?.["d60"].units ?? -1;
                wholesaleItem.OrdersOur90D = orderStatsMap[wholesaleItem.ASIN]?.["d90"].units ?? -1;
                wholesaleItem.OrdersOur180D = orderStatsMap[wholesaleItem.ASIN]?.["d180"]?.units ?? -1;
                wholesaleItem.OrdersOur365D = orderStatsMap[wholesaleItem.ASIN]?.["d365"]?.units ?? -1;

                wholesaleItem.BBShare = Math.round(
                    ssItems.reduce((acc, ssItem, _, arr) => acc + parseFloat(ssItem.avg_buybox_share ?? 0) / arr.length, 0)
                );

                const orderTimeframe = getOrderStatsTimeframe(sellersnapTimespan);

                wholesaleItem.OrdersAll = orderStatsMap[wholesaleItem.ASIN]?.[orderTimeframe].units ?? -1;
                wholesaleItem.Orders30D = orderStatsMap[wholesaleItem.ASIN]?.["d30"].units ?? -1;
                wholesaleItem.Orders7D = orderStatsMap[wholesaleItem.ASIN]?.["d7"].units ?? -1;

                wholesaleItem.AvgSellPrice =
                    orderStatsMap[wholesaleItem.ASIN]?.[orderTimeframe].units > 0
                        ? orderStatsMap[wholesaleItem.ASIN]?.[orderTimeframe].value /
                          orderStatsMap[wholesaleItem.ASIN]?.[orderTimeframe].units
                        : 0;

                wholesaleItem.DIS = Math.max(
                    ...ssItems.filter((item) => dayjs().diff(dayjs(item.extractTime), "day") <= 3).map((ssItem) => ssItem.days_in_stock),
                    0
                );

                wholesaleItem.Velocity = wholesaleItem.DIS > 0 ? parseFloat((wholesaleItem.OrdersAll / wholesaleItem.DIS).toFixed(2)) : 0;
                wholesaleItem.SellerSnapMaxPrice = ssItems
                    .filter((ssItem) => ssItem.max_price)
                    .reduce((acc, ssItem, _, arr) => acc + parseFloat(ssItem.max_price ?? 0) / arr.length, 0);
                wholesaleItem.SellerSnapMinPrice = ssItems
                    .filter((ssItem) => ssItem.min_price)
                    .reduce((acc, ssItem, _, arr) => acc + parseFloat(ssItem.min_price ?? 0) / arr.length, 0);
                wholesaleItem.Tags = ssItems.reduce((acc: string[], curr) => acc.concat(curr.tags || []), []);
                wholesaleItem.AdsRunning = ssItems.reduce((acc: boolean, curr) => (acc ? acc : curr.advertising_impressions > 0), false);
                wholesaleItem.ssSkus = ssItems.length;
                wholesaleItem.ssItems = ssItems;

                wholesaleItem.InvQuantity = invItems.reduce(
                    (acc, invItem) =>
                        acc +
                        (parseInt(invItem["afn-total-quantity"] || "0") - parseInt(invItem["afn-unsellable-quantity"] || "0")) +
                        parseInt(invItem["mfn-fulfillable-quantity"] || "0"),
                    0
                );
                wholesaleItem.InvAvailableQuantity = wholesaleItem.InvQuantity;
                wholesaleItem.FulfillableQuantity = invItems.reduce(
                    (acc, invItem) =>
                        acc + parseInt(invItem["afn-fulfillable-quantity"] || "0") + parseInt(invItem["mfn-fulfillable-quantity"] || "0"),
                    0
                );

                const getShippedQty = (asin: string) => {
                    const skus = skuAsinMap[asin] || [];
                    let shippedQty = 0;
                    skus.forEach((sku) => {
                        shippedQty += shippedItemsData[sku] || 0;
                    });
                    return shippedQty;
                };

                wholesaleItem.ShippedQuantity = getShippedQty(wholesaleItem.ASIN);
                wholesaleItem.Discrepancy =
                    (wholesaleItem.OMSQuantity ?? 0) -
                    (wholesaleItem.ShippedQuantity ?? 0) -
                    wholesaleItem.IncomingQuantity -
                    wholesaleItem.ShipLater;
                wholesaleItem.InventoryTotal = wholesaleItem.InvQuantity + wholesaleItem.ShipLater + wholesaleItem.IncomingQuantity;
                wholesaleItem.trackedFor = dayjs().diff(dayjs(wholesaleItem["Tracking since"]), "day");

                wholesaleItem.Notes = (noteMap[wholesaleItem.ASIN] || []).sort((a, b) =>
                    dayjs(b.updateTimestamp).diff(dayjs(a.updateTimestamp))
                );

                wholesaleItem.opportunities = Array.from(
                    new Set(
                        (dashboardMap[wholesaleItem.ASIN] || [])
                            .sort((a, b) => dayjs(b.updateTimestamp).diff(dayjs(a.updateTimestamp)))
                            .map((item) => item.opportunities)
                            .flat()
                    )
                ).join(";");
            });

            if (saved) {
                setTableData(newTableData.filter((item) => item.saved));
            } else {
                setTableData(newTableData);
            }

            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [
        collection,
        savedCollection,
        mainData,
        savedDataQuery.data,
        isSavedDataLoading,
        omsData,
        sellerSnapData,
        inventoryData,
        shippedItemsData,
        shipLaterData,
        inventoryHealthData,
        returnAsinData,
        returnCategoryData,
        skuAsinData,
        orderStatsData,
        noteData,
        dashboardData,
        sellersnapTimespan,
        invTotalTimespan,
        saved,
    ]);

    // Handle deleting the collection
    const handleDelete = (collection: string) => {
        currentUser!
            .getIdToken()
            .then((token: string) => deleteCollection(collection, token))
            .then((res) => {
                if (res.error === null) {
                    queryClient.invalidateQueries({queryKey: ["collections"]});
                    queryClient.invalidateQueries({queryKey: ["wholesale", collection, name]});
                    setTableData([]);
                    modal.success({
                        content: `Successfully deleted collection ${collection}!`,
                    });
                } else {
                    modal.error({
                        title: `${res.msg}`,
                        content: `${res.error}`,
                    });
                }
            });
    };

    useEffect(() => {
        queryClient.invalidateQueries({queryKey: ["collections"]});
    }, [showAll, queryClient]);

    // console.log('[SAVE] Re-rendering the WholesaleTab view with savedDataLoading', savedDataLoading);
    console.log(
        "Re-rendering the WholesaleTab view",
        collection,
        saved,
        savedDataQuery.data,
        mainData,
        savedDataQuery.isLoading,
        tableData
    );

    useEffect(() => {
        if (savedDataQuery.isLoading !== isSavedDataLoading) {
            console.log("Updating isSavedDataLoading due to differences, setting to", savedDataQuery.isLoading, "from", isSavedDataLoading);
            setIsSavedDataLoading(savedDataQuery.isLoading);
        }
    }, [savedDataQuery, isSavedDataLoading]);

    useEffect(() => {
        console.log("Invalidating queries due to omsDataTimespan change:", omsDataTimespan);
        setLoading(true);
        // invalidate oms queries
        queryClient.setQueryData(["recent_oms_data", collection], null);
        queryClient.setQueryData(["shippedItems_data"], null);
        // invalide shipping queries

        queryClient.invalidateQueries({queryKey: ["recent_oms_data", collection]});
        queryClient.invalidateQueries({queryKey: ["shippedItems_data"]});
    }, [omsDataTimespan]);

    useEffect(() => {
        if (userData?.role === "ADMIN") {
            // do nothing
        } else {
            const onBeforeUnload = (e: any) => {
                e.preventDefault();
                e.returnValue = "";
            };

            window.addEventListener("beforeunload", onBeforeUnload);

            return () => {
                window.removeEventListener("beforeunload", onBeforeUnload);
            };
        }
    }, [userData?.role]);

    return (
        <Spin spinning={collection?.length > 0 ? isLoading : false}>
            <>
                {contextHolder}
                <div style={{display: "flex"}}>
                    <CollectionsDropdown
                        value={collection}
                        queryKey={`${name}_${showAll ? "showAll" : "showUser"}`}
                        onChange={(x) => {
                            console.log("Setting new collection from dropdown:", x);
                            setCollection(x);
                        }}
                        getCollections={showAll ? () => getCollections(saved) : () => getCollectionsForUser(saved, currentUser!)}
                    />
                    <Button type="primary" onClick={() => setShowAll((show) => !show)}>
                        {showAll ? "Show yours" : "Show all"}
                    </Button>
                    {userData?.role === "ADMIN" ? (
                        <Space style={{marginLeft: "8px"}}>
                            <Popconfirm
                                icon={<QuestionCircleOutlined style={{color: "red"}} />}
                                title="Are you sure you want to delete this collection?"
                                onConfirm={() => handleDelete(collection)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button type="primary" danger>
                                    Delete
                                </Button>
                            </Popconfirm>
                        </Space>
                    ) : null}
                </div>
                <Divider />
                {tableData.length > 0 && (
                    <ExperimentalTable
                        sellersnapTimespan={sellersnapTimespan}
                        key={collection}
                        save={saved}
                        isLoading={isLoading}
                        data={tableData}
                        setSellersnapTimespan={setSellersnapTimespan}
                        setOmsTimespan={setOmsDataTimespan}
                        setInvTotalTimespan={setInvTotalTimespan}
                        collection={collection}
                    />
                )}
            </>
        </Spin>
    );
};

export default WholesaleTab;
