import { SellerSnapItem } from "../../types/WholesaleItem"

export function getShippingFees(prod: SellerSnapItem): number {
    if (prod.pkg_weight) {
        const shippingFeeMulti = 0.5
        const shippingFeeBase = 1
        const shippingWeight = parseFloat(prod.pkg_weight)
        return shippingWeight * shippingFeeMulti + shippingFeeBase
    } else {
        return 5;
    }
}

// const AVG_CLICK_COST = 0.3675;
// const seasonMap: { [key: string]: number[] } = {
//     'EG': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
//     'Cool': [1, 2, 3, 4, 9, 10, 11, 12],
//     'Winter': [11, 12, 1, 2],
//     'Summer': [5, 6, 7, 8]
// }

// const GMROI = [
//     {
//         'ROI': 25,
//         'Days': 30,
//     },
//     {
//         'ROI': 50,
//         'Days': 61,
//     },
//     {
//         'ROI': 250,
//         'Days': 180,
//     },
// ]

export const OMEGA_POSSIBLE_ISSUES = [
    'Not Competitive',
    'Close to Not Competitive',
    'Possibly Orphaned',
    'Poor Conversion',
    'Low Sessions',
    'Low BB Share',
    'No Sales',
    'No Sales (7D)',
    'BB Coupon',
    'Ship Later needed',
    'Suppressed',
    'Stuck Near Min',
    'MinVSP',
    'Search Suppressed',
    // 'MAP Broken',
    'MAP Violation',
    'MultiSKU',
    'NoActive',
    'MCW',
    'LS',
]

// function FindBuyQTY(item: SingularItem) {
//     let DaysToBuy: number = 0;

//     if ((item.ROI * 100) >= 25 && item.Profit > 2.5) {
//         const lowerBoundary = GMROI.find(pred => pred.ROI > (item.ROI * 100))
//         const higherBoundary = [...GMROI].reverse().find(pred => pred.ROI <= (item.ROI * 100))

//         if (lowerBoundary && higherBoundary) {
//             DaysToBuy = lowerBoundary.Days + ((item.ROI * 100) - lowerBoundary.ROI) * ((higherBoundary.Days - lowerBoundary.Days) / (higherBoundary.ROI - lowerBoundary.ROI));
//         } else {
//             DaysToBuy = lowerBoundary?.Days ?? higherBoundary!.Days;
//         }
//     } else {
//         DaysToBuy = -1;
//     }

//     const EstSales: number = item['Sold (30D)'] / item['Days In Stock']

//     if (DaysToBuy > 0 && EstSales > 0) {
//         if (item.Stock && item.Stock > 0) {
//             return Math.max(Math.round(EstSales * DaysToBuy - item.Stock / EstSales), 0);
//         } else {
//             return Math.max(Math.round(EstSales * DaysToBuy), 0);
//         }
//     } else {
//         return 0;
//     }
// }

// function FindFutureProfit(item: SingularItem) {
//     const getProfit = (item: SingularItem, sellPrice: number) => sellPrice - item.Cost - (parseFloat(item.ssItem.fees_structure?.regular_fee_perc || '15') / 100) * sellPrice - item.shippingFee - item.fulfillmentFee;

//     let stock = item.Stock;
//     let estSales = item["EstSales (30D)"]
//     let sellPrice = item["Avg Sell Price"] > 0 ? item["Avg Sell Price"] : item["Listed Price"]
//     let profit = getProfit(item, sellPrice);

//     let totalProfit = 0;
//     let timePassed = 0;
//     while (stock > 0 && timePassed < 365) {
//         if (timePassed >= 60) {
//             sellPrice *= 0.95
//             profit = getProfit(item, sellPrice);
//         }
//         const soldThisMonth = Math.min(stock, estSales);
//         totalProfit += soldThisMonth * profit;
//         stock -= soldThisMonth;
//         timePassed += 30;
//     }

//     return totalProfit;
// }

export function RevFeeFromSellerSnap(price: number, ssItem: SellerSnapItem) {
    if (ssItem.fees_structure) {
        const refFeePercentage = parseFloat(ssItem.fees_structure.regular_fee_perc) / 100

        if (ssItem.fees_structure.reduced_fee_perc) {
            const reducedFee = ssItem.fees_structure.reduced_fee_perc / 100;
            if (ssItem.fees_structure.reduced_fee_type === 'full amount') {
                if (price <= ssItem.fees_structure.reduced_fee_threshold) {
                    return Math.max(reducedFee * price, ssItem.fees_structure.min_fee);
                }
            } else if (ssItem.fees_structure.reduced_fee_type === 'portion above threshold') {
                return Math.min(ssItem.fees_structure.reduced_fee_threshold, price) * refFeePercentage + reducedFee * Math.max(price - ssItem.fees_structure.reduced_fee_threshold, 0);
            } else {
                // something is broken, lemao!!!
            }
        }

        // @ts-ignore because pydantic 
        if (ssItem.fees_structure.regular_fee_perc === "17.00" || ssItem.fees_structure.regular_fee_perc === 17) {
            if (price <= 15) {
                return Math.max(0.05 * price, ssItem.fees_structure.min_fee);
            } else if (price <= 20) {
                return Math.max(0.10 * price, ssItem.fees_structure.min_fee);
            } else {
                return Math.max(0.17 * price, ssItem.fees_structure.min_fee);
            }
        }

        return Math.max(refFeePercentage * price, ssItem.fees_structure.min_fee);
    } else {
        return price * 0.15
    }
}

export function approxMVSP(totalCost: number, ssItem: SellerSnapItem) {
    let currTotal = totalCost
    let refFee = RevFeeFromSellerSnap(currTotal, ssItem)
    for (let i = 0; i < 7; i++) {
        refFee = RevFeeFromSellerSnap(currTotal + refFee, ssItem)
    }

    return currTotal + refFee;
}

// function FindIssues(item: SingularItem) {
//     const issues = [];

//     const min_price = parseFloat(item.ssItem.min_price)
//     const curr_bb = parseFloat(item.ssItem.cur_buybox_price ?? 0)

//     if (item.Stock > 0) {
//         if (item.ssItem.cur_buybox_price && min_price > curr_bb) {
//             issues.push('Not Competitive');
//         } else if (item.ssItem.cur_buybox_price && min_price > (0.975 * curr_bb)) {
//             issues.push('Close to Not Competitive');
//         }
//     }

//     if (item.salesTrafficItems.length > 1) {
//         issues.push('Possibly Orphaned');
//     }

//     if (item['OurSessions-Sales'] > 15 || (item['Sold (30D)'] === 0 && item['OurSessions'] > 15)) {
//         issues.push('Poor Conversion');
//     }

//     if ((item['Stock'] / 3) * (15 / item['Sessions']) > 1) {
//         issues.push('Low Sessions');
//     }

//     if (item['BBShare'] <= 25) {
//         issues.push('Low BB Share');
//     }

//     if (item['Sold (30D)'] === 0) {
//         issues.push('No Sales');
//     }

//     if (item.ssItem.velocity_7 === null || item.ssItem.velocity_7 === '0.00') {
//         issues.push('No Sales (7D)');
//     }

//     if (item.ssItem.suppressed_buybox === true) {
//         issues.push('Suppressed');
//     }

//     if (item.InventoryOver90D > 0) {
//         issues.push('Old');
//     }

//     if (item.MVSP < item["Min Price"]) {
//         issues.push('MinVSP');
//     }

//     if (
//         item.ssItem.cur_buybox_price
//         && item['BBShare'] <= 5
//         && parseFloat(item.ssItem.cur_buybox_price) >= item["Min Price"]
//         && item.availableInvValue > 200
//         && !item.Tags.map((tag) => tag.toLowerCase()).find(((tag) => tag === 'hold' || tag === 'map'))
//         && item.ssItem.repricing
//     ) {
//         issues.push('Stuck Near Min')
//     }

//     if (
//         item.ssItem.cur_buybox_price
//         && !item.Tags.map((tag) => tag.toLowerCase()).includes('supp')
//         && item.availableInvValue > 200
//         && parseFloat(item.ssItem.cur_buybox_price) < item["Min Price"]
//         && item.ssItem.repricing
//     ) {
//         issues.push('MCW')
//     }

//     if (
//         item.activeSellers === 0
//         && item.inactiveSellersCount >= 1
//         && item.availableInventory > 0
//         && item["Days In Stock"] > 3
//         && item["CurrBB Price"] <= item["Max Price"]
//     ) {
//         issues.push('NoActive');
//     }

//     const excludeTagsFromLS = ['Possibly Orphaned', 'Not Competitive', 'Low BB Share', 'MCW']
//     if (
//         !issues.some(issue => excludeTagsFromLS.includes(issue))
//         && item.availableInventory > 1
//         && item["Days In Stock"] > 14
//         && item["EstTTS"] > 75
//     ) {
//         issues.push('LS')
//     }

//     return issues
// }

// export function AnalyzeSingularItem(
//     ssItem: SellerSnapItem,
//     omsItems: OMSItem[],
//     salesTrafficItems: SalesTrafficItem[],
//     inventoryHealthItem: InventoryHealthItem,
//     belowMinData: BelowMinData | undefined,
//     activeSellersData: ActiveSellersData | undefined,
//     shippedCount: number,
//     shipLaterCount: number,
//     feeData: FeeData | undefined,
//     backordered: boolean,
//     brand: string,
//     key: React.Key,
// ) {
//     const newProd: SingularItem = {
//         'key': key,
//         'SKU': '',
//         'ASIN': '',
//         'Parent': '',
//         'Title': '',
//         'Brand': '',
//         'BBShare': -1,
//         'Cost': -1,
//         'Listed Price': -1,
//         'Min Price': -1,
//         'Max Price': -1,
//         'CurrBB Price': -1,
//         'Avg Sell Price': -1,
//         'Profit': -1,
//         'MVSP': -1,
//         'ROI': -1,
//         'Days In Stock': -1,
//         'Stock': -1,
//         'Sold (30D)': -1,
//         'Velocity (30D)': -1,
//         'EstSales (30D)': -1,
//         'Sold (7D)': -1,
//         'Velocity (7D)': -1,
//         'EstSales (7D)': -1,
//         'EstTTS': -1,
//         'GMROI': -1,
//         'Recommended Buy': -1,
//         'Exists in OMS': '',
//         'Tags': [],
//         'Sessions': -1,
//         'OurSessions': -1,
//         'OurSessions-Sales': -1,
//         'Cost / Sale': -1,
//         'CR': -1,
//         'Out of Season?': false,
//         'Issues': [],
//         'ssItem': ssItem,
//         'omsItems': omsItems,
//         'salesTrafficItems': salesTrafficItems,
//         'Ship Later': shipLaterCount,
//         'ShipLaterTTS': -1,
//         'ShipLaterVal': -1,
//         'InventoryUnder90D': parseInt(inventoryHealthItem["inv-age-0-to-90-days"] ?? 0),
//         'InventoryOver90D': (
//             parseInt(inventoryHealthItem["inv-age-365-plus-days"] ?? 0)
//             + parseInt(inventoryHealthItem["inv-age-91-to-180-days"] ?? 0)
//             + parseInt(inventoryHealthItem["inv-age-181-to-270-days"] ?? 0)
//             + parseInt(inventoryHealthItem["inv-age-271-to-365-days"] ?? 0)
//         ),
//         'InventoryOver180D': (
//             parseInt(inventoryHealthItem["inv-age-365-plus-days"] ?? 0)
//             + parseInt(inventoryHealthItem["inv-age-181-to-270-days"] ?? 0)
//             + parseInt(inventoryHealthItem["inv-age-271-to-365-days"] ?? 0)
//         ),
//         'InventoryOver270D': (
//             parseInt(inventoryHealthItem["inv-age-365-plus-days"] ?? 0)
//             + parseInt(inventoryHealthItem["inv-age-271-to-365-days"] ?? 0)
//         ),
//         skuItemCount: 1,
//         skuItemList: [ssItem],
//         shippedCount: shippedCount ?? 0,
//         fulfillmentFee : 0,
//         shippingFee: 0,
//         refFee: 0,
//         invValue: 0,
//         availableInventory: 0,
//         unavailableInventory: 0,
//         availableInvValue: 0,
//         unavailableInvValue: 0,
//         futureProfit: 0,
//         hoursBelowMin: belowMinData?.timeBelowMin ? Math.round(belowMinData?.timeBelowMin / 3600) : 0,
//         inactiveSellers: activeSellersData?.inactiveSellers ?? -1,
//         inactiveSellersCount: activeSellersData?.inactiveSellers.length ?? -1,
//         activeSellers: activeSellersData?.activeSellersCount ?? -1,
//         fbaSellers: activeSellersData?.FBACount ?? -1,
//         fbmSellers: activeSellersData?.FBMCount ?? -1,
//         notes: [],
//         repricing: ssItem.repricing,
//         invHealthItem: inventoryHealthItem,
//         inboundFee: feeData?.InboundFees ?? '-1',
//         freightCost: feeData?.ItemizedFreightCost ?? feeData?.NormalFreightCost ?? '-1',
//         storageCost: feeData?.StorageFee ?? '-1',
//         isBackordered: backordered ?? false,
//     }

//     const shippingFee = getShippingFees(ssItem);
//     const refFee = RevFeeFromSellerSnap(parseFloat(ssItem.listed_price), ssItem);
//     const fulfillmentFee = (parseFloat(ssItem.fulfillment_channel === 'FBA' ? (ssItem.fulfillment_cost || '5') : '0'))

//     newProd.inboundFee = parseFloat(newProd.inboundFee as string)
//     newProd.freightCost = parseFloat(newProd.freightCost as string)
//     newProd.storageCost = parseFloat(newProd.storageCost as string)
//     newProd.invValue = ssItem.fulfillment_channel === 'FBA' ? ssItem.fba_total_quantity * parseFloat(ssItem.cost) : parseFloat(ssItem.cost) * ssItem.fulfillable_quantity
//     newProd.availableInventory = ssItem.fulfillable_quantity
//     newProd.unavailableInventory = ssItem.fulfillment_channel === 'FBA' ? Math.max(ssItem.fba_total_quantity - ssItem.fulfillable_quantity, 0) : 0
//     newProd.availableInvValue = newProd.availableInventory * parseFloat(ssItem.cost)
//     newProd.unavailableInvValue = newProd.unavailableInventory * parseFloat(ssItem.cost)

//     newProd.shippingFee = shippingFee;
//     newProd.refFee = refFee;
//     newProd.fulfillmentFee = fulfillmentFee;
//     // SELLERSNAP REPLEN ANALYSIS APPENDS
//     newProd['SKU'] = ssItem.sku;
//     newProd['ASIN'] = ssItem.asin;
//     newProd['Parent'] = salesTrafficItems.length > 1 ? 'MULTIPLE' : (salesTrafficItems[0]?.parentAsin || 'Unknown') ;
//     newProd['Title'] = ssItem.title;
//     newProd['Brand'] = brand;
//     newProd['Tags'] = ssItem.tags;
//     newProd['BBShare'] = ssItem.repricing ? parseFloat(ssItem.avg_buybox_share || '0') : salesTrafficItems[0]?.trafficByAsin.buyBoxPercentage ?? 0;
//     newProd['Cost'] = parseFloat(ssItem.cost);
//     newProd['Listed Price'] = parseFloat(ssItem.listed_price);
//     newProd['Min Price'] = parseFloat(ssItem.min_price);
//     newProd['Max Price'] = parseFloat(ssItem.max_price);
//     newProd['CurrBB Price'] = parseFloat(ssItem.cur_buybox_price || '-1');
//     newProd['Avg Sell Price'] = parseFloat(ssItem.avg_selling_price || ssItem.cur_buybox_price || -1);
//     newProd['Profit'] = (newProd['Avg Sell Price'] > 0 ? newProd['Avg Sell Price'] : newProd['Listed Price']) - newProd['Cost'] - refFee - shippingFee - fulfillmentFee;
//     newProd['MVSP'] = approxMVSP(1.25 * newProd['Cost'] + shippingFee + fulfillmentFee, ssItem)
//     newProd['ROI'] = newProd['Profit'] / newProd['Cost'] * 100;
//     newProd['Days In Stock'] = ssItem.days_in_stock;
//     newProd['Stock'] = ssItem.fba_available_quantity ?? (ssItem.fulfillable_quantity ?? -1);
//     newProd['Sold (30D)'] = ssItem.total_order_items;
//     newProd['Velocity (30D)'] = ssItem.velocity ?? -1;
//     newProd['EstSales (30D)'] =
//         newProd['BBShare'] > 0 && newProd['Days In Stock'] >= 1 ?
//             parseFloat((ssItem.total_order_items * (100 / (newProd['BBShare'])) * (30 / newProd['Days In Stock'])).toFixed(2))
//             :
//             -1

//     newProd['Sold (7D)'] = inventoryHealthItem ? parseInt(inventoryHealthItem["units-shipped-t7"]) : -1;
//     newProd['Velocity (7D)'] = newProd['Sold (7D)'] >= 0 && newProd['Days In Stock'] >= 1 ? newProd['Sold (7D)'] / (Math.min(newProd['Days In Stock'], 7)) : 0;
//     newProd['EstSales (7D)'] =
//         newProd['BBShare'] > 0 && newProd['Days In Stock'] >= 1 && newProd['Sold (7D)'] >= 0 ?
//             parseFloat((newProd['Sold (7D)'] * (100 / (newProd['BBShare'])) * (7 / Math.min(newProd['Days In Stock'], 7))).toFixed(2))
//             :
//             -1

//     newProd['EstTTS'] =
//         ssItem.total_order_items > 0 ?
//             newProd['Stock'] > 0 ? parseFloat(((newProd['Stock'] / ssItem.total_order_items) * (ssItem.days_in_stock)).toFixed(2)) : 0
//             :
//             ssItem.days_in_stock > 7 ?
//                 365
//                 :
//                 -1
//             ;

//     newProd['ShipLaterVal'] = newProd["Ship Later"] * newProd.Cost;
//     newProd['ShipLaterTTS'] =
//         ssItem.total_order_items > 0 ?
//             newProd['Ship Later'] > 0 ? parseFloat(((newProd['Ship Later'] / ssItem.total_order_items) * (ssItem.days_in_stock)).toFixed(2)) : 0
//             :
//             365;

//     newProd['GMROI'] =
//         newProd['EstTTS'] > 0 ?
//             parseFloat(((newProd['Profit'] / (newProd['Cost'] + shippingFee)) *
//             (365 / (newProd['EstTTS'] + newProd['Days In Stock']))).toFixed(2))
//             :
//             -1;

//     newProd['Recommended Buy'] =
//         ssItem.total_order_items > 0 ?
//             FindBuyQTY(newProd)
//             :
//             0;

//     newProd['Exists in OMS'] = omsItems?.map(omsProd => omsProd.Supplier_SO || omsProd.Supplier_PO).join(', ') || ''


//     // INV MNGMT ANALYSIS APPENDS
//     const orderItems = salesTrafficItems.reduce((acc, curr) => acc + curr.salesByAsin.totalOrderItems, 0)
//     newProd['Sessions'] = salesTrafficItems.reduce((acc, curr) => acc + curr.trafficByAsin.sessions, 0)
//     newProd['OurSessions'] = parseFloat((newProd['Sessions'] * (newProd.BBShare / 100)).toFixed(0))
//     newProd['OurSessions-Sales'] = orderItems > 0 ? parseFloat((newProd['OurSessions'] / orderItems).toFixed(2)) : -1
//     newProd['Cost / Sale'] = newProd['OurSessions-Sales'] > 0 ? parseFloat((newProd['OurSessions-Sales'] * AVG_CLICK_COST).toFixed(2)) : -1
//     newProd['CR'] = newProd['OurSessions-Sales'] > 0 ? parseFloat(((1 / newProd['OurSessions-Sales']) * 100).toFixed(2)) : 0

//     // Check seasonality
//     for (const tag of newProd.Tags) {
//         if (seasonMap[tag] && !seasonMap[tag].includes(new Date().getMonth() + 1)) {
//             newProd['Out of Season?'] = true;
//         }
//     }

//     newProd['Issues'] = FindIssues(newProd);

//     newProd.futureProfit = newProd["EstSales (30D)"] > 0 ? FindFutureProfit(newProd) : 0;

//     return newProd;
// }