import {ExclamationCircleOutlined, UploadOutlined} from "@ant-design/icons";
import {Form, Button, Input, Upload, Modal, Spin, Space, message, Select, DatePicker, Row, Col} from "antd";
import {RcFile} from "antd/lib/upload";
import {UploadFile} from "antd/lib/upload/interface";
import React from "react";
import {useQueryClient} from "@tanstack/react-query";
import {useAuth} from "../../contexts/AuthContext";
import {importProductsForUser} from "../../services/WholesaleService";
import * as dataForge from "data-forge";
import ColumnSelect from "./ColumnSelect";
import {useSuppliers} from "../omega/oms/dataHandlers";
import dayjs from "dayjs";
import {SendToSlack} from "../../services/OmegaService";
import {SLACK_CHANNEL_IDS} from "../utilities/common";

const layout = {
    labelCol: {span: 8},
    wrapperCol: {span: 8},
};

const tailLayout = {
    wrapperCol: {offset: 8, span: 16},
};

interface fileMap {
    wholesale: string[];
    keepa: string[];
}

interface columnMapping {
    upc: string;
    oh: string;
    cost?: string;
    map?: string;
}

const WholesaleImport: React.FC = () => {
    const [modal, contextHolder] = Modal.useModal();
    const {currentUser} = useAuth();
    const [form] = Form.useForm();
    const [fileList, setFileList] = React.useState<RcFile[]>([]);
    const [uploading, setUploading] = React.useState(false);
    const [fileMap, setFileMap] = React.useState<fileMap>({wholesale: [], keepa: []});
    const [wholesaleColumns, setWholesaleColumns] = React.useState<string[]>([]);
    const [columnMapping, setColumnMapping] = React.useState<columnMapping>({
        upc: "UPC",
        oh: "OH",
        cost: "Cost",
        map: "MAP",
    });
    const queryClient = useQueryClient();
    const {data: suppliers} = useSuppliers();

    // Prepare the columns for Wholesale Column pickers
    const reader = new FileReader();
    reader.onload = async (e) => {
        const text = e.target!.result as string;
        const fixedText =
            text.match(/"([^",]*)(\n)([^",]*)"/g)?.reduce((acc, header) => acc.replace(header, header.replace("\n", " ")), text) || text;
        setWholesaleColumns(fixedText.split("\n")[0].replace(/\r|"/g, "").split(","));
        setUploading(false);
    };

    // Handle the file list before uploading
    const beforeUpload = (file: RcFile, key: "wholesale" | "keepa") => {
        if (key === "wholesale") {
            setUploading(true);
            reader.readAsText(file);
        }

        setFileMap({...fileMap, [key]: fileMap[key].concat([file.name])});
        const newFileList = [...fileList, file];
        setFileList(newFileList);
        return false;
    };

    const onRemove = (file: UploadFile, key: "wholesale" | "keepa") => {
        if (key === "wholesale") {
            setWholesaleColumns([]);
        }

        setFileMap({...fileMap, [key]: fileMap[key].filter((existingFile) => existingFile !== file.name)});
        const newFileList = fileList.filter((el) => el.uid !== file.uid);
        setFileList(newFileList);
    };

    const onFinish = async (values: any) => {
        setUploading(true);
        // const collection = form.getFieldValue("collection").replace(/_/g, "").toUpperCase();
        const {uploadTime, supplierName, userEmail, customSuffix, uploadType} = values;

        const collectionName = (
            `${uploadTime.format("MM.DD.YY")}` +
            `-${supplierName.join("+")}` +
            `-${userEmail}` +
            `${customSuffix.length > 0 ? `-${customSuffix}` : ""}` +
            `-${uploadType}`
        )
            .replace(/ /g, "-")
            .replace(/_/g, "-")
            .toUpperCase();

        const formData = new FormData();
        fileList.forEach((file) => {
            formData.append("files[]", file);
        });
        if (fileMap.wholesale) {
            formData.append("wholesale", JSON.stringify(fileMap.wholesale));
        }
        formData.append("keepa", JSON.stringify(fileMap.keepa));
        formData.append("columnsMapping", JSON.stringify(columnMapping));

        const allColumns = wholesaleColumns.map((column) => column.toLowerCase());
        for (const [key, value] of Object.entries(columnMapping)) {
            if (value.toLowerCase() !== key.toLowerCase() && allColumns.includes(key.toLowerCase())) {
                message.error(
                    <>
                        Column <b>{key}</b> exists in the uploaded file but was not selected to be treated as such!
                    </>
                );
                setUploading(false);
                return;
            }
            // if (key !== "upc") {
            // }
        }

        // @ts-ignore
        console.log(...formData.values());
        let respStatus = -1;
        let upcCheck = true;
        if (fileMap.wholesale.length > 0) {
            upcCheck = await new Promise((resolve) => {
                for (const wholesaleFileName of fileMap.wholesale) {
                    const wholesaleFile = fileList.find((file) => file.name === wholesaleFileName)!;

                    const wholesaleReader = new FileReader();

                    wholesaleReader.onload = async (e) => {
                        try {
                            console.log("preprocessing file", e);
                            const text = e.target!.result as string;
                            const wholesaleFrame = dataForge.fromCSV(text);
                            const upcColumn = wholesaleFrame.getSeries(columnMapping.upc).where((upc) => upc.length > 0);
                            const upcColumnDistinct = wholesaleFrame
                                .getSeries(columnMapping.upc)
                                .where((upc) => upc.length > 0)
                                .distinct();

                            let content = [];

                            if (upcColumn.where((upc) => upc.length === 11).count() > 0) {
                                content.push(
                                    <p>
                                        There are {upcColumn.where((upc) => upc.length === 11).count()} UPCs of length 11, presumably with a
                                        missing leading zero
                                    </p>
                                );
                            }

                            if (upcColumn.count() !== upcColumnDistinct.count()) {
                                content.push(<p>There are {upcColumn.count() - upcColumnDistinct.count()} duplicated UPCs</p>);
                            }

                            if (content.length > 0) {
                                content.push(<p>Do you want to proceed with the upload?</p>);
                                modal.confirm({
                                    title: `Hey you!`,
                                    icon: <ExclamationCircleOutlined />,
                                    content: content.map((node, idx) => <div key={idx}>{node}</div>),
                                    onOk() {
                                        resolve(true);
                                    },
                                    onCancel() {
                                        resolve(false);
                                    },
                                });
                            } else {
                                resolve(true);
                            }

                            console.log("UPC VALUES IN FRAME UPLOAD:", upcColumn.toArray());
                            console.log(
                                "DUPLICATED UPC VALUES IN FRAME UPLOAD:",
                                Object.entries(
                                    upcColumn.toArray().reduce((acc, curr) => ((acc[curr] = (acc[curr] || 0) + 1), acc), {}) // eslint-disable-line
                                ).filter(([, count]) => (count as number) > 1)
                            );
                        } catch (ex) {
                            message.error(`Error while preprocessing file - ${(ex as any).message}`);
                            resolve(false);
                        }
                    };

                    wholesaleReader.readAsText(wholesaleFile as Blob);
                }
            });
        }

        if (upcCheck) {
            console.log("UPC CHECK PASSED");
            console.log("Uploading", collectionName, values);

            currentUser?.getIdToken().then((token: string) => {
                console.log("Token", token);
                importProductsForUser(collectionName, formData, token)
                    .then((res) => {
                        respStatus = res.status;
                        return res.json();
                    })
                    .then((res) => {
                        setUploading(false);
                        if (respStatus === 200) {
                            SendToSlack(
                                SLACK_CHANNEL_IDS.LOG,
                                `User *${currentUser?.email}* uploaded data to collection <${window.location.href}?collection=${collectionName}_data|${collectionName}>!`
                            );

                            queryClient.invalidateQueries({queryKey: ["collections"]});

                            const xd = modal.success({
                                content: (
                                    <>
                                        <p>Successfully uploaded data to collection {collectionName}!</p>
                                    </>
                                ),
                                footer: (
                                    <Space style={{display: "flex", justifyContent: "end", width: "100%"}} align="end">
                                        <Button
                                            key="redirect"
                                            href={`${window.location.href}?collection=${collectionName}_data`}
                                            type="primary"
                                        >
                                            Go to collection
                                        </Button>
                                        <Button onClick={() => xd.destroy()} style={{marginLeft: "15px"}} key="submit" type="primary">
                                            OK
                                        </Button>
                                    </Space>
                                ),
                            });
                        } else {
                            modal.error({
                                title: `${res.msg}`,
                                content: <div dangerouslySetInnerHTML={{__html: res.error}} />,
                            });
                        }
                    });
            });
        } else {
            setUploading(false);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };

    return (
        <Spin spinning={uploading}>
            <>
                {contextHolder}
                <Form
                    {...layout}
                    form={form}
                    name="wholesaleForm"
                    initialValues={{
                        uploadTime: dayjs(),
                        userEmail: currentUser?.email?.split("@")[0].replace(".", ""),
                        uploadType: "Purchase",
                        supplierName: ["Random"],
                        customSuffix: "",
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item label="Date" name="uploadTime" rules={[{required: true, message: "Please select date!"}]}>
                        <DatePicker autoFocus allowClear />
                    </Form.Item>
                    <Form.Item label="Supplier" name="supplierName" rules={[{required: true, message: "Please select Supplier name!"}]}>
                        <Select
                            mode="tags"
                            allowClear
                            showSearch
                            options={[
                                {
                                    value: "Random",
                                    label: "Random",
                                },
                            ].concat(
                                suppliers?.map((supplier) => ({
                                    value: supplier.name,
                                    label: supplier.name,
                                })) || []
                            )}
                        />
                    </Form.Item>
                    <Form.Item label="User" name="userEmail">
                        <Input disabled />
                    </Form.Item>
                    <Form.Item label="Custom Suffix" name="customSuffix">
                        <Input />
                    </Form.Item>
                    <Form.Item label="Upload Type" name="uploadType">
                        <Select
                            allowClear
                            defaultValue={"Purchase"}
                            options={[
                                {
                                    value: "Purchase",
                                    label: "Purchase",
                                },
                                {
                                    value: "Research",
                                    label: "Research",
                                },
                                {
                                    value: "PO Eval",
                                    label: "PO Eval",
                                },
                                {
                                    value: "ShipLater",
                                    label: "ShipLater",
                                },
                                {
                                    value: "Random",
                                    label: "Random",
                                },
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        name="wholesaleData"
                        label="Wholesale Data"
                        valuePropName="wholesaleDataFile"
                        extra={
                            <div>
                                <Row>
                                    <Col span={12}>
                                        <b>Required columns:</b>
                                        <p>
                                            UPC:{" "}
                                            <ColumnSelect
                                                columns={wholesaleColumns}
                                                onChange={(val) => setColumnMapping((prev) => ({...prev, upc: val.trim()}))}
                                            ></ColumnSelect>
                                        </p>
                                        <p>
                                            OH (OnHand):{" "}
                                            <ColumnSelect
                                                columns={wholesaleColumns}
                                                onChange={(val) => setColumnMapping((prev) => ({...prev, oh: val.trim()}))}
                                            ></ColumnSelect>
                                        </p>
                                    </Col>
                                    <Col span={12}>
                                        <b>Additional columns:</b>
                                        <p>
                                            Cost:{" "}
                                            <ColumnSelect
                                                columns={wholesaleColumns}
                                                onChange={(val) => setColumnMapping((prev) => ({...prev, cost: val.trim()}))}
                                            ></ColumnSelect>
                                        </p>
                                        <p>
                                            MAP:{" "}
                                            <ColumnSelect
                                                columns={wholesaleColumns}
                                                onChange={(val) => setColumnMapping((prev) => ({...prev, map: val.trim()}))}
                                            ></ColumnSelect>
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        }
                    >
                        <Upload
                            beforeUpload={(file: RcFile) => beforeUpload(file, "wholesale")}
                            onRemove={(file: UploadFile) => onRemove(file, "wholesale")}
                            accept={".tsv, .csv"}
                            name="wholesaleData"
                            listType="text"
                        >
                            <Button icon={<UploadOutlined />}>Click to upload</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item
                        name="keepaData"
                        label="Keepa Data"
                        valuePropName="keepaDataFile"
                        extra={
                            <>
                                <b>Required columns:</b>
                                <Row>
                                    <Col span={12}>
                                        ASIN
                                        <br />
                                        Title
                                        <br />
                                        Sales Rank: Current
                                        <br />
                                        Sales Rank: 30 days avg.
                                        <br />
                                        Sales Rank: 90 days avg.
                                        <br />
                                        New: Current
                                        <br />
                                        Amazon: Current
                                        <br />
                                        List Price: Current
                                        <br />
                                        Buy Box 🚚: Current
                                        <br />
                                        Buy Box 🚚: 30 days avg.
                                        <br />
                                        Buy Box 🚚: 90 days avg.
                                        <br />
                                        Reviews: Review Count
                                    </Col>
                                    <Col span={12}>
                                        Product Group
                                        <br />
                                        FBA Pick&Pack Fee
                                        <br />
                                        Product Codes: UPC
                                        <br />
                                        Parent ASIN
                                        <br />
                                        Variation ASINs
                                        <br />
                                        Categories: Root
                                        <br />
                                        Categories: Tree
                                        <br />
                                        Package: Weight (g/lb)
                                        <br />
                                        Package: Length (cm/in)
                                        <br />
                                        Package: Width (cm/in)
                                        <br />
                                        Package: Height (cm/in)
                                    </Col>
                                </Row>
                            </>
                        }
                    >
                        <Upload
                            beforeUpload={(file: RcFile) => beforeUpload(file, "keepa")}
                            onRemove={(file: UploadFile) => onRemove(file, "keepa")}
                            accept={".tsv, .csv"}
                            name="keepaData"
                            listType="text"
                        >
                            <Button icon={<UploadOutlined />}>Click to upload</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </>
        </Spin>
    );
};

export default WholesaleImport;
