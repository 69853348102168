import {message} from "antd";
import * as dataForge from "data-forge";
import dayjs from "dayjs";
import {downloadHelper} from "./Downloads";
import {grabPectyData, requestPectyData} from "../../../services/OmegaService";
import {PectyOffer} from "../../../types/OmegaTypes";

export const processDataPectyHelper = async (data: ArrayBuffer[], collectionName: string) => {
    try {
        // read all the files
        let fullOutput: any[] = [];
        for (const input of data) {
            const jnputData = new TextDecoder("utf-8").decode(input);
            const keepaFrame = dataForge.fromCSV(jnputData).subset(["ASIN", "asin"]);
            console.log(keepaFrame.first());

            fullOutput = fullOutput.concat(keepaFrame.toArray());
        }

        // get unique ASINs
        const uniqueASINs = Array.from(new Set(fullOutput.map((row) => row["ASIN"] ?? row["asin"])));

        console.log(`Unique ASINs: ${uniqueASINs.length} | Total ASINs: ${fullOutput.length} | Collection: ${collectionName}`);
        console.log("Unique ASINs:", uniqueASINs);
        requestPectyData(uniqueASINs, "helper", collectionName.toLowerCase()).then((res) => {
            console.log("pectyrequest", res);
            if (res.error) {
                message.error("Failed to begin requesting Pecty Data");
            } else {
                message.success(`Successfully began requesting Pecty Data for ${uniqueASINs.length} ASINs!`);
            }
        });
    } catch (ex) {
        console.log(ex);
        message.error("Failed to process! Please send file to Karol.");
    }
};

export const downloadPectyData = async (collectionName: string) => {
    try {
        const data = await grabPectyData("helper");
        console.log(data);
        const pectyMap = data
            .filter((row) => row.dateProcessed > dayjs().subtract(3, "days").unix() * 1000)
            .filter((row) => row.mgrequestid === collectionName.toLowerCase())
            .reduce(
                (acc: any, curr) => (
                    // eslint-disable-next-line no-sequences
                    curr.dateProcessed > (acc[curr?.data?.asin]?.dateProcessed ?? 0) ? (acc[curr.data.asin] = curr) : null, acc
                ),
                {}
            );

        message.success(`Successfully downloaded data! ${Object.values(pectyMap).length} ASINs were found!`);

        const perSellerFrame: any[] = [];
        const finalFrame = new dataForge.DataFrame(
            Object.values(pectyMap)
                .map((obj: any) => ({...obj.data, ...obj}))
                .map((obj) => ({
                    ...obj,
                    offers: obj?.data?.offers ?? [],
                    QtyFBA:
                        obj?.data?.offers
                            ?.filter((offer: PectyOffer) => offer.fba)
                            .reduce((acc: any, curr: PectyOffer) => (acc += curr.inventoryCount), 0) ?? 0,
                    QtyFBM:
                        obj?.data?.offers
                            ?.filter((offer: PectyOffer) => !offer.fba)
                            .reduce((acc: any, curr: PectyOffer) => (acc += curr.inventoryCount), 0) ?? 0,
                }))
                .map(({asin, itemName, dateProcessed, totalOffers, totalQty, minPrice, avgPrice, maxPrice, QtyFBA, QtyFBM, offers}) => ({
                    asin,
                    itemName,
                    dateProcessed,
                    totalOffers,
                    totalQty,
                    minPrice,
                    avgPrice,
                    maxPrice,
                    offers,
                    QtyFBA,
                    QtyFBM,
                }))
                .map((offer) => ({...offer, dateProcessed: dayjs(offer.dateProcessed).format("MM-DD-YYYY")}))
        );

        finalFrame.forEach((row) => {
            row.offers.forEach((offer: PectyOffer) => {
                if (offer.sellerName !== "M Emporium") {
                    perSellerFrame.push({
                        asin: row.asin,
                        itemName: row.itemName,
                        dateProcessed: row.dateProcessed,

                        moq: offer.moq,
                        sellerName: offer.sellerName,
                        fba: offer.fba,
                        price: offer.priceTotal?.toFixed(2),
                        inventoryCount: offer.inventoryCount,
                    });
                }
            });
        });

        downloadHelper(perSellerFrame, `${collectionName}-${dayjs().format("YYYY-MM-DD")}.csv`);
    } catch (ex) {
        console.log(ex);
        message.error("Failed to download data! Please send file to Karol.");
    }
};
