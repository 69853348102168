import {Item} from "@scaleleap/selling-partner-api-sdk/lib/api-models/catalog-items-api-model-v20220401";
import {Batch, BatchCategory, InvData, SizeTier, UPCNotif, WarehouseProduct, WarehouseProductBase} from "../../../types/WarehouseTypes";
import {KeepaCodeType, getKeepa} from "../../../services/KeepaService";
import {searchCatalogItems} from "../../../services/SPApiService";
import {OMSItem} from "../../../types/OmegaTypes";
import {getUpcNotifs} from "../../../services/WarehouseService";

export function extractSPCatalogItem(item: Item): WarehouseProductBase {
    let asin = item.asin;

    let attributeSet = item.attributes as any;
    let name = attributeSet?.item_name?.[0].value;
    let brand = attributeSet?.brand?.[0].value;

    // @ts-ignore
    let dims = item.dimensions?.[0].package;
    let Dimensions = {
        Width: dims?.width?.value || -1,
        Height: dims?.height?.value || -1,
        Length: dims?.length?.value || -1,
        Weight: dims?.weight?.value || -1,
        WeightUnits: dims?.weight?.unit || "N/A",
    };
    let sizeTier = calcSizeTier(Dimensions.Width, Dimensions.Length, Dimensions.Height, Dimensions.Weight, Dimensions.WeightUnits);

    let color = attributeSet?.color?.[0].value;
    let size = item.summaries?.[0].size;

    let salesRank = item.salesRanks?.[0].classificationRanks?.[0]?.rank;

    let images = item.images?.[0].images || [];
    // Find the first main image and scale it to 500x500
    let mainImage = images.find((img) => img.variant === "MAIN");
    let imgURL = mainImage?.link || "";
    if (imgURL.includes("__SL75__")) {
        imgURL = imgURL.replace("_SL75_", "_SL500_");
    } else {
        imgURL = imgURL.replace(".jpg", "._SL500_.jpg");
    }

    const marketplaces = item.identifiers || [];
    const identifiers = marketplaces.find((i) => i.marketplaceId === "ATVPDKIKX0DER")?.identifiers || [];

    let upc = identifiers.find((i) => i.identifierType === "UPC")?.identifier;

    return {
        asin,
        upc,
        brand,
        name,
        imgURL,
        color,
        size,
        salesRank,
        sizeTier,
    };
}

function calcSizeTier(width: number, length: number, height: number, weight: number, weightUnits: string): SizeTier {
    let dims = [width, height, length];
    dims.sort((a, b) => a - b);

    if (weight === -1 || dims[2] <= 0) {
        return "Unspecified";
    }

    // Small standard-size
    if (
        ((weight <= 12 && weightUnits === "ounces") || (weight <= 0.75 && weightUnits === "pounds")) &&
        dims[2] <= 15 &&
        dims[1] <= 12 &&
        dims[0] <= 0.75
    ) {
        return "Small";
    }

    // Large standard-size
    if (weight <= 20 && weightUnits === "pounds" && dims[2] <= 18 && dims[1] <= 14 && dims[0] <= 8) {
        return "Large";
    }

    // Small oversize
    let girth = (dims[0] + dims[1]) * 2;
    if (weight <= 70 && weightUnits === "pounds" && dims[2] <= 60 && dims[1] <= 30 && dims[2] + girth <= 130) {
        return "Small oversize";
    }

    // Medium oversize
    if (weight <= 150 && weightUnits === "pounds" && dims[2] <= 108 && dims[2] + girth <= 130) {
        return "Medium oversize";
    }

    // Large oversize
    if (weight <= 150 && dims[2] + girth <= 165) {
        return "Large oversize";
    }

    return "Special oversize";
}

export function getSearchType(code: string) {
    if (code.search(/^LPN.*/i) !== -1) return "LPN";
    if (code.length > 10) return "UPC";
    if (code.charAt(0) === "X") return "FNSKU";
    if (/^(B[\dA-Z]{9}|\d{9}(X|\d))$/g.test(code)) return "ASIN";
    return null;
}

async function searchAmazon(identifiers: string[], identifiersType: "UPC" | "ASIN") {
    const res = await searchCatalogItems({
        identifiers,
        marketplaceIds: ["ATVPDKIKX0DER"],
        identifiersType: identifiersType,
        includedData: ["identifiers", "dimensions", "attributes", "summaries", "images"],
    });

    const items = res.map(extractSPCatalogItem);

    return items;
}

async function searchKeepa(code: string, identifiersType: "UPC" | "ASIN") {
    const typeMap: {[key: string]: KeepaCodeType} = {
        UPC: "code",
        ASIN: "asin",
    };
    const res = await getKeepa(code, typeMap[identifiersType]);

    return res.products || [];
}

export async function fetchProductsData(code: string, identifiersType: "UPC" | "ASIN") {
    let [spItems, keepaProducts] = await Promise.all([searchAmazon([code], identifiersType), searchKeepa(code, identifiersType)]);

    const asins = keepaProducts.map((p) => p.asin).filter((a) => a) as string[];
    if (spItems.length === 0 && asins.length === 0) {
        throw new Error("No product found");
    }

    if (spItems.length === 0) {
        spItems = await searchAmazon(asins, "ASIN");
    }

    // Most calls should finish here
    if (spItems.length > 0) {
        return spItems.map((item) => {
            const keepaData = keepaProducts.find((k) => k.asin === item.asin);
            let category: string | undefined = undefined;
            if (keepaData?.categoryTree?.length) {
                category = keepaData.categoryTree[0].name;
            }
            const productGroup = keepaData?.productGroup;
            return {
                ...item,
                category,
                productGroup,
            };
        });
    }

    throw new Error("No product found");
}

export async function fetchInvData(products: WarehouseProductBase[]): Promise<WarehouseProduct[]> {
    const responses = await Promise.all(
        products.map(async (product) => {
            const res = await fetch(`https://api.projectsuite.io/sku_asin?asin=${product.asin}`).then((res) => res.json());
            return res;
        })
    );

    return responses.map((res, i) => {
        const invData: InvData[] = res.result.map((r: any) => ({
            sku: r.SKU,
            fnsku: r.FNSKU,
            quantity: parseInt(r.Quantity || 0),
            condition: r.Condition,
        }));
        // If there is only 1 option, set it as the default
        const selectedData = invData.length === 1 ? invData[0] : undefined;
        const p: WarehouseProduct = {
            ...products[i],
            quantity: 0,
            invData,
            ...selectedData,
        };
        return p;
    });
}

export function getBatchCategory(product: WarehouseProduct): BatchCategory {
    if (product.productGroup === "Shoes") {
        return "Shoes";
    }

    if (product.productGroup === "Jewelry") {
        return "Jewelry";
    }

    if (product.sizeTier?.toLowerCase().includes("oversize")) {
        return "Oversized";
    }

    return "Standard";
}

export async function parseOMSProducts(products: OMSItem[]): Promise<WarehouseProduct[]> {
    // First check if Keepa data is available
    const missing = products.filter((p) => !p.KeepaData);

    // For missing Keepa data, fetch it 100 at a time
    const promises = [];
    for (let i = 0; i < missing.length; i += 100) {
        promises.push(
            getKeepa(
                missing
                    .slice(i, i + 100)
                    .map((p) => p.ASIN)
                    .join(","),
                "asin",
                ""
            )
        );
    }
    const keepaResults = await Promise.all(promises);
    const keepaData = keepaResults.flatMap((r) => r.products || []);

    // Combine Keepa data with OMS data
    const combined = products.map((p) => {
        const keepa = keepaData.find((k) => k.asin === p.ASIN);
        if (keepa) {
            return {
                ...p,
                KeepaData: keepa,
            };
        }
        return {
            ...p,
        };
    });

    const baseProducts: WarehouseProductBase[] = [];
    for (const p of combined) {
        const keepa = p.KeepaData;
        if (keepa) {
            const productGroup = keepa.productGroup;
            let category: string | undefined = undefined;
            if (keepa.categoryTree?.length) {
                category = keepa.categoryTree[0].name;
            }
            const imagesCSV = keepa.imagesCSV?.split(",");
            let imgUrl = "";
            if (imagesCSV?.length) {
                imgUrl = `https://m.media-amazon.com/images/I/${imagesCSV[0]}`;
            }

            let height = keepa.packageHeight || 0;
            let width = keepa.packageWidth || 0;
            let length = keepa.packageLength || 0;
            let weight = keepa.packageWeight || 0;

            // Values are in mm and grams, convert them to inches and pounds
            height /= 25.4;
            width /= 25.4;
            length /= 25.4;
            weight /= 453.592;

            const sizeTier = calcSizeTier(width, length, height, weight, "pounds");

            baseProducts.push({
                asin: p.ASIN || "",
                brand: keepa.brand || "",
                name: p.AMZ_Title || "",
                imgURL: imgUrl,
                productGroup,
                category,
                sizeTier,
                color: keepa.color || "",
                size: keepa.size || "",
            });
        }
    }

    let invProducts = await fetchInvData(baseProducts);
    invProducts = invProducts.map((product) => {
        const omsProduct = combined.find((p) => p.ASIN === product.asin);
        return {
            ...product,
            batchCategory: getBatchCategory(product),
            supplierPO: omsProduct?.SupplierPO,
            quantity: omsProduct?.Quantity || 0,
        };
    });

    return invProducts;
}

export function parseBatches(batches: Batch[]) {
    const scannedItems: {[key in BatchCategory]?: WarehouseProduct[]} = {};
    batches.forEach((batch) => {
        let items = batch.items || [];
        if (scannedItems[batch.category]) {
            scannedItems[batch.category] = [...scannedItems[batch.category]!, ...items];
        } else {
            scannedItems[batch.category] = items;
        }
    });
    return scannedItems;
}

export async function checkUpcNotifs(products: WarehouseProduct[]): Promise<UPCNotif[]> {
    const upcProducts = products.filter((p) => p.upc);
    const upcs = upcProducts.map((p) => p.upc) as string[];
    let notifications: UPCNotif[] = [];
    try {
        const res = await getUpcNotifs(upcs);

        for (const n of res) {
            notifications.push({
                ...n,
                timestamp: new Date(n.timestamp),
            });
        }
    } catch (e) {
        console.error(e);
    }

    const asinProducts = products.filter((p) => p.asin);
    const asins = asinProducts.map((p) => p.asin) as string[];

    try {
        const res = await getUpcNotifs(asins);

        for (const n of res) {
            notifications.push({
                ...n,
                timestamp: new Date(n.timestamp),
            });
        }
    } catch (e) {
        console.error(e);
    }

    return notifications;
}
