import { message } from 'antd';
import * as dataForge from 'data-forge'
import dayjs from 'dayjs';
import { downloadHelper } from "./Downloads";
import { GetSKUForASIN } from '../../../services/OmegaService';



export const processDataSKUGrabberHelper = async (data: ArrayBuffer) => {
    try {
        const inputData = new TextDecoder("utf-8").decode(data);
        const keepaFrame = dataForge.fromCSV(inputData)

        let asinSkuMap = new Map<string, {SKU: string, Quantity: string, PotentiallyArchived?: boolean}[]>();
        for (const skuWindow of Array.from(keepaFrame.window(1))) {
            const asinsToGrab = skuWindow.getSeries('ASIN').distinct().toArray().join(',');
            await GetSKUForASIN(asinsToGrab).then((res) => {
                console.log(res);
                for (const result of res) {
                    if (asinSkuMap.has(result.ASIN)) {
                        asinSkuMap.set(result.ASIN, [...asinSkuMap.get(result.ASIN)!, result])
                    } else {
                        asinSkuMap.set(result.ASIN, [result])
                    }
                }
            })
        }

        let fullResult: any[] = []
        keepaFrame.toArray().forEach((row) => {
            const skus = asinSkuMap.get(row.ASIN)
            if (skus) {
                skus.forEach((skuObj) => {
                    fullResult.push({ 
                        ...row, 
                        [row.MSKU !== undefined ? 'MSKU' : 'SKU']: skuObj.SKU,
                        IdUnits: skuObj.Quantity,
                        PotentiallyArchived: skuObj.PotentiallyArchived ? 'Yes' : 'No',
                        IdentifierEncounters: skus.length
                    })
                })
            } else {
                fullResult.push({ 
                    ...row, 
                    [row.MSKU !== undefined ? 'MSKU' : 'SKU']: 'Not Found',
                    IdUnits: 'Not Found',
                    PotentiallyArchived: 'Not Found',
                    IdentifierEncounters: 0
                })
            }
        })

        console.log(fullResult);

        downloadHelper(fullResult, `${dayjs().format('MM-DD-YYYY')}-SKU-Grabber-Result`)
    } catch (ex) {
        message.error('Failed to process! Please send file to Karol.')
    }
}