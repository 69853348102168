import {Avatar, Button, InputNumber, Skeleton, Space, Table} from "antd";
import {ColumnsType} from "antd/lib/table";
import React from "react";
import {WarehouseProduct} from "../../../types/WarehouseTypes";
import PrintButton from "./PrintButton";
import {DeleteOutlined} from "@ant-design/icons";

interface BatchTableProps {
    items: WarehouseProduct[];
    onItemDelete?: (asin: string) => void;
    onItemQuantityChange?: (asin: string, quantity: number) => void;
    onMoveItem?: (item: WarehouseProduct) => void;
    onPrintLabels?: (item: WarehouseProduct, quantity: number) => void;
    onInvSelect?: (asin: string) => void;
    title?: (data: readonly WarehouseProduct[]) => React.ReactNode;
}

const BatchTable: React.FC<BatchTableProps> = ({
    items,
    onItemDelete,
    onItemQuantityChange,
    title,
    onMoveItem,
    onPrintLabels,
    onInvSelect,
}) => {
    const columns: ColumnsType<WarehouseProduct> = [
        {
            title: "Image",
            dataIndex: "imgURL",
            key: "imgURL",
            render: (imgURL) => (!imgURL ? <Skeleton.Image /> : <Avatar src={imgURL} size={40} shape="square" />),
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "ASIN",
            dataIndex: "asin",
            key: "asin",
        },
        {
            title: "SKU",
            dataIndex: "sku",
            key: "sku",
            onCell: (data) => {
                if (!data.sku) {
                    let style = {
                        backgroundColor: `rgba(255, 0, 0, 0.5)`,
                    };
                    return {style};
                } else {
                    return {};
                }
            },
        },
        {
            title: "FNSKU",
            dataIndex: "fnsku",
            key: "fnsku",
        },
        {
            title: "UPC",
            dataIndex: "upc",
            key: "upc",
        },
        {
            title: "Inventory Quantity",
            dataIndex: "invQuantity",
            key: "invQuantity",
        },
        {
            title: "Condition",
            dataIndex: "condition",
            key: "condition",
        },
    ];

    if (onItemQuantityChange) {
        columns.push({
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
            render: (text, record) => (
                <InputNumber
                    min={1}
                    value={record.quantity}
                    onChange={(newQuantity) => onItemQuantityChange(record.asin, newQuantity as number)}
                    style={{width: 70}}
                />
            ),
        });
    } else {
        columns.push({
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
        });
    }

    if (onPrintLabels) {
        columns.push({
            title: "Print",
            key: "action",
            width: 120,
            render: (item: WarehouseProduct) => <PrintButton onPrintLabel={(quantity) => onPrintLabels(item, quantity)} />,
        });
    }

    if (onItemDelete) {
        columns.push({
            title: "Action",
            key: "action",
            render: (text, record) => (
                <Space>
                    <Button onClick={() => onItemDelete(record.asin)} danger icon={<DeleteOutlined />} />
                    {onMoveItem && <Button onClick={() => onMoveItem(record)}>Move</Button>}
                    {onInvSelect && (
                        <Button disabled={!record.invData} onClick={() => onInvSelect(record.asin)}>
                            Inv
                        </Button>
                    )}
                </Space>
            ),
        });
    }

    return <Table rowKey={(p) => p.asin} columns={columns} dataSource={items} title={() => (title ? title(items) : undefined)} />;
};

export default BatchTable;
