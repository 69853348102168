import { Select, SelectProps } from 'antd';
import React from 'react';

export default function AddSelect({
    save,
    selectableValues,
    onChange,
    value,
    ...props
}: SelectProps & {
    save?: () => void;
    selectableValues?: string[];
}) {
    return (
        <Select<string[]>
            {...props}
            value={value || undefined}
            mode='tags'
            allowClear
            autoFocus
            onBlur={save}
            defaultOpen
            onChange={(value, option) => {
                onChange?.(
                    value.length ? value[value.length - 1] : undefined,
                    option
                );
            }}
            options={selectableValues?.map(item => ({
                label: item,
                value: item,
            }))}
        />
    );
}
