import {CopyFilled} from "@ant-design/icons";
import {Button, Col, Collapse, message, Modal, Popconfirm, Row, Typography} from "antd";
import dayjs from "dayjs";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import React, {useEffect, useRef, useState} from "react";
import {useSupplierContext} from "../../contexts/SupplierContext";
import {SupplierNoteItem} from "../../types/Brand";
import Editor from "./quill/Editor";

interface NoteEditorPrompts {
    title: string;
}

const Delta = Quill.import("delta");

const getNoteKey = (note: SupplierNoteItem) => `${note.title}-${note.updateTimestamp ?? ""}`;

const saveToLocalStorage = (delta: any) => {
    localStorage.setItem("note_draft", JSON.stringify(delta));
    message.success("Draft saved to local storage");
};

const onBeforeUnload = (e: any) => {
    e.preventDefault();
    e.returnValue = "";
};

const MultiNoteEditor: React.FC<NoteEditorPrompts> = ({title}) => {
    const {selectedSupplier: supplier, selectedSupplierNotes, updateSupplierNotes} = useSupplierContext();
    const [activeKey, setActiveKey] = useState<string | string[]>([]);
    const [supplierNotes, setSupplierNotes] = useState<any[]>([]);
    const quillRef = useRef<Quill>();
    const inputRef = useRef<HTMLInputElement>(null);
    const lastDraftSave = useRef<number>(0);

    const showNoteModal = (existingNote?: SupplierNoteItem) => {
        window.addEventListener("beforeunload", onBeforeUnload);
        const xd = Modal.confirm({
            title: "Let's get a new note added",
            maskClosable: false,
            keyboard: false,
            content: (
                <>
                    <Editor
                        ref={quillRef}
                        // @ts-ignore
                        onTextChange={() => {
                            const currentOps = quillRef?.current?.getContents().ops;

                            if (currentOps && currentOps.length > 0 && currentOps[0].insert !== "\n") {
                                if (Date.now() - lastDraftSave.current >= 60 * 1000) {
                                    lastDraftSave.current = Date.now();
                                    saveToLocalStorage(currentOps);
                                }
                            }
                        }}
                        defaultValue={existingNote ? new Delta(existingNote.ops) : new Delta()}
                    />
                    <div className="controls">
                        <p>
                            <b>Title:</b> <input ref={inputRef} defaultValue={existingNote?.title ?? ""} />
                        </p>
                        {/* <p>
                            <b>Last save:</b> {dayjs(lastDraftSave.current).toString()}
                        </p> */}
                    </div>
                </>
            ),
            style: {
                minWidth: "600px",
            },
            footer: () => (
                <>
                    <Popconfirm
                        title="Are you sure you want to restore saved draft?"
                        onConfirm={() => {
                            const storedContents = JSON.parse(localStorage.getItem(`note_draft`) ?? "[]");
                            // @ts-ignore
                            quillRef.current.setContents(new Delta(storedContents));
                        }}
                        onCancel={() => {}}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button key="back">Restore</Button>
                    </Popconfirm>
                    <Popconfirm
                        title="Are you sure you want to reset this note?"
                        onConfirm={() => {
                            // @ts-ignore
                            quillRef.current.setContents(new Delta());
                            inputRef.current!.value = "";
                        }}
                        onCancel={() => {}}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button key="back">Reset</Button>
                    </Popconfirm>
                    <Popconfirm
                        title="Are you sure want to discard this note?"
                        onConfirm={() => {
                            xd.destroy();

                            window.removeEventListener("beforeunload", onBeforeUnload);
                        }}
                        onCancel={() => {}}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button key="discard">Discard</Button>
                    </Popconfirm>
                    <Button
                        key="submit"
                        type="primary"
                        onClick={() => {
                            // @ts-ignore
                            const ops = quillRef.current.getContents().ops;
                            const noteTitle = inputRef.current!.value;

                            if (!noteTitle || noteTitle.length === 0) {
                                message.error("Title cannot be empty");
                                return;
                            } else if (/^[a-zA-Z0-9-_ ]+$/g.test(noteTitle) === false) {
                                message.error("Title must be alphanumeric (no special characters)");
                                return;
                            }

                            const updateNote: Omit<SupplierNoteItem, "author"> = {
                                Brand: supplier!.name,
                                title: noteTitle,
                                type: title,
                                ops,
                            };

                            if (existingNote) {
                                updateNote._id = existingNote._id;
                            }

                            updateSupplierNotes([updateNote]);

                            // @ts-ignore
                            quillRef.current.setContents(new Delta());
                            inputRef.current!.value = "";
                            xd.destroy();
                            window.removeEventListener("beforeunload", onBeforeUnload);
                        }}
                    >
                        Save
                    </Button>
                </>
            ),
        });
    };

    useEffect(() => {
        if (supplier) {
            let applicableNotes = selectedSupplierNotes.filter(
                (note) => (note.type === undefined && title === "journal") || note.type === title
            );
            setSupplierNotes(
                supplier?.supplierNotes?.[title]
                    ? [
                          ...applicableNotes,
                          {
                              title: title,
                              ops: supplier.supplierNotes[title],
                          },
                      ]
                    : [...applicableNotes]
            );
        }
    }, [supplier, selectedSupplierNotes, title]);

    useEffect(() => {
        const queryString = new URLSearchParams(window.location.search);
        const queryTitle = queryString.get("note_title");

        if (queryTitle) {
            const note = supplierNotes.find((note) => note.title === queryTitle);
            if (note) {
                setActiveKey(getNoteKey(note));
            }
        } else {
            // do nothing
        }
    }, [supplierNotes]);

    return (
        <Row gutter={[8, 8]}>
            <Col span={24} style={{display: "flex", alignContent: "center", justifyContent: "center", gap: "6px"}}>
                <Button
                    type="primary"
                    onClick={() => {
                        showNoteModal();
                    }}
                >
                    Add New Note
                </Button>
                <Button
                    onClick={() => {
                        setActiveKey(supplierNotes.map(getNoteKey));
                    }}
                >
                    Expand All
                </Button>
                <Button
                    onClick={() => {
                        setActiveKey([]);
                    }}
                >
                    Collapse All
                </Button>
            </Col>
            <Col span={24} style={{minHeight: "300px"}}>
                <Collapse
                    activeKey={activeKey}
                    onChange={setActiveKey}
                    items={supplierNotes.map((el, idx) => ({
                        key: getNoteKey(el),
                        label: (
                            <div style={{display: "flex", minHeight: "100%"}}>
                                <Typography.Text>
                                    {" "}
                                    {el.insertTimestamp ? dayjs(el.insertTimestamp).format("MM-DD-YYYY") : "Unknown"} by{" "}
                                    {el.author ?? "Unknown"}
                                    {" - "}
                                    <b>{el.title}</b>
                                </Typography.Text>
                            </div>
                        ),
                        extra: [
                            // copy link to note
                            <Button
                                size="small"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    const url = new URL(window.location.href);
                                    url.searchParams.set("note_title", el.title);
                                    navigator.clipboard.writeText(url.toString());
                                    message.success("Link copied to clipboard");
                                }}
                            >
                                <CopyFilled />
                            </Button>,
                            <Button
                                size="small"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    showNoteModal(el);
                                }}
                                disabled={!el.insertTimestamp || dayjs().diff(dayjs(el.insertTimestamp), "days") >= 7}
                            >
                                Edit
                            </Button>,
                            // <Button
                            //     danger
                            //     type="primary"
                            //     size="small"
                            //     onClick={(e) => {
                            //         e.stopPropagation();
                            //         updateSupplierNotes([
                            //             {
                            //                 ...el,
                            //                 delete: true,
                            //             },
                            //         ]);
                            //     }}
                            // >
                            //     Delete
                            // </Button>,
                        ],
                        children: (
                            <>
                                <Editor
                                    ref={{current: null}}
                                    // @ts-ignore
                                    readOnly={true}
                                    defaultValue={new Delta(el.ops)}
                                />
                            </>
                        ),
                    }))}
                ></Collapse>
            </Col>
        </Row>
    );
};

export default MultiNoteEditor;
