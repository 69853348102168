import {create} from "zustand";
import {AppState} from "../types/storeTypes";
import {createBoxingDataSlice} from "./warehouse/boxingDataSlice";
import {createBoxingActionsSlice} from "./warehouse/boxingActionsSlice";
import {createUserDataSlice} from "./userDataSlice";
import {immer} from "zustand/middleware/immer";
import { createPrintSlice } from "./warehouse/printSlice";

export const useStore = create<AppState>()(
    immer((...a) => ({
        ...createBoxingDataSlice(...a),
        ...createBoxingActionsSlice(...a),
        ...createUserDataSlice(...a),
        ...createPrintSlice(...a),
    }))
);
