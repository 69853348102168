import React from "react";
import {KeepaProduct} from "./KeepaTypes";
import {SellerSnapItem} from "./WholesaleItem";
import {ExpandedNote} from "../components/utilities/NotesRenderer";

export const SEASONAL_TAGS = [
    "EG",
    "Summer",
    "Cool",
    "Winter",
    "Volleyball",
    "Football",
    "Halloween",
    "Christmas",
    "BTS",
    "SL",
    "Easter",
    "MAP",
] as const;

export type SeasonalTag = (typeof SEASONAL_TAGS)[number];

export const ORDER_TYPES = ["AT ONCE", "BOOKING", "CLOSEOUT"] as const;

export type OrderType = (typeof ORDER_TYPES)[number];

export const VENDOR_TYPES = ["OUR BRANDS", "GMA", "BLUETIME", "FC", "ADAM", "AFFARI"] as const;

export type VendorType = (typeof VENDOR_TYPES)[number];

export const DISTRIBUTOR_TYPES = ["GMA", "FC", "AFFARI", "SPORTSINC"] as const;

export type DistributorType = (typeof DISTRIBUTOR_TYPES)[number];

export const SUPPLIER_USERS = [
    "jacob@gomooregroup.com",
    "vincent@mooreemporium.com",
    "mat@gomooregroup.com",
    "justin@gomooregroup.com",
    "jake@gomooregroup.com",
    "mika@gomooregroup.com",
    "emily@gomooregroup.com",
] as const;

export const SUPPLIER_MANAGERS = [
    "lucas@gomooregroup.com",
    "arthur@gomooregroup.com",
    "paula@gomooregroup.com",
    "gabriel.r@gomooregroup.com",
    "gabriel.e@gomooregroup.com",
    "joao@gomooregroup.com",
    "bernardo@gomooregroup.com",
    "victor@gomooregroup.com",
    "matheus@gomooregroup.com",
    "marco@gomooregroup.com",
    "daniel@gomooregroup.com",
    "marra@gomooregroup.com",
    "miguel@gomooregroup.com",
] as const;

export type SupplierUser = (typeof SUPPLIER_USERS)[number];

export const ACCEPTANCE_MODES = ["", "YES", "NO"] as const;

export const INVOLVEMENT_MODES = ["", "None", "MG Item", "MG Exclusive"] as const;

export const BRAND_OPPORTUNITIES = [
    "MG Item",
    "MG Exclusive",
    "Discount Needed",
    "Not Available",
    "BB Issue",
    "Replenishable",
    "Non UPC Match",
];

export type CcAcceptanceMode = (typeof ACCEPTANCE_MODES)[number];
export type MapFollowMode = (typeof ACCEPTANCE_MODES)[number];
export type BizMapFollowMode = (typeof ACCEPTANCE_MODES)[number];
export type MapNotifyMode = (typeof ACCEPTANCE_MODES)[number];

export const SHIP_TO_VALUES = ["IL", "IJ", "WI", "CA", "WV"];

export const CURRENT_SHIPMENT_STATUSES = ["Complete", "Draft", "Scheduled"] as const;

export type CurrentShipmentStatus = (typeof CURRENT_SHIPMENT_STATUSES)[number];

interface BasicOMSItem {
    _id?: string;
    Quantity: number | null;
    Supplier_PO: string;
    Supplier_SO: string;
    UPC: string;
    Cost: number | null;
    Ship_Requested: Date;
    Supplier_Date: Date;
    Supplier_Name: string;
    Supplier_Notes: string;
    Supplier_SKU: string;
    Supplier_Title: string;
    MAP?: number | null;
    Shipped?: number;
    Unshipped?: number;
    deleted?: boolean;
}

interface OMSStage1Properties {
    "OrderType"?: string;
    "VendorType"?: string;
    "Row"?: number | string;
    "S1_Pushed?"?: string;
    "S1_Pushed_By"?: string;
}

interface OMSStage2Properties {
    "Replenishable?"?: string;
    "OMS_Tag"?: string;
    "Checked?"?: boolean;
    "Upc Match?"?: string;
    "ASIN"?: string;
    "AMZ_SalesRank"?: number;
    "AMZ_Title"?: string;
    "AMZ_SizeTier"?: string;
    "AMZ_Orphan"?: string;
    "S2_Pushed?"?: string;
    "S2_Pushed_By"?: string;
}

interface OMSStage3Properties {
    "ParentASIN"?: string;
    "Max Price/List"?: number | null;
    "Min Price"?: number | null;
    "Sell Price"?: number | null;
    "Seasonal Tag"?: string;
    "Req_Cancel?"?: string;
    "Listing Issues?"?: string;
    "Ship Now"?: number | null;
    "Ship Later"?: number | null;
    "S2_Pushed_By"?: string;
    "S3_Pushed?"?: string;
    "S3_Pushed_By"?: string;
    "S4 Pushed?"?: string;
    "S4_Pushed_By"?: string;
    "Ship_Total"?: number | null;
    "KeepaData"?: KeepaProduct;
    "Prelisted IL?"?: string;
    "MVSP"?: number;
}

export interface OMSItem extends BasicOMSItem, OMSStage1Properties, OMSStage2Properties, OMSStage3Properties {
    [key: string]: any;
}

export interface OMSItemRenderType extends OMSItem {
    key: React.Key;
}

export type OMSMap = {[key: string]: OMSItem};

export const POSSIBLE_OMS_TRACKER_ISSUES = [
    "SHIPPED LAST 7 DAYS",
    "PARTIAL SHIPPED",
    "PAID/UNSHIPPED",
    "PAID/PARTIAL SHIPPED",
    "SHIPPED / NOT PRELISTED",
    "OVERDUE",
    "UPCOMING 10",
    "UPCOMING 30",
    "UPCOMING 60",
] as const;

export type OMSTrackerIssue = (typeof POSSIBLE_OMS_TRACKER_ISSUES)[number];

export interface OMSTrackerItem {
    "_id": string;
    "Supplier_PO": string;
    "Supplier_SO": string;
    "# of Shipments": number;
    "Amount Due"?: number;
    "Amount Owed"?: number;
    "Amount Paid": number;
    "Date Paid": Date;
    "Includes ShipLater?": boolean;
    "Invoice #": string;
    "Last Shipment Date": Date | "";
    "Notes"?: (string | ExpandedNote)[];
    "Order Amount": number;
    "Prelisted IL?": string;
    "Ship_Requested": Date;
    "Shipped To": string;
    "Supplier_Name": string;
    "Total Freight Costs": number;
    "Total Units": number;
    "Units Shipped": number;
    "Units Unshipped": number;
    "Invoice Added": string;
    "Routing Instructions"?: string;
    "PaymentNotes"?: string;
    "Issues": OMSTrackerIssue[];
    "ArchiveTimestamp"?: Date;
    "ConnectedUsers"?: string[];
    [key: string]: any;
}

export interface OMSTrackerItemRenderType extends OMSTrackerItem {
    key: React.Key;
}

export interface SupplierNotes {
    [key: string]: any[];
}

export const POSSIBLE_PAYMENT_METHODS = ["CC", "ACH", ""] as const;

export const POSSIBLE_GROWTH_PLANS = ["Maximize", "Transactional", ""] as const;

export const POSSIBLE_PAYMENT_TERMS = ["Upfront", "Net 15", "Net 30", "Net 45", "Net 60", "Net 90", "Other", ""] as const;

export interface SupplierDataValues {
    // span of 8
    brandManager: string;
    brandStrategist: string[];
    brandStakeholders: string[];
    salesRep: string;
    brandChannel: string;

    monthlyRevenue: number;
    ourRevenue: number;
    goalRevenue: number;
    growthPlan: (typeof POSSIBLE_GROWTH_PLANS)[number];
    isNegotiable: boolean;

    // span of 4
    isDistributor: boolean;
    isExclusive: boolean;
    mapPolicyValue: boolean;
    b2bMapPolicy: boolean;
    paymentMethod: (typeof POSSIBLE_PAYMENT_METHODS)[number];
    paymentTerms: (typeof POSSIBLE_PAYMENT_TERMS)[number];
    couponable: boolean;
    isDistributorText: string;
    isExclusiveText: string;
    mapPolicyValueText: string;
    b2bMapPolicyText: string;
    couponableText: string;
    paymentMethodText: string;
    paymentTermsText: string;

    // span of 6
    bookingWindows: string;
    bookingRevisionPolicy: string;
    atsGuide: string;
    orderNotes: string;

    // span of 6
    orderForm: string;
    priceList: string;
    discountRules: string;
    shippingNotes: string;

    // span of 4
    // prepDelay: number;
    // prepCost: number;
    // shippingCost: number;
    // returnRate: number;

    // averageUnitProcessingCost: string;
    // averageUnitInboundCost: string;
    // exclusive: string;
    // prelisting: string;
    // specialOrderNotes: string;

    // bookingOrderAvailability: string;
    // recurringTask: string;
    // [key: string]: any;
}

export interface Supplier {
    _id?: string;
    name: string;
    user?: string;
    date?: Date;
    ccAcceptance: CcAcceptanceMode;
    mapFollow: MapFollowMode;
    bizMapFollow: BizMapFollowMode;
    mapNotify: MapNotifyMode;
    VendorType: VendorType;
    paymentProcess: string;
    supplierNotes: SupplierNotes;
    connectedUsers?: SupplierUser[];
    supplierBrands: string[];
    dataValues?: SupplierDataValues;
}

export interface SupplierRenderType extends Supplier {
    key: React.Key;
    index: number;
}

export interface InsertTimestamp {
    $date: Date;
}

export interface OrderedProductSales {
    amount: number;
    currencyCode: string;
}

export interface OrderedProductSalesB2B {
    amount: number;
    currencyCode: string;
}

export interface SalesByAsin {
    unitsOrdered: number;
    unitsOrderedB2B: number;
    orderedProductSales: OrderedProductSales;
    orderedProductSalesB2B: OrderedProductSalesB2B;
    totalOrderItems: number;
    totalOrderItemsB2B: number;
}

export interface TrafficByAsin {
    browserSessions: number;
    mobileAppSessions: number;
    sessions: number;
    browserSessionPercentage: number;
    mobileAppSessionPercentage: number;
    sessionPercentage: number;
    browserPageViews: number;
    mobileAppPageViews: number;
    pageViews: number;
    browserPageViewsPercentage: number;
    mobileAppPageViewsPercentage: number;
    pageViewsPercentage: number;
    buyBoxPercentage: number;
    unitSessionPercentage: number;
    unitSessionPercentageB2B: number;
}

export interface UpdateTimestamp {
    $date: Date;
}

export interface SalesTrafficItem {
    _id: string;
    childAsin: string;
    insertTimestamp: InsertTimestamp;
    parentAsin: string;
    salesByAsin: SalesByAsin;
    trafficByAsin: TrafficByAsin;
    updateTimestamp: UpdateTimestamp;
}

interface NoteItemStats {
    prevMin: number;
    newMin: number;
    prevMax: number;
    newMax: number;
    prevPreset: string;
    newPreset: string;
}
export interface NoteItem {
    _id?: string;
    SKU: string;
    actions: string[];
    noteText: string;
    author: string;
    insertTimestamp: Date;
    stats: NoteItemStats;
    hasData?: 0 | 1;
    productData?: SingularItem | Omit<SingularItem, "notes"> | null;
}

export interface ReplenItem {
    SKU: string;
    ASIN: string;
    Title: string;
    UPC: string;
    Supplier_SKU: string;
    Supplier_Title: string;
    Supplier_Notes: string;
    Cost: number;
    antBBShare: number;
    targetDIS: number;
    antVelocity: number;
    antStock: number;
    antAllSales: number;
    replenQty: number;
}

interface OrderSummary {
    orders: number;
    units: number;
    value: number;
}

export interface OrderSummaryByDaysData {
    "asin": string;
    "7": OrderSummary;
    "14": OrderSummary;
    "30": OrderSummary;
    "60": OrderSummary;
    "90": OrderSummary;
}
export interface OrderSummaryByDaysAggData {
    asin: string;
    d7: OrderSummary;
    d14: OrderSummary;
    d30: OrderSummary;
    d60: OrderSummary;
    d90: OrderSummary;
    d180?: OrderSummary;
    d365?: OrderSummary;
}

export interface SingularItem {
    "key": React.Key;
    "SKU": string;
    "ASIN": string;
    "Parent": string;
    "Title": string;
    "Brand": string;
    "BBShare": number;
    "Cost": number;
    "Listed Price": number;
    "Min Price": number;
    "Max Price": number;
    "Avg Sell Price": number;
    "CurrBB Price": number;
    "Profit": number;
    "MVSP": number;
    "ROI": number;
    "Days In Stock": number;
    "Stock": number;
    "Sold (7D)": number;
    "Sold (14D)": number;
    "Sold (60D)": number;
    "Sold (90D)": number;
    "Sold (30D)": number;
    "Velocity (7D)": number;
    "Velocity (30D)": number;
    "EstSales (30D)": number;
    "EstTTS": number;
    "GMROI": number;
    "Recommended Buy": number;
    "Exists in OMS": string;
    "Tags": string[];
    "Sessions": number;
    "OurSessions": number;
    "OurSessions-Sales": number;
    "Cost / Sale": number;
    "CR": number;
    "Out of Season?": boolean;
    "Issues": string[];
    "ssItem": SellerSnapItem;
    "omsItems": OMSItem[];
    "salesTrafficItems": SalesTrafficItem[];
    "Ship Later": number;
    "InventoryUnder90D": number;
    "InventoryOver90D": number;
    "InventoryOver180D": number;
    "InventoryOver270D": number;
    "skuItemCount": number;
    "skuItemList": SellerSnapItem[];
    "shippedCount": number;
    "shippingFee": number;
    "refFee": number;
    "fulfillmentFee": number;
    "notes": NoteItem[];
    "invValue": number;
    "availableInventory": number;
    "unavailableInventory": number;
    "availableInvValue": number;
    "bbShare7D": number;
    "bbShare14D": number;
    "bbShare30D": number;
    "unavailableInvValue": number;
    "hoursBelowMin": number;
    "inactiveSellers": ActiveSellerData[] | -1;
    "activeSellers": number;
    "fbaSellers": number;
    "fbmSellers": number;
    "futureProfit": number;
    "insight"?: any;
    "inboundFee": number | string;
    "freightCost": number | string;
    "storageCost": number | string;
    "invHealthItem": InventoryHealthItem;
    "isBackordered": boolean;
    "orderData": OrderSummaryByDaysData;
    [key: string]: any;
}

export interface BrandStats {
    "Brand": string;
    "Total SKUs": number;
    "Total units": number;
    "Available units": number;
    "Unavailable units": number;
    "Total sold": number;
    "Total value sold": number;
    "Inventory Value": number;
    "Available Value": number;
    "Unavailable Value": number;
    "Total profit": number;
    "Total revenue": number;
    "TTS<90": number;
    "TTS>=90": number;
    "Sell-through Rate": number;
    "Mean BB Share": number;
    "Mean CR": number;
    "Mean ROI": number;
    "Mean GMROI": number;
    "ROIC": number;
    "Brand Items": SingularItem[];
}

export interface InventoryHealthItem {
    "_id": string;
    "snapshot-date": string;
    "sku": string;
    "fnsku": string;
    "asin": string;
    "product-name": string;
    "condition": string;
    "available": string;
    "pending-removal-quantity": string;
    "inv-age-0-to-90-days": string;
    "inv-age-91-to-180-days": string;
    "inv-age-181-to-270-days": string;
    "inv-age-271-to-365-days": string;
    "inv-age-365-plus-days": string;
    "currency": string;
    "qty-to-be-charged-ltsf-9-mo": string;
    "projected-ltsf-9-mo": string;
    "qty-to-be-charged-ltsf-12-mo": string;
    "estimated-ltsf-next-charge": string;
    "units-shipped-t7": string;
    "units-shipped-t30": string;
    "units-shipped-t60": string;
    "units-shipped-t90": string;
    "alert": string;
    "your-price": string;
    "sales-price": string;
    "lowest-price-new-plus-shipping": string;
    "lowest-price-used": string;
    "recommended-action": string;
    "healthy-inventory-level": string;
    "recommended-sales-price": string;
    "recommended-sale-duration-days": string;
    "recommended-removal-quantity": string;
    "estimated-cost-savings-of-recommended-actions": string;
    "sell-through": string;
    "item-volume": string;
    "volume-unit-measurement": string;
    "storage-type": string;
    "storage-volume": string;
    "marketplace": string;
    "product-group": string;
    "sales-rank": string;
    "days-of-supply": string;
    "estimated-excess-quantity": string;
    "weeks-of-cover-t30": string;
    "weeks-of-cover-t90": string;
    "featuredoffer-price": string;
    "sales-shipped-last-7-days": string;
    "sales-shipped-last-30-days": string;
    "sales-shipped-last-60-days": string;
    "sales-shipped-last-90-days": string;
    "inv-age-0-to-30-days": string;
    "inv-age-31-to-60-days": string;
    "inv-age-61-to-90-days": string;
    "inv-age-181-to-330-days": string;
    "inv-age-331-to-365-days": string;
    "estimated-storage-cost-next-month": string;
    "inbound-quantity": string;
    "inbound-working": string;
    "inbound-shipped": string;
    "inbound-received": string;
    "no-sale-last-6-months": string;
    "reserved-quantity": string;
    "unfulfillable-quantity": string;
}

export interface SkuAsinItem {
    ASIN: string;
    SKU: string;
    FNSKU: string;
}
export interface ReplenParams {
    antBBShare: number;
    targetDIS: number;
    antVelocity: number;
    canCancel: boolean;
    desiredRoi: number;
    // suggestedBuyQTY: number,
    // antStock: number,
    // antAllSales: number,
}

export interface PectyOffer {
    sellerName: string;
    condition: string;
    price: number;
    priceShipping: number;
    priceTotal: number;
    fba: boolean;
    inventoryCount: number;
    conditionNote: string;
    ratingStar: number;
    sellerRating: string;
    prime: boolean;
    sellerId: string;
    inventoryRequestFailed: boolean;
    moq: boolean;
    moqvalue: number;
}

export interface PectyData {
    asin: string;
    itemName: string;
    brand: string;
    salesRank: number;
    totalQty: number;
    buyboxPrice: string;
    buyboxSellerName: string;
    buyboxSellerId: string;
    buyboxItemCondition: string;
    buyboxHasCoupon: boolean;
    buyboxCouponValue: string;
    totalFBA: number;
    totalFBM: number;
    maxPrice: number;
    minPrice: number;
    avgPrice: number;
    totalOffers: number;
    offersInventoryRequest: string;
    offers: PectyOffer[];
}

export interface PectyRoot {
    mgrequestid: string;
    _id: string;
    status: string;
    responsefor: string;
    allowed: number;
    dailyAllowed?: any;
    messageId?: any;
    message?: any;
    data: PectyData;
    rid: string;
    dateProcessed: number;
}

export interface CompetitivePriceData {
    asin: string;
    timestamp: string;
    competitivePrice: number | null;
    suggestedPrice: number | null;
}

export interface ActiveSellerData {
    sellerId: string;
    currPrice: number;
}

export interface ActiveSellersData {
    asin: string;
    uniqueSellersCount: number;
    activeSellersCount: number;
    FBACount: number;
    FBMCount: number;
    ourPrice: number;
    notificationsCount: number;
    lastNotificationTimestamp: string;
    inactiveSellers: ActiveSellerData[];
    listingLink: string;
}

export interface BelowMinData {
    asin: string;
    timeBelowMinPct: number;
    timeBelowMin: number;
    minPrice: number;
    currentBB: number;
    medianBB: number;
    isBelow: boolean;
    lastNotificationTimestamp: string;
}

export interface FeeData {
    SKU: string;
    ASIN: string;
    Title: string;
    PkgVol: string;
    SellPrice: string;
    StorageFee: string;
    InboundFees: string;
    OMSCost: string;
    ItemizedFreightCost: string;
    NormalFreightCost: string;
    RegularFeePerc: string;
    RefFee: string;
    FulfillmentCost: string;
    ProfitStorage: string;
    ProfitNoStorage: string;
}

export interface BuySheetItem {
    "ASIN": string | null;
    "TITLE": string | null;
    "Quantity": number | null;
    "COSTUNIT": number | null;
    "Min Price": number | null;
    "Max Price/List": number | null;
    "Shipped To": string | null;
    "SeasTag": string | null;
    "Supp?": boolean | null;
    "PURCHASED DATE": Date | null;
    "SUPPLIER": string | null;
}

export interface InvoiceData {
    _id?: string;
    InvoiceId?: string;
    Supplier_Name: string;
    Supplier_PO: string;
    Dropbox_URL: string;
    Timestamp: Date;
}

export interface InvoiceDataRenderType extends InvoiceData {
    key: React.Key;
}

export interface LedgerData {
    _id?: string;
    Supplier_Name: string;
    Supplier_PO?: string;
    Supplier_SO?: string;
    InvoiceId?: string;
    DistributorInvoiceId?: string;
    Notes?: string;
    IsCommission?: boolean;
    PaidAmount: number;
    PaidDate: Date;
    PaymentMethod: string;
    IsProcessed?: boolean;
    CreditId?: string;
    Timestamp: Date;
}

export interface LedgerDataRenderType extends LedgerData {
    key: React.Key;
}

export interface SellerSnapUpdateItem {
    sku: string;
    cost?: number;
    min_price?: number;
    max_price?: number;
    repricing?: boolean;
    reprice_config_preset: string;
    tags: string;
}

export interface SellerSnapExportData extends SellerSnapUpdateItem {
    "ASIN": string;
    "ParentASIN": string;
    "Fulfillment"?: string;
    "Supplier"?: string;
    "PurchaseDate"?: Date;
    "Source"?: string;
    "MAP"?: number;
    "Sell Price"?: number;
}

export interface SellerSnapExportDataRenderType extends SellerSnapExportData {
    key: React.Key;
}

export interface APInvoiceTrackerData {
    _id?: string;
    IssueDate: Date;
    DueDate: Date;
    Brand: string;
    Distributor: string;
    Supplier_PO: string;
    Supplier_SO?: string;
    InvoiceId?: string;
    InvoiceNotes?: string;
    OrderAmount: number;
    PaymentNotes?: string;
    PaidAmount?: number;
    PaidDate?: Date;
    IsPaid?: boolean;
    OtherNotes?: string;
    InvoiceFileURL?: string;
    IsProcessed?: boolean;
    IsCredit?: boolean;
    isNew?: boolean;
}

export interface APInvoiceTrackerDataRenderType extends APInvoiceTrackerData {
    key: React.Key;
}

export interface FreightLogRow {
    _id?: string;
    Supplier_Name: string;
    Supplier_PO?: string;
    Supplier_SO?: string;
    BolId?: string;
    Ship_Requested: Date;
    ShipDateBooked?: Date;
    ItemsNum?: number;
    TotalCost: number;
    UnitCost?: number;
    ShippedTo: string;
    Booked: boolean;
    ShipmentPlanFile?: string;
    IsProcessed?: boolean;
    deleted?: boolean;
    Timestamp: Date;
}

export interface FreightLogRowRenderType extends FreightLogRow {
    key: React.Key;
}

export interface FreightLogItemizedRow extends FreightLogRow {
    ProductId: string;
    ShipmentTracker: number;
    UnitsShipped?: number;
    UnitsBackordered?: number;
    BackorderDate?: Date;
}

export interface FreightLogItemizedRowRenderType extends FreightLogItemizedRow {
    key: React.Key;
}

export interface FreightLogItemizedEntry extends FreightLogItemizedRow {
    Supplier_Title: string;
    UPC: string;
    Supplier_SKU: string;
    Quantity: number;
}

export interface FreightLogItemizedEntryRenderType extends FreightLogItemizedEntry {
    key: React.Key;
}

export interface CurrentShipmentLogItem {
    _id?: string;
    Supplier_Names: string[];
    ShipDateBooked?: Date;
    Supplier_POs?: string[];
    Pallets?: number;
    Units?: number;
    ShipValue?: number;
    ShipTo?: string;
    Carrier?: string;
    ETA?: Date;
    Arrived?: Date;
    ShipmentPlan?: string;
    AddToFL?: boolean;
    Tracking?: string;
    Notes?: (string | ExpandedNote)[];
    Status?: CurrentShipmentStatus;
    TrackerData?: any[];
    Timestamp: Date;
}

export interface CurrentShipmentLogItemRenderType extends CurrentShipmentLogItem {
    key: React.Key;
}
export interface InquiryItem {
    _id?: string;
    Supplier_POs?: (OMSItem & {Invoices?: any[]})[];
    UnitsSold?: number;
    UnitsInStock?: number;
    ASIN: string;
    Title?: string;
    UPC?: string;
    SKU?: string;
    DropboxLink?: string;
    Submitted?: boolean;
    DateResponded?: Date;
    ResponseTime?: Date;
    Status?: string;
    Notes?: ExpandedNote[];
    Timestamp: Date;
}

export interface InquiryItemRenderType extends InquiryItem {
    key: React.Key;
}
