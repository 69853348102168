import {useQueryClient} from "@tanstack/react-query";
import {Button, Collapse, Divider, message, Modal, notification, Select, Space, Statistic, Tooltip, Typography} from "antd";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import {ColumnsType, ColumnType} from "antd/lib/table";
import dayjs from "dayjs";
import React, {useCallback, useEffect, useReducer, useState} from "react";
import ReactDragListView from "react-drag-listview";
import {SUPPLIER_PREFIX, updateProduct} from "../../services/WholesaleService";
import {KeepaItem, KeepaStockItem, WholesaleItem} from "../../types/WholesaleItem";
import {GetExpandedFilter} from "../utilities/ExpandedFilterDropdown";
import {PRESET_LOOKUP} from "../utilities/TableColumnPresets";
import {getColumnTitle} from "../utilities/TableFilterSorters";
import {ExcelDownload} from "./ExcelDownload";
import {
    CheckSmallLight,
    FindBuyQTY,
    FindEstSales,
    FindIssues,
    FindReplenParams,
    FindReturnMetrics,
    FindSales,
    FindSellPrice,
    FindSizeTier,
    FindStages,
    FindStockMetrics,
    FindWsTTS,
    RecalculateProfits,
} from "./ItemCalcs";
import SeparatedTable from "./SeparatedTable";
import "./Statistic.scss";
import WholesaleBulkActions from "./table/WholesaleBulkActions";
import {GetWholesaleColumns, WholesaleDataType, WholesaleDataUpdate, WHOLESALE_CELL_COLORS} from "./table/WholesaleColumns";
import WholesaleFilterPresets from "./table/WholesaleFilterPresets";
import {transformBBOwnerFromKeepa, WHOLESALE_DEFAULT_HIDDEN} from "./table/WholesaleUtilities";
import {copyToClipboard} from "../omega/ItemBrowser";
import {BRAND_OPPORTUNITIES, SEASONAL_TAGS} from "../../types/OmegaTypes";

const ExperimentalTable: React.FC<{
    isLoading: boolean;
    data: WholesaleItem[];
    save?: boolean;
    collection: string;
    sellersnapTimespan: string;
    setSellersnapTimespan: React.Dispatch<React.SetStateAction<string>>;
    setOmsTimespan: React.Dispatch<React.SetStateAction<number>>;
    setInvTotalTimespan: React.Dispatch<React.SetStateAction<number>>;
}> = ({isLoading, data, save = false, collection, sellersnapTimespan, setSellersnapTimespan, setOmsTimespan, setInvTotalTimespan}) => {
    const queryClient = useQueryClient();
    const [modal, contextHolder] = Modal.useModal();
    // const [shippingFeeBase, setShippingFeeBase] = useState(0.5);
    // const [shippingFeeMulti, setShippingFeeMulti] = useState(0.5);
    // const [bbMulti, setBBMulti] = useState(1);
    // const [askPriceMulti, setAskPriceMulti] = useState(0);
    const [prepDelay, setPrepDelay] = useState(14);
    const [desiredRoi, setDesiredRoi] = useState(0.25);
    const [targetDIS, setTargetDIS] = useState(-1);
    const [netTerm, setNetTerm] = useState(0);
    const [ccAccepted, setCCAccepted] = useState(false);
    const [anticipatedVelocity, setAnticipatedVelocity] = useState(1);
    const [Q4Storage, setQ4Storage] = useState(false);
    const [replenShipLater, setReplenShipLater] = useState(true);
    const [replenIncoming, setReplenIncoming] = useState(true);
    const [poEvalIncoming, setPoEvalIncoming] = useState(false);
    const [canEditCancel, setCanEditCancel] = useState(true);

    const [reviewUpload, setReviewUpload] = useState(false);

    const [selectedColumnPreset, setSelectedColumnPreset] = useState("WS_DEFAULT_PRESET");
    const [selectedColumnPresetArray, setSelectedColumnPresetArray] = useState<string[]>([]);
    const [selectedFilterPreset, setSelectedFilterPreset] = useState("default");

    const [columns, setColumns] = useState<ColumnsType<WholesaleDataType>>([]);
    const [finalColumns, setFinalColumns] = useState<ColumnsType<WholesaleDataType>>([]);
    const [filteredData, setFilteredData] = useState<number[]>([]);
    const [filteredColumns, setFilteredColumns] = useState<string[]>([]);
    const [hiddenColumns, setHiddenColumns] = useState<string[]>(WHOLESALE_DEFAULT_HIDDEN);

    const changeSellerSnap = (timespan: string) => {
        queryClient.setQueryData(
            ["wholesale", collection, save ? "Saved" : "All"],
            tableData.map((prod) => ({
                ...prod,
                replenBBMultiplier: prod.replenBBMultiplier !== prod.BBShare / 100 ? prod.replenBBMultiplier : null,
                replenProfit: null,
                replenROI: null,
                replenEstSales: null,
                replenAllSales: null,
                replenTargetDTB: null,
                replenDaysToBuy: null,
                replenToBuy: null,
                replenTTS: null,
                replenIncoming: null,
                anticipatedVelocity: null,
            }))
        );
        queryClient.invalidateQueries({queryKey: ["wholesale", collection.split("_")[0] + "_saved", save ? "Saved" : "All"]});
        setSellersnapTimespan(timespan);
    };

    function initTableData(data: WholesaleItem[]) {
        const transformedData = data?.map((el, idx, arr) => {
            const row: WholesaleDataType = {
                // BASE
                ...el,
                key: idx,
                azImg: el.Image?.split(";")[0] || "",

                // WS VARIABLES
                Profit: el.Profit ?? -1,
                cost: el.cost ?? -1,
                AskPrice: el.AskPrice ?? el.cost ?? -1,
                bbOwner: el.bbOwner ?? transformBBOwnerFromKeepa(el["Buy Box Seller"], el["Buy Box: Is FBA"]) ?? "",
                bbOwnerId: el.bbOwnerId ?? el["Buy Box Seller"]?.replace(/(3rd Party)|\(|\)/g, "") ?? "",
                offerCount: el.offerCount ?? parseInt(el["New Offer Count: Current"]?.length > 0 ? el["New Offer Count: Current"] : "-1"),
                buyBox180Avg: el.buyBox180Avg ?? -1,
                bbOOS:
                    el.bbOOS ??
                    (el["Buy Box 🚚: 90 days OOS"] && el["Buy Box 🚚: 90 days OOS"] !== "-"
                        ? parseFloat(el["Buy Box 🚚: 90 days OOS"].replace("%", ""))
                        : null) ??
                    -1,
                azOOS:
                    el.azOOS ??
                    (el["Amazon: 90 days OOS"] && el["Amazon: 90 days OOS"] !== "-"
                        ? parseFloat(el["Amazon: 90 days OOS"].replace("%", ""))
                        : null) ??
                    -1,
                bbStock: el.bbStock ?? -1,
                azStock: el.azStock ?? -1,
                sellPrice: el.sellPrice ?? -1,
                keepaRating: el.keepaRating ?? el["Reviews: Rating"] ?? -1,
                sizeMultiplier: el.sizeMultiplier ?? 1,
                colorMultiplier: el.colorMultiplier ?? 1,
                reviewMultiplier: el.reviewMultiplier ?? 1,
                bbMultiplier: el.bbMultiplier ?? -1,
                SuggestedQTY: el.SuggestedQTY ?? el.BuyQTY ?? 0,
                variationReviews: el.variationReviews ?? -1,
                variationReviewsTotal: el.variationReviewsTotal ?? -1,
                variationActive: el.variationActive ?? -1,
                variationChecked: el.variationChecked ?? el.variationReviews > -1 ?? false,
                adjustedWeight: el.adjustedWeight ?? (el.weight === -1 ? 454 : el.weight) / 454,
                editedShippingCost: el.editedShippingCost,
                recentlyEdited: false,
                editSiblings: false,
                wsTTS: el.wsTTS ?? -1,
                wsVelTTS: el.wsVelTTS ?? -1,
                wsGMROITTS: el.wsGMROITTS ?? -1,
                wsGMROI: el.wsGMROI ?? -1,
                comments: el.comments ?? [],
                SmallLightFee: el.SmallLightFee ?? -1,
                SmallLightFeeApplied: el.SmallLightFeeApplied ?? false,
                SizeTier: el.SizeTier ?? "Unknown",
                shipDimWeight: el.shipDimWeight ?? -1,
                refFeeSPAPI: el.refFeeSPAPI ?? -1,
                refFeeSPAPIBase: el.refFeeSPAPIBase ?? -1,
                isBundle: el.isBundle ?? false,
                packSize: el.packSize ?? 1,
                isRestricted: el.isRestricted ?? false,
                isRestrictedChecked: el.isRestrictedChecked ?? false,
                isHazmat: el.isHazmat ?? false,
                isHazmatChecked: el.isHazmatChecked ?? false,
                isHazmatReason: el.isHazmatReason ?? "",
                ccAccepted: el.ccAccepted ?? false,
                Q4StorageFee:
                    el.Q4StorageFee ??
                    dayjs()
                        .add(el?.prepDelay ?? 14, "day")
                        .month() >= 9
                        ? true
                        : false,
                opportunities: el.opportunities ?? "",
                KeepaMaxSold: el.KeepaMaxSold ?? -1,
                KeepaMaxSoldDate: el.KeepaMaxSoldDate ?? null,
                KeepaMaxSoldDateFinal: el.KeepaMaxSoldDateFinal ?? null,
                KeepaMaxSoldTimestamp: el.KeepaMaxSoldTimestamp ?? null,
                KeepaMaxSoldChecked: el.KeepaMaxSoldChecked ?? false,

                // RETURN
                returnRate: el.returnRate ?? 0.1,
                returnUnsellableRate: el.returnUnsellableRate ?? 0.02,

                // GLOBAL VARIABLES
                shippingFeeBase: el.shippingFeeBase ?? (el.productCode === "Shoes" ? 1.3 : 0.5),
                shippingFeeMulti: el.shippingFeeMulti ?? 0.5,
                prepFee: el.prepFee ? el.prepFee : el.Fees > 0 ? el.Fees : 5,
                prepDelay: el.prepDelay ?? 14,
                desiredRoi: el.desiredRoi ?? desiredRoi ?? 0.25,
                askPriceMultiplier: el.askPriceMultiplier ?? 0,
                targetDIS: el.targetDIS ?? targetDIS ?? -1,
                canEditCancel: el.canEditCancel ?? canEditCancel ?? true,
                netTerm: el.netTerm ?? netTerm ?? 0,
                replenShipLaterInclude: el.replenShipLaterInclude ?? replenShipLater ?? true,
                replenIncomingInclude: el.replenIncomingInclude ?? replenIncoming ?? true,
                poEvalIncomingSubtractOH: el.poEvalIncomingSubtractOH ?? poEvalIncoming ?? false,

                // REPLEN VARIABLES
                replenBBMultiplier: el.replenBBMultiplier ?? el.BBShare / 100,
                replenProfit: el.replenProfit ?? -1,
                replenROI: el.replenROI ?? -1,
                replenEstSales: el.replenEstSales ?? -1,
                replenAllSales: el.replenAllSales ?? -1,
                replenTargetDTB: el.replenTargetDTB ?? -1,
                replenDaysToBuy: el.replenDaysToBuy ?? -1,
                replenToBuy: el.replenToBuy ?? 0,
                replenTTS: el.replenTTS ?? -1,
                replenIncoming: el.replenIncoming ?? -1,
                anticipatedVelocity: el.anticipatedVelocity ?? anticipatedVelocity ?? 1,
                replenMode: el.replenMode ?? false,
            };

            // fix legacy comments
            if (!Array.isArray(row.comments) && row.comments && row.comments.length > 0) {
                row.comments = [row.comments];
            }

            if (row.bbMultiplier === -1) {
                if (row["Buy Box Seller"] === "Amazon" || (row.azOOS !== undefined && row.azOOS >= 0 && row.azOOS <= 10)) {
                    row.bbMultiplier = 0.15;
                } else if (row.currentBuyBoxPrice >= 0) {
                    if (row["Buy Box: Is FBA"] === "yes") {
                        row.bbMultiplier = 0.3;
                    } else if (row["Buy Box: Is FBA"] === "no") {
                        row.bbMultiplier = 0.5;
                    } else {
                        row.bbMultiplier = 0.5;
                    }
                } else {
                    row.bbMultiplier = 1;
                }
            }

            if (row.sellPrice === -1) {
                FindSellPrice(row);
            }

            if (!row.calcRank || row.calcRank === -1) {
                FindSales(row);
            }

            FindEstSales(row);
            [row.SizeTier, row.shipDimWeight] = FindSizeTier(row);
            row.SmallLightFee = CheckSmallLight(row);
            FindReturnMetrics(row);
            RecalculateProfits(row);
            FindBuyQTY(row);
            FindStockMetrics(row);

            if (row.AvgSellPrice > 0) {
                FindReplenParams(row);
            }

            if (row.saved === false) {
                const suggestedQTY = selectedColumnPreset.includes("REPLEN_PRESET") ? row.replenToBuy : row.SuggestedQTY;
                if (row.onHand !== undefined && row.onHand >= 0) {
                    row.BuyQTY = Math.min(row.onHand, suggestedQTY);
                } else {
                    row.BuyQTY = suggestedQTY;
                }
            }

            FindWsTTS(row);
            FindStages(row);

            return row;
        });

        transformedData.forEach((row, _, arr) => {
            FindIssues(arr, row);
        });

        return transformedData;
    }

    function handleUpdate(
        state: WholesaleDataType[],
        action: WholesaleDataUpdate | WholesaleDataType | WholesaleItem[] | WholesaleDataType[]
    ) {
        function isDataUpdate(
            action: WholesaleDataUpdate | WholesaleDataType | WholesaleItem[] | WholesaleDataType[]
        ): action is WholesaleDataUpdate {
            return (action as WholesaleDataUpdate).type !== undefined;
        }

        let row, updateType;
        if (isDataUpdate(action)) {
            row = action.data;
            updateType = action.type;
        } else {
            row = action;
            updateType = "classic";
        }

        if (updateType === "partial" || (updateType === "classic" && (!Array.isArray(row) || row.length !== state.length))) {
            const updateNewData = (newData: WholesaleDataType[], row: WholesaleDataType, updateRecentlyEdited = true) => {
                const index = newData.findIndex((item) => row.key === item.key);
                const item = newData[index];

                // Update the price-related calculations
                [row.SizeTier, row.shipDimWeight] = FindSizeTier(row);
                row.SmallLightFee = CheckSmallLight(row);
                FindReturnMetrics(row);
                RecalculateProfits(row);
                FindEstSales(row);
                FindBuyQTY(row);
                FindStockMetrics(row);

                if (row.AvgSellPrice > 0) {
                    FindReplenParams(row);
                }

                // Limit the BuyQTY to what's available
                if (row.onHand !== undefined && row.onHand >= 0 && row.BuyQTY > row.onHand) {
                    row.BuyQTY = Number(row.onHand);
                }

                row.BuyQTY = Math.floor(row.BuyQTY);

                FindWsTTS(row);
                FindStages(row);
                FindIssues(newData, row);

                // Prevent the edited item from disappearing from filters
                newData.forEach((item) => (item.recentlyEdited = false));
                row.recentlyEdited = updateRecentlyEdited;

                newData.splice(index, 1, {
                    ...item,
                    ...row,
                    editSiblings: false,
                });

                return newData;
            };

            let dataToSave: WholesaleDataType[] = [];
            let newData = [...state];
            if (!Array.isArray(row)) {
                if (row.editSiblings) {
                    const newCalcRank = row.calcRank;
                    const parent = row.parentASIN;
                    if (parent) {
                        for (const item of newData) {
                            if (item.parentASIN === parent) {
                                newData = updateNewData(newData, {...item, calcRank: newCalcRank, editSiblings: false});
                            }
                        }

                        dataToSave = newData;
                    } else {
                        dataToSave = updateNewData(newData, row);
                    }
                } else {
                    dataToSave = updateNewData(newData, row);
                }
            } else {
                for (const item of row) {
                    newData = updateNewData(newData, item as WholesaleDataType, false);
                }

                dataToSave = newData;
            }

            return dataToSave;
        } else {
            const transformedData = initTableData(row as WholesaleItem[]);
            return transformedData;
        }
    }

    const [tableData, updateTableData] = useReducer(handleUpdate, data, initTableData);

    useEffect(() => {
        // setBBMulti(median(data.map((row) => row.bbMultiplier).filter((multi) => multi >= 0)) ?? 1);
        // setShippingFeeBase(data.find((row) => row.shippingFeeBase)?.shippingFeeBase ?? 0.5);
        // setShippingFeeMulti(data.find((row) => row.shippingFeeMulti)?.shippingFeeMulti ?? 0.5);
        setPrepDelay(data.find((row) => row.prepDelay)?.prepDelay ?? 14);
        setDesiredRoi(data.find((row) => row.desiredRoi)?.desiredRoi ?? 0.25);
        // setAskPriceMulti(data.find((row) => row.askPriceMultiplier)?.askPriceMultiplier ?? 0);
        setTargetDIS(data.find((row) => row.targetDIS)?.targetDIS ?? -1);
        setCanEditCancel(data.find((row) => row.canEditCancel !== undefined)?.canEditCancel ?? true);
        setNetTerm(data.find((row) => row.netTerm)?.netTerm ?? 0);
        setCCAccepted(data.find((row) => row.ccAccepted)?.ccAccepted ?? false);
        setQ4Storage(
            data.find((row) => row.Q4StorageFee !== undefined)?.Q4StorageFee ??
                dayjs()
                    .add(data.find((row) => row.prepDelay)?.prepDelay ?? 14, "day")
                    .month() >= 9
                ? true
                : false
        );
        setReplenShipLater(data.find((row) => row.replenShipLaterInclude !== undefined)?.replenShipLaterInclude ?? true);
        setReplenIncoming(data.find((row) => row.replenIncomingInclude !== undefined)?.replenIncomingInclude ?? true);
        setPoEvalIncoming(data.find((row) => row.poEvalIncomingSubtractOH !== undefined)?.poEvalIncomingSubtractOH ?? false);

        updateTableData({
            type: "full",
            data: data,
        });
    }, [data]);

    const handleBuyQTYPresetChange = useCallback(() => {
        const localTableData = [...tableData];
        const replenMode = selectedColumnPreset.includes("REPLEN_PRESET") ? true : false;

        localTableData
            .filter((prod) => !prod.saved)
            .forEach((item) => {
                const suggestedQTY = selectedColumnPreset.includes("REPLEN_PRESET") ? item.replenToBuy : item.SuggestedQTY;
                if (item.onHand !== undefined && item.onHand >= 0) {
                    item.BuyQTY = Math.min(item.onHand, suggestedQTY);
                } else {
                    item.BuyQTY = suggestedQTY;
                }
            });

        updateTableData(localTableData);
    }, [selectedColumnPreset, tableData]);

    // const handleFeeEdit = useCallback(
    //     (value: string, target: string) => {
    //         console.log(target, value);

    //         if (Number.isNaN(parseFloat(value))) {
    //             return;
    //         }

    //         const setter = target === "multi" ? setShippingFeeMulti : setShippingFeeBase;
    //         const getter = target === "multi" ? "shippingFeeMulti" : "shippingFeeBase";
    //         const newFee = parseFloat(value);
    //         setter(newFee);

    //         const localTableData = [...tableData];

    //         localTableData.forEach((item) => {
    //             item[getter] = newFee;
    //         });

    //         updateTableData(localTableData);
    //     },
    //     [tableData]
    // );

    // const handleAskPriceMultiEdit = useCallback(
    //     (value: string) => {
    //         if (Number.isNaN(parseFloat(value))) {
    //             return;
    //         }

    //         const newAskPriceMulti = parseFloat(value) / 100;

    //         setAskPriceMulti(newAskPriceMulti);
    //         const localTableData = [...tableData];

    //         localTableData.forEach((item) => {
    //             item.AskPrice = item.cost * item.packSize * (1 - newAskPriceMulti);
    //             item.askPriceMultiplier = newAskPriceMulti;
    //         });

    //         updateTableData(localTableData);
    //     },
    //     [tableData]
    // );

    // const handleBBMultiEdit = useCallback(
    //     (value: string) => {
    //         if (Number.isNaN(parseInt(value))) {
    //             return;
    //         }

    //         const newBBMulti = parseInt(value) / 100;

    //         setBBMulti(newBBMulti);
    //         const localTableData = [...tableData];

    //         localTableData.forEach((item) => {
    //             item.bbMultiplier = newBBMulti;
    //         });

    //         updateTableData(localTableData);
    //     },
    //     [tableData]
    // );

    const handlePrepDelayEdit = useCallback(
        (value: string) => {
            if (Number.isNaN(parseInt(value))) {
                return;
            }

            const newPrepDelay = parseInt(value);
            setPrepDelay(newPrepDelay);
            const isQ4Storage = dayjs().add(newPrepDelay, "day").month() >= 9 ? true : false;
            setQ4Storage(isQ4Storage);
            const localTableData = [...tableData];

            localTableData.forEach((item) => {
                item.prepDelay = newPrepDelay;
                item.Q4StorageFee = isQ4Storage;
            });

            updateTableData(localTableData);
        },
        [tableData]
    );

    const handleTargetDISEdit = useCallback(
        (value: string) => {
            if (Number.isNaN(parseInt(value))) {
                return;
            }

            const newTargetDIS = parseInt(value);

            setTargetDIS(newTargetDIS);
            const localTableData = [...tableData];

            localTableData.forEach((item) => {
                item.targetDIS = newTargetDIS;
            });

            updateTableData(localTableData);
        },
        [tableData]
    );

    const handleAntVelocityEdit = useCallback(
        (value: string) => {
            if (Number.isNaN(parseInt(value))) {
                return;
            }

            const newAntVelocity = parseInt(value) / 100;

            setAnticipatedVelocity(newAntVelocity);
            const localTableData = [...tableData];

            localTableData.forEach((item) => {
                item.anticipatedVelocity = newAntVelocity;
            });

            updateTableData(localTableData);
        },
        [tableData]
    );

    const handletNetTermEdit = useCallback(
        (value: string) => {
            if (Number.isNaN(parseInt(value))) {
                return;
            }

            const newNetTerm = parseInt(value);

            setNetTerm(newNetTerm);
            const localTableData = [...tableData];

            localTableData.forEach((item) => {
                item.netTerm = newNetTerm;
            });

            updateTableData(localTableData);
        },
        [tableData]
    );

    const handleDesiredRoiEdit = useCallback(
        (value: string) => {
            if (Number.isNaN(parseInt(value))) {
                return;
            }

            const newDesiredRoi = parseFloat(value) / 100;

            setDesiredRoi(newDesiredRoi);
            const localTableData = [...tableData];

            localTableData.forEach((item) => {
                item.desiredRoi = newDesiredRoi;
            });

            updateTableData(localTableData);
        },
        [tableData]
    );

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleCanEditCancelEdit = useCallback(
        (value: string) => {
            const newVal = value === "YES" ? true : false;
            setCanEditCancel(newVal);
            const localTableData = [...tableData];

            localTableData.forEach((item) => {
                item.canEditCancel = newVal;
            });

            updateTableData(localTableData);
        },
        [tableData]
    );

    const handleCCAcceptedEdit = useCallback(
        (value: string) => {
            const newVal = value === "YES" ? true : false;
            setCCAccepted(newVal);
            const localTableData = [...tableData];

            localTableData.forEach((item) => {
                item.ccAccepted = newVal;
            });

            updateTableData(localTableData);
        },
        [tableData]
    );

    const handleQ4StorageEdit = useCallback(
        (value: string) => {
            const newVal = value === "YES" ? true : false;
            setQ4Storage(newVal);
            const localTableData = [...tableData];

            localTableData.forEach((item) => {
                item.Q4StorageFee = newVal;
            });

            updateTableData(localTableData);
        },
        [tableData]
    );

    const handleReplenShipLaterEdit = useCallback(
        (value: string) => {
            const newVal = value === "YES" ? true : false;
            setReplenShipLater(newVal);
            const localTableData = [...tableData];

            localTableData.forEach((item) => {
                item.replenShipLaterInclude = newVal;
            });

            updateTableData(localTableData);
        },
        [tableData]
    );

    const handleReplenIncomingEdit = useCallback(
        (value: string) => {
            const newVal = value === "YES" ? true : false;
            setReplenIncoming(newVal);
            const localTableData = [...tableData];

            localTableData.forEach((item) => {
                item.replenIncomingInclude = newVal;
            });

            updateTableData(localTableData);
        },
        [tableData]
    );

    const handlePoEvalIncomingEdit = useCallback(
        (value: string) => {
            const newVal = value === "YES" ? true : false;
            setPoEvalIncoming(newVal);
            const localTableData = [...tableData];

            localTableData.forEach((item) => {
                item.poEvalIncomingSubtractOH = newVal;
                // item.IncomingQuantity30D = item.poEvalIncomingSubtractOH ? item.IncomingQuantity30D - item.onHand : item.IncomingQuantity30D + item.onHand
            });

            updateTableData(localTableData);
        },
        [tableData]
    );

    // EXTENSION HANDLING
    useEffect(() => {
        const handleKeepaData = (data: KeepaItem[]) => {
            let totalReviews = 0;
            let totalRatings = 0;
            let activeVars = 0;
            let totalRR = 0;

            data.forEach((item) => {
                if (item.Offers > 0) {
                    activeVars++;
                    totalRatings += item.Ratings;
                    totalReviews += item.Reviews;
                }
            });

            totalRR = data[0].totalRR ?? totalRatings + totalReviews;

            const parsedData = data.map((item) => ({
                ...item,
                totalMultiplier: item.totalMultiplier ?? (item.Reviews + item.Ratings) / (totalRR || 1),
                RR: item.RR ?? item.Reviews + item.Ratings,
            }));

            const parsedMap = Object.fromEntries(parsedData.map((item) => [item.ASIN, item]));

            const updateItems: WholesaleItem[] = [];
            const localTableData = [...tableData];
            let triedToUpdateSaved = false;
            localTableData.forEach((item) => {
                if (parsedMap[item.ASIN] && item.saved === false) {
                    if (parsedMap[item.ASIN].Offers > 0 && totalRR > 50) {
                        item.reviewMultiplier = parsedMap[item.ASIN].totalMultiplier;

                        // AFTER CLOTHING DISTRIBUTION IS IMPROVED
                        // try {
                        //     const newReviewMultiplier = parsedMap[item.ASIN].estSales! / (parsedMap[item.ASIN].bbMultiplier! * parsedMap[item.ASIN].estSalesTotal!) || 1
                        //     item.reviewMultiplier = newReviewMultiplier;
                        // } catch (ex) {
                        //     item.reviewMultiplier = parsedMap[item.ASIN].totalMultiplier;
                        // }
                    }

                    if (parsedMap[item.ASIN]?.stats?.minInInterval?.[18]?.slice(-1).pop() > -1) {
                        item.minBB = parsedMap[item.ASIN]?.stats?.minInInterval?.[18].slice(-1).pop() / 100;
                    }

                    if (parsedMap[item.ASIN]?.stats?.maxInInterval?.[18]?.slice(-1).pop() > -1) {
                        item.maxBB = parsedMap[item.ASIN]?.stats?.maxInInterval?.[18].slice(-1).pop() / 100;
                    }

                    item.calcRank = parsedMap[item.ASIN].calcRank !== undefined ? parsedMap[item.ASIN].calcRank! : item.calcRank;
                    item.variationReviewsTotal = totalRR;
                    item.activeOffers = parsedMap[item.ASIN].Offers;
                    item.variationActive = activeVars;
                    item.variationReviews = parsedMap[item.ASIN].RR;
                    item.variationChecked = true;
                    // item.rootCategory = parsedMap[item.ASIN].rootCategory !== undefined ? parsedMap[item.ASIN].rootCategory! : item.rootCategory;
                    console.log(item);
                    updateItems.push(item);
                } else if (item.saved && triedToUpdateSaved === false) {
                    triedToUpdateSaved = true;
                }
            });

            if (triedToUpdateSaved) {
                message.info(`You've tried to update values of at least 1 saved item - those are skipped!`);
            }

            updateTableData(localTableData);
            updateProduct(updateItems);
        };

        const handleStockData = (data: KeepaStockItem) => {
            const getTimeFromKeepa = (keepaTime: number): Date => {
                return new Date((keepaTime + 21564000) * 60000);
            };

            const handleStockCSV = (stockData: number[]): [number, Date] => {
                let freshStock: number = -1;
                let freshTimestamp: Date = new Date();
                let foundError = false;

                for (let i = 0; i < stockData.length; i += 2) {
                    const [timestamp, stock] = [getTimeFromKeepa(stockData[i]), stockData[i + 1]];

                    if (stock === -1) {
                        foundError = true;
                    }

                    console.log(timestamp, dayjs().diff(dayjs(timestamp), "days"), stock);
                    if (dayjs().diff(dayjs(timestamp), "days") < 7) {
                        freshStock = stock;
                        freshTimestamp = timestamp;
                    }
                }

                if (foundError) message.error("Stock data might be wrong - error encountered!");

                return [freshStock, freshTimestamp];
            };

            const liveOffersOrder = data.liveOffersOrder;
            const lastUpdate = getTimeFromKeepa(data.lastUpdate);
            message.success(`Stock data grabbed! Last Keepa update: ${lastUpdate.toLocaleString()}`);

            if (liveOffersOrder) {
                const offers = data.offers;
                const existingOffers = liveOffersOrder.map((offerIdx) => offers[offerIdx]).filter((offer) => offer.condition === 1); // NEW
                const transformedOffers = existingOffers.map((offer) => {
                    const [freshStock, freshTimestamp] = handleStockCSV(offer.stockCSV);
                    const offerPrice =
                        offer.offerCSV?.length > 0
                            ? (offer.offerCSV[offer.offerCSV.length - 2] + offer.offerCSV[offer.offerCSV.length - 1]) / 100
                            : -1;
                    const sellerId = offer.sellerId;
                    const isAmazon = offer.isAmazon;
                    const isFBA = offer.isFBA;

                    return {
                        freshTimestamp,
                        freshStock,
                        offerPrice,
                        sellerId,
                        isAmazon,
                        isFBA,
                    };
                });

                const localTableData = [...tableData];
                const updateItems: WholesaleItem[] = [];
                const localItems = localTableData.filter((prod) => prod.ASIN === data.asin);

                localItems.forEach((item) => {
                    item.StockData = transformedOffers;
                    FindStockMetrics(item);
                    updateItems.push(item);
                });

                updateTableData(localTableData);
                updateProduct(updateItems);
                console.log(transformedOffers);
            } else {
                message.error(`There are (supposedly) no live offers for this product!`);
            }
        };

        const handleWholesaleMessage = (event: MessageEvent) => {
            const eventMessage = event.data;
            if (eventMessage.action === "extracted_keepa_data_for_site") {
                console.log("Received Data Message from Extension!", eventMessage);
                console.log("Extension variation data", eventMessage.keepaData);
                if (eventMessage.keepaData?.length > 0) {
                    message.success("Variation data grabbed!");
                    handleKeepaData(eventMessage.keepaData);
                } else {
                    message.error(`Received empty variation data! Check Keepa's tab and retry if needed.`);
                }
            } else if (eventMessage.action === "extracted_stock_keepa_data_for_site") {
                console.log("Received Data Message from Extension!", eventMessage);
                console.log("Extension stock data", eventMessage.keepaData);
                if (eventMessage.keepaData?.offersSuccessful) {
                    handleStockData(eventMessage.keepaData);
                } else {
                    message.error(`Stock data failed to grab! Check Keepa's tab and retry if needed.`);
                }
            } else if (eventMessage.action === "extract_started") {
                message.info("Variation data is being grabbed!");
            } else if (eventMessage.action === "stock_extract_started") {
                message.info("Stock data is being grabbed!");
            } else if (eventMessage.action === "extract_login_error_for_site") {
                message.error(eventMessage.message);
            }
        };

        console.log("Setting listener for messages!");
        window.addEventListener("message", handleWholesaleMessage);

        // cleanup this component
        return () => {
            console.log("Removing listener for messages!");
            window.removeEventListener("message", handleWholesaleMessage);
        };
    }, [tableData]);

    useEffect(() => {
        const localColumns: ColumnsType<WholesaleDataType> = GetWholesaleColumns(updateTableData, modal);

        // Supplier (custom) columns from the WS file will go in the space between Keepa and Comments, so we're adding them here
        if (data.length > 0) {
            const exactExpandedFilterList = ["Supplier_Encounter"];
            const supplierColumnNames = Object.keys(data[0]).filter((col) => col.includes(SUPPLIER_PREFIX));
            const supplierColumns: ColumnsType<WholesaleDataType> = supplierColumnNames.map((colName) => ({
                title: () => colName.split("_")[1],
                key: colName,
                dataIndex: colName,
                width: colName.split("_")[1].length === 3 ? "65px" : "100px",
                sorter: {
                    compare: (a, b) => (a[colName] > b[colName] ? 1 : a[colName] === b[colName] ? 0 : -1),
                    multiple: 3,
                },
                ...GetExpandedFilter([], colName, undefined, exactExpandedFilterList.includes(colName)),
                render: (val) =>
                    typeof val === "string" && /http(s?):\/\//.test(val) ? (
                        <a target="_blank" rel="noopener noreferrer" href={val}>
                            {val}
                        </a>
                    ) : (
                        val
                    ),
            }));

            setHiddenColumns((hiddenColumns) => hiddenColumns.concat(supplierColumnNames.map((colName) => colName)));
            localColumns.splice(-2, 0, ...supplierColumns);
        }

        if (selectedColumnPreset !== "default" || selectedColumnPresetArray.length > 0) {
            const columnsToShow: string[] =
                // @ts-ignore
                selectedColumnPresetArray.length > 0 ? selectedColumnPresetArray : PRESET_LOOKUP[selectedColumnPreset];
            const actualColumnsToShow = columnsToShow.filter((colToShow) =>
                localColumns.map((column) => getColumnTitle(column)).includes(colToShow)
            );
            setHiddenColumns(
                localColumns.map((column) => getColumnTitle(column)).filter((column) => !actualColumnsToShow.includes(column))
            );

            const asinColumn = localColumns.find((col) => getColumnTitle(col) === "ASIN")!;
            const saveColumn = localColumns.find((col) => getColumnTitle(col) === "Save")!;
            const keepaColumn = localColumns.find((col) => getColumnTitle(col) === "Keepa")!;

            const desiredOrder = actualColumnsToShow
                .map((colTitle) => {
                    const desiredCol = localColumns.find((col) => getColumnTitle(col) === colTitle)!;
                    return desiredCol;
                })
                .filter((col) => !["Save", "ASIN", "Keepa"].includes(getColumnTitle(col)));

            const undesiredOrder = localColumns
                .filter((col) => !actualColumnsToShow.includes(getColumnTitle(col)))
                .filter((col) => !["Save", "ASIN", "Keepa"].includes(getColumnTitle(col)));

            setColumns([asinColumn, ...desiredOrder, ...undesiredOrder, keepaColumn, saveColumn]);
        } else {
            setColumns(localColumns);
        }
    }, [selectedColumnPreset, selectedColumnPresetArray, data, modal]);

    useEffect(() => {
        const editableColumns = ["AskPrice", "SellPrice", "BuyQTY", "MinPrice", "MaxPrice", "Seasonal Tags", "Opportunities", "PackSize"];
        columns.forEach((column: ColumnType<WholesaleDataType>) => {
            if (editableColumns.includes(column.title as string)) {
                if (column.title === "SellPrice") {
                    column.onCell = (record: WholesaleDataType) => {
                        const style = {backgroundColor: "transparent"};

                        if (record.currentBuyBoxPrice > 0) {
                            style.backgroundColor = WHOLESALE_CELL_COLORS.GREEN;
                        }

                        if (record["Buy Box Seller"] === "Amazon") {
                            style.backgroundColor = WHOLESALE_CELL_COLORS.YELLOW;
                        }

                        if (record.currentBuyBoxPrice <= 0) {
                            style.backgroundColor = WHOLESALE_CELL_COLORS.BLUE;
                        }

                        return {
                            record,
                            dataIndex: column.dataIndex,
                            title: column.title as string,
                            editable: true,
                            handleSave: (record: WholesaleDataType) => updateTableData(record),
                            style,
                        };
                    };
                } else if (column.title === "Seasonal Tags" || column.title === "Opportunities") {
                    column.onCell = (record: WholesaleDataType) => ({
                        record,
                        dataIndex: column.dataIndex,
                        title: column.title as string,
                        inputType: "seasonal tags",
                        inputAllowedOptions: column.title === "Seasonal Tags" ? SEASONAL_TAGS : BRAND_OPPORTUNITIES,
                        editable: true,
                        handleSave: (record: WholesaleDataType) => updateTableData(record),
                    });
                } else if (column.title === "PackSize") {
                    column.onCell = (record: WholesaleDataType) => ({
                        record,
                        dataIndex: column.dataIndex,
                        title: column.title as string,
                        inputType: "int-positive",
                        editable: true,
                        handleSave: (record: WholesaleDataType) =>
                            updateTableData({...record, AskPrice: record.cost * record.packSize * (1 - record.askPriceMultiplier)}),
                    });
                } else {
                    column.onCell = (record: WholesaleDataType) => ({
                        record,
                        dataIndex: column.dataIndex,
                        title: column.title as string,
                        editable: true,
                        handleSave: (record: WholesaleDataType) => updateTableData(record),
                    });
                }
            }
        });

        setFinalColumns(columns);
    }, [columns]);

    const dragProps = {
        onDragEnd(fromIndex: any, toIndex: any) {
            const localColumns: any = [...(columns as any)];
            const item = localColumns.splice(fromIndex, 1)[0];
            localColumns.splice(toIndex, 0, item);
            setColumns(localColumns);
        },
        nodeSelector: "button",
    };

    // console.log('Re-rendering the table view', tableData)

    return (
        <>
            {contextHolder}
            <Collapse defaultActiveKey={["2"]}>
                <CollapsePanel key={1} header={"Columns"}>
                    <ReactDragListView.DragColumn {...dragProps}>
                        <div style={{display: "flex", justifyContent: "space-around", flexWrap: "wrap", flexDirection: "row"}}>
                            {columns.map((column: ColumnType<WholesaleDataType>) => {
                                if (hiddenColumns.includes(getColumnTitle(column))) {
                                    return (
                                        <Button
                                            style={{marginBottom: "4px", marginRight: "2px"}}
                                            size="small"
                                            key={getColumnTitle(column)}
                                            type="dashed"
                                            disabled={filteredColumns.includes(column.dataIndex! as string)}
                                            onClick={() =>
                                                setHiddenColumns((hidden) => hidden.filter((col) => col !== getColumnTitle(column)))
                                            }
                                        >
                                            {getColumnTitle(column)}
                                        </Button>
                                    );
                                } else {
                                    return (
                                        <Button
                                            style={{marginBottom: "4px", marginRight: "2px"}}
                                            size="small"
                                            key={getColumnTitle(column)}
                                            type="primary"
                                            disabled={filteredColumns.includes(column.dataIndex! as string)}
                                            onClick={() => setHiddenColumns((hidden) => hidden.concat([getColumnTitle(column)]))}
                                        >
                                            {getColumnTitle(column)}
                                        </Button>
                                    );
                                }
                            })}
                        </div>
                    </ReactDragListView.DragColumn>
                </CollapsePanel>
                <CollapsePanel key={2} header={"Settings"}>
                    <Space align="center" style={{justifyContent: "space-between", width: "100%", overflow: "auto"}}>
                        <Space>
                            {/* <Statistic
                                title="Shipping Fee Multi:"
                                value={shippingFeeMulti.toFixed(2)}
                                formatter={(val) => (
                                    <Typography.Text
                                        className="editable-stat"
                                        editable={{
                                            icon: false,
                                            tooltip: `Set the shipping fee multiplier - skips saved items`,
                                            onChange: (val) => handleFeeEdit(val, "multi"),
                                            onEnd: () => handleFeeEdit("", "multi"),
                                            onCancel: () => handleFeeEdit("", "multi"),
                                        }}
                                    >{`$${val} / lb`}</Typography.Text>
                                )}
                            />
                            <Divider type="vertical" />
                            <Statistic
                                title="Shipping Fee Base:"
                                value={shippingFeeBase.toFixed(2)}
                                formatter={(val) => (
                                    <Typography.Text
                                        className="editable-stat"
                                        editable={{
                                            icon: false,
                                            tooltip: `Set the shipping fee flat addition - skips saved items`,
                                            onChange: (val) => handleFeeEdit(val, "base"),
                                            onEnd: () => handleFeeEdit("", "base"),
                                            onCancel: () => handleFeeEdit("", "base"),
                                        }}
                                    >{`$${val}`}</Typography.Text>
                                )}
                            />
                            <Divider type="vertical" /> */}
                            {/* <Statistic
                                title="BB Share Multi:"
                                value={(bbMulti * 100).toFixed(0)}
                                formatter={(val) => (
                                    <Typography.Text
                                        className="editable-stat"
                                        editable={{
                                            icon: false,
                                            tooltip: `Set the BB share multiplier - skips saved items`,
                                            onChange: (val) => handleBBMultiEdit(val),
                                            onEnd: () => handleBBMultiEdit(""),
                                            onCancel: () => handleBBMultiEdit(""),
                                        }}
                                    >{`${val}%`}</Typography.Text>
                                )}
                            />
                            <Divider type="vertical" /> */}
                            {/* <Statistic
                                title="Cost Discount:"
                                value={(askPriceMulti * 100).toFixed(2)}
                                formatter={(val) => (
                                    <Typography.Text
                                        className="editable-stat"
                                        editable={{
                                            icon: false,
                                            tooltip: `Set the Cost Discount and update AskPrice accordingly - skips saved items`,
                                            onChange: (val) => handleAskPriceMultiEdit(val),
                                            onEnd: () => handleAskPriceMultiEdit(""),
                                            onCancel: () => handleAskPriceMultiEdit(""),
                                        }}
                                    >{`${val}%`}</Typography.Text>
                                )}
                            />
                            <Divider type="vertical" /> */}
                            <Statistic
                                title="Prep Delay:"
                                value={prepDelay.toFixed(0)}
                                formatter={(val) => (
                                    <Typography.Text
                                        className="editable-stat"
                                        editable={{
                                            icon: false,
                                            tooltip: `Set the Prep Delay (Handling Time)`,
                                            onChange: (val) => handlePrepDelayEdit(val),
                                            onEnd: () => handlePrepDelayEdit(""),
                                            onCancel: () => handlePrepDelayEdit(""),
                                        }}
                                    >{`${val}`}</Typography.Text>
                                )}
                            />
                            <Divider type="vertical" />
                            <Statistic
                                title="Desired ROI:"
                                value={(desiredRoi * 100).toFixed(0)}
                                formatter={(val) => (
                                    <Typography.Text
                                        className="editable-stat"
                                        editable={{
                                            icon: false,
                                            tooltip: `Set the Desired ROI`,
                                            onChange: (val) => handleDesiredRoiEdit(val),
                                            onEnd: () => handleDesiredRoiEdit(""),
                                            onCancel: () => handleDesiredRoiEdit(""),
                                        }}
                                    >{`${val}%`}</Typography.Text>
                                )}
                            />
                            <Divider type="vertical" />
                            <Statistic
                                title="Target DIS:"
                                value={targetDIS.toFixed(0)}
                                formatter={(val) => (
                                    <Typography.Text
                                        className="editable-stat"
                                        editable={{
                                            icon: false,
                                            tooltip: `Set the target DIS (-1 for default ROI-based system)`,
                                            onChange: (val) => handleTargetDISEdit(val),
                                            onEnd: () => handleTargetDISEdit(""),
                                            onCancel: () => handleTargetDISEdit(""),
                                        }}
                                    >{`${+val >= 0 ? val : `ROI-based`}`}</Typography.Text>
                                )}
                            />
                            <Divider type="vertical" />
                            <Statistic
                                title="Net Term:"
                                value={netTerm.toFixed(0)}
                                formatter={(val) => (
                                    <Typography.Text
                                        className="editable-stat"
                                        editable={{
                                            icon: false,
                                            tooltip: `Set the net term for this purchase`,
                                            onChange: (val) => handletNetTermEdit(val),
                                            onEnd: () => handletNetTermEdit(""),
                                            onCancel: () => handletNetTermEdit(""),
                                        }}
                                    >{`${val}`}</Typography.Text>
                                )}
                            />
                            <Divider type="vertical" />
                            <Statistic
                                title={
                                    <Tooltip title="Whether to apply Q4 storage fees when calculating Profit metrics">CC Accepted</Tooltip>
                                }
                                value={ccAccepted ? "YES" : "NO"}
                                formatter={(val) => (
                                    <Select
                                        size={"small"}
                                        bordered={false}
                                        style={{width: "65px", fontSize: "1.4vh"}}
                                        value={ccAccepted ? "YES" : "NO"}
                                        onChange={(val) => handleCCAcceptedEdit(val)}
                                    >
                                        <Select.Option value={`YES`}>YES</Select.Option>
                                        <Select.Option value={`NO`}>NO</Select.Option>
                                    </Select>
                                )}
                            />
                            <Divider type="vertical" />
                            <Tooltip title="Whether to apply Q4 storage fees when calculating Profit metrics">
                                <Statistic
                                    title="Q4 Storage Fees:"
                                    value={Q4Storage ? "YES" : "NO"}
                                    formatter={(val) => (
                                        <Select
                                            size={"small"}
                                            bordered={false}
                                            style={{width: "65px", fontSize: "1.4vh"}}
                                            value={Q4Storage ? "YES" : "NO"}
                                            onChange={(val) => handleQ4StorageEdit(val)}
                                        >
                                            <Select.Option value={`YES`}>YES</Select.Option>
                                            <Select.Option value={`NO`}>NO</Select.Option>
                                        </Select>
                                    )}
                                />
                            </Tooltip>
                            {/* <Divider type="vertical" />
                            <Statistic title="Can edit/cancel?:" value={canEditCancel ? 'YES' : 'NO'} 
                                formatter={(val) => 
                                <Select size={"small"} 
                                    bordered={false} 
                                    style={{ width: '65px' }} 
                                    value={canEditCancel ? 'YES' : 'NO'} 
                                    onChange={(val) => handleCanEditCancelEdit(val)}
                                >
                                    <Select.Option value={`YES`}>YES</Select.Option>
                                    <Select.Option value={`NO`}>NO</Select.Option>
                                </Select>
                            } /> */}

                            <Divider type="vertical" />
                            <Statistic
                                title="SS Timeframe (days):"
                                formatter={() => (
                                    <Select
                                        value={sellersnapTimespan}
                                        size={"small"}
                                        bordered={false}
                                        style={{width: "65px", fontSize: "1.4vh"}}
                                        className="editable-stat"
                                        onChange={(val) => changeSellerSnap(val)}
                                    >
                                        <Select.Option className="editable-stat" value={""}>
                                            7
                                        </Select.Option>
                                        <Select.Option className="editable-stat" value={"true"}>
                                            30
                                        </Select.Option>
                                        <Select.Option className="editable-stat" value={"sixty"}>
                                            60
                                        </Select.Option>
                                        <Select.Option className="editable-stat" value={"ninety"}>
                                            90
                                        </Select.Option>
                                    </Select>
                                )}
                            />
                            <Divider type="vertical" />
                            <Statistic
                                title="OMS Timeframe (days):"
                                formatter={() => (
                                    <Select
                                        size={"small"}
                                        defaultValue={90}
                                        bordered={false}
                                        style={{width: "90px", fontSize: "1.4vh"}}
                                        className="editable-stat"
                                        onChange={(val) => setOmsTimespan(val)}
                                    >
                                        <Select.Option className="editable-stat" value={30}>
                                            30
                                        </Select.Option>
                                        <Select.Option className="editable-stat" value={60}>
                                            60
                                        </Select.Option>
                                        <Select.Option className="editable-stat" value={90}>
                                            90
                                        </Select.Option>
                                        <Select.Option className="editable-stat" value={180}>
                                            180
                                        </Select.Option>
                                        <Select.Option className="editable-stat" value={365}>
                                            365
                                        </Select.Option>
                                        <Select.Option className="editable-stat" value={0}>
                                            All-time
                                        </Select.Option>
                                    </Select>
                                )}
                            />
                            <Divider type="vertical" />
                            <Statistic
                                title="IncAll Timeframe (days):"
                                formatter={() => (
                                    <Select
                                        size={"small"}
                                        defaultValue={0}
                                        bordered={false}
                                        style={{width: "90px", fontSize: "1.4vh"}}
                                        className="editable-stat"
                                        onChange={(val) => setInvTotalTimespan(val)}
                                    >
                                        <Select.Option className="editable-stat" value={30}>
                                            30
                                        </Select.Option>
                                        <Select.Option className="editable-stat" value={60}>
                                            60
                                        </Select.Option>
                                        <Select.Option className="editable-stat" value={90}>
                                            90
                                        </Select.Option>
                                        <Select.Option className="editable-stat" value={180}>
                                            180
                                        </Select.Option>
                                        <Select.Option className="editable-stat" value={365}>
                                            365
                                        </Select.Option>
                                        <Select.Option className="editable-stat" value={0}>
                                            All-time
                                        </Select.Option>
                                    </Select>
                                )}
                            />
                            {selectedColumnPreset.includes("REPLEN_PRESET") && (
                                <>
                                    <Divider type="vertical" />
                                    <Statistic
                                        title="Ant. Velocity:"
                                        value={(anticipatedVelocity * 100).toFixed(0)}
                                        formatter={(val) => (
                                            <Typography.Text
                                                className="editable-stat"
                                                editable={{
                                                    icon: false,
                                                    tooltip: `Set the anticipated velocity for all items`,
                                                    onChange: (val) => handleAntVelocityEdit(val),
                                                    onEnd: () => handleAntVelocityEdit(""),
                                                    onCancel: () => handleAntVelocityEdit(""),
                                                }}
                                            >{`${val}%`}</Typography.Text>
                                        )}
                                    />

                                    <Divider type="vertical" />
                                    <Tooltip title="Whether ShipLater quantity should influence ReplenQTY calculations">
                                        <Statistic
                                            title="SL in ReplenQTY:"
                                            value={replenShipLater ? "YES" : "NO"}
                                            formatter={(val) => (
                                                <Select
                                                    size={"small"}
                                                    bordered={false}
                                                    style={{width: "65px", fontSize: "1.4vh"}}
                                                    value={replenShipLater ? "YES" : "NO"}
                                                    onChange={(val) => handleReplenShipLaterEdit(val)}
                                                >
                                                    <Select.Option value={`YES`}>YES</Select.Option>
                                                    <Select.Option value={`NO`}>NO</Select.Option>
                                                </Select>
                                            )}
                                        />
                                    </Tooltip>
                                    <Divider type="vertical" />
                                    <Tooltip title="Whether Incoming quantity should influence ReplenQTY calculations">
                                        <Statistic
                                            title="Inc in ReplenQTY:"
                                            value={replenIncoming ? "YES" : "NO"}
                                            formatter={(val) => (
                                                <Select
                                                    size={"small"}
                                                    bordered={false}
                                                    style={{width: "65px", fontSize: "1.4vh"}}
                                                    value={replenIncoming ? "YES" : "NO"}
                                                    onChange={(val) => handleReplenIncomingEdit(val)}
                                                >
                                                    <Select.Option value={`YES`}>YES</Select.Option>
                                                    <Select.Option value={`NO`}>NO</Select.Option>
                                                </Select>
                                            )}
                                        />
                                    </Tooltip>
                                    <Divider type="vertical" />
                                    <Tooltip title="Whether Incoming quantity (in replen calcs) should be lowered by OH (PO Eval purposes)!">
                                        <Statistic
                                            title="Remove OH from Inc (Replen):"
                                            value={poEvalIncoming ? "YES" : "NO"}
                                            formatter={(val) => (
                                                <Select
                                                    size={"small"}
                                                    bordered={false}
                                                    style={{width: "65px", fontSize: "1.4vh"}}
                                                    value={poEvalIncoming ? "YES" : "NO"}
                                                    onChange={(val) => handlePoEvalIncomingEdit(val)}
                                                >
                                                    <Select.Option value={`YES`}>YES</Select.Option>
                                                    <Select.Option value={`NO`}>NO</Select.Option>
                                                </Select>
                                            )}
                                        />
                                    </Tooltip>
                                </>
                            )}
                        </Space>
                        <Space direction="vertical">
                            <Space style={{width: "100%"}}>
                                <Button
                                    block={true}
                                    type="primary"
                                    loading={reviewUpload}
                                    onClick={() => {
                                        setReviewUpload(true);
                                        const reviewData = tableData.map((prod) => prod);
                                        reviewData.forEach(
                                            (prod) => (prod.sourceCollection = prod.sourceCollection.split("_")[0] + "_review")
                                        );
                                        console.log("Review data", reviewData);
                                        updateProduct(reviewData)
                                            .then((res) => res.json())
                                            .then((res) => {
                                                console.log(res);
                                                if (res.error) {
                                                    message.error(`Failed to save collection for review - ${res.error}`);
                                                } else {
                                                    message.success("Saved collection for review!");
                                                }
                                                setReviewUpload(false);
                                            })
                                            .catch((ex) => {
                                                notification.error({
                                                    message: "Failed to save collection for review!",
                                                    description: ex.toString(),
                                                });
                                            });
                                    }}
                                >
                                    Save For Review
                                </Button>
                                <Button
                                    type="primary"
                                    block
                                    onClick={() => {
                                        let finalString = "";
                                        finalString += `• Settings\n`;
                                        // finalString += `\t• Shipping Fee Multi: $${shippingFeeMulti.toFixed(2)}\n`;
                                        // finalString += `\t• Shipping Fee Base: $${shippingFeeBase.toFixed(2)}\n`;
                                        finalString += `\t• Target DIS: ${targetDIS >= 0 ? targetDIS : "ROI-based"}\n`;
                                        finalString += `\t• Prep Delay: ${prepDelay}\n`;
                                        finalString += `\t• Net Term: ${netTerm}\n`;
                                        finalString += `\t• CC Accepted: ${ccAccepted ? "YES" : "NO"}\n`;
                                        finalString += `\t• SS timeframe: ${sellersnapTimespan === "true" ? "30" : sellersnapTimespan}\n`;
                                        finalString += `\t• Desired ROI: ${(desiredRoi * 100).toFixed(0)}%\n`;
                                        // finalString += `\t• Return: \n`;
                                        finalString += `• The road so far\n`;
                                        finalString += `\t• Saved Spend: ${tableData
                                            .filter((row) => row.saved)
                                            .reduce((acc, curr) => (acc += curr.BuyQTY * curr.AskPrice), 0)
                                            .toLocaleString("en-US", {style: "currency", currency: "USD"})}\n`;
                                        finalString += `\t• Avg ROI: ${(
                                            (tableData
                                                .filter((row) => row.saved)
                                                .reduce((acc, curr) => (acc += curr.Profit * curr.BuyQTY), 0) /
                                                (tableData
                                                    .filter((row) => row.saved)
                                                    .reduce((acc, curr) => (acc += curr.AskPrice * curr.BuyQTY), 0) || 1)) *
                                            100
                                        ).toFixed(2)}%\n`;
                                        finalString += `\t• Avg GMROI: ${(
                                            tableData
                                                .filter((row) => row.saved)
                                                .reduce((acc, curr) => (acc += curr.wsGMROI * curr.OrderAmount), 0) /
                                            (tableData.filter((row) => row.saved).reduce((acc, curr) => (acc += curr.OrderAmount), 0) || 1)
                                        ).toFixed(2)}\n`;
                                        finalString += `\t• Saved Qtys: ${tableData
                                            .filter((row) => row.saved)
                                            .reduce((acc, curr) => (acc += curr.BuyQTY), 0)
                                            .toLocaleString()}\n`;
                                        finalString += `\t• Saved SKUs: ${tableData.filter((row) => row.saved).length}\n`;
                                        finalString += `\t• Avg TTS: ${(
                                            tableData
                                                .filter((row) => row.saved)
                                                .reduce((acc, curr) => (acc += curr.wsGMROITTS * curr.OrderAmount), 0) /
                                            (tableData.filter((row) => row.saved).reduce((acc, curr) => (acc += curr.OrderAmount), 0) || 1)
                                        ).toFixed(0)} \n`;
                                        finalString += `• To fill PD\n`;
                                        // finalString += `\t• Ship Later: ${tableData.filter(row => row.saved).some((item) => item.ShipLater > 0) ? 'Yes' : 'No'}\n`
                                        finalString += `\t• Ship Later: *FILL IN*\n`;
                                        finalString += `\t• Non-UPC matches: *FILL IN*\n`;
                                        finalString += `\t• Multi-ASIN: *FILL IN*\n`;
                                        finalString += `\t• Packs: ${
                                            tableData.filter((row) => row.saved).some((item) => item.packSize && item.packSize > 1)
                                                ? "Yes"
                                                : "No"
                                        }\n`;
                                        finalString += `\t• Bundles: ${
                                            tableData.filter((row) => row.saved).some((item) => item.isBundle) ? "Yes" : "No"
                                        }\n`;
                                        finalString += `\t• Restricted: ${
                                            tableData.filter((row) => row.saved).some((item) => item.isRestricted) ? "Yes" : "No"
                                        }\n`;
                                        finalString += `\t• MF items: FILL IN\n`;
                                        finalString += `\t• MAP Items: ${
                                            tableData.filter((row) => row.saved).some((item) => item.MAP) ? "Yes" : "No"
                                        }\n`;

                                        copyToClipboard(finalString, "Summary Data");
                                    }}
                                >
                                    Copy Stats
                                </Button>
                            </Space>
                            <ExcelDownload
                                data={tableData}
                                filteredData={filteredData}
                                isLoading={isLoading}
                                collection={collection}
                                columnData={finalColumns.filter(
                                    (column) => (column as any).dataIndex && !hiddenColumns.includes(getColumnTitle(column))
                                )}
                            />
                        </Space>
                    </Space>
                </CollapsePanel>
                <CollapsePanel key={3} header={"Column presets"}>
                    <Space align="center" style={{width: "100%", overflow: "auto"}}>
                        <Button
                            style={{marginBottom: "4px", marginRight: "2px"}}
                            size="small"
                            type="primary"
                            onClick={() => {
                                setSelectedColumnPreset("WS_DEFAULT_PRESET");
                                setSelectedColumnPresetArray([]);
                                handleBuyQTYPresetChange();
                            }}
                        >
                            Default
                        </Button>
                        <Button
                            style={{marginBottom: "4px", marginRight: "2px"}}
                            size="small"
                            type="primary"
                            onClick={() => {
                                setSelectedColumnPreset("WS_LUCAS_PRESET");
                                setSelectedColumnPresetArray([]);
                                handleBuyQTYPresetChange();
                            }}
                        >
                            Lucas
                        </Button>
                        <Button
                            style={{marginBottom: "4px", marginRight: "2px"}}
                            size="small"
                            type="primary"
                            onClick={() => {
                                setSelectedColumnPreset("WS_LUCAS_SECOND_PRESET");
                                setSelectedColumnPresetArray([]);
                                handleBuyQTYPresetChange();
                            }}
                        >
                            Lucas V2
                        </Button>
                        <Button
                            style={{marginBottom: "4px", marginRight: "2px"}}
                            size="small"
                            type="primary"
                            onClick={() => {
                                setSelectedColumnPreset("WS_LUCAS_THIRD_PRESET");
                                setSelectedColumnPresetArray([]);
                                handleBuyQTYPresetChange();
                            }}
                        >
                            Lucas V3
                        </Button>
                        <Button
                            style={{marginBottom: "4px", marginRight: "2px"}}
                            size="small"
                            type="primary"
                            onClick={() => {
                                setSelectedColumnPreset("WS_LUCAS_ADIDAS_PRESET");
                                setSelectedColumnPresetArray([]);
                                handleBuyQTYPresetChange();
                            }}
                        >
                            Lucas Adidas
                        </Button>
                        <Button
                            style={{marginBottom: "4px", marginRight: "2px"}}
                            size="small"
                            type="primary"
                            onClick={() => {
                                setSelectedColumnPreset("WS_MONITOR_SUPPRESSED_PRESET");
                                setSelectedColumnPresetArray([]);
                                handleBuyQTYPresetChange();
                            }}
                        >
                            Monitor Suppressed
                        </Button>
                        <Button
                            style={{marginBottom: "4px", marginRight: "2px"}}
                            size="small"
                            type="primary"
                            onClick={() => {
                                setSelectedColumnPreset("WS_FUTURE_PRESET");
                                setSelectedColumnPresetArray([]);
                                handleBuyQTYPresetChange();
                            }}
                        >
                            Future
                        </Button>
                        <Divider type="vertical"></Divider>
                        {/* <Button 
                            style={{marginBottom: '4px', marginRight: '2px'}} 
                            size="small" 
                            type="primary"
                            disabled={true}
                            onClick={() => {
                                setSelectedColumnPreset('WS_OMS_S3_PRESET')
                                setSelectedColumnPresetArray([])
                                handleBuyQTYPresetChange();
                            }}
                        >
                            [OMS] Stage 3
                        </Button> */}
                        <Button
                            style={{marginBottom: "4px", marginRight: "2px"}}
                            size="small"
                            type="primary"
                            onClick={() => {
                                setSelectedColumnPreset("WS_DASHBOARD_PRESET");
                                setSelectedColumnPresetArray([]);
                                handleBuyQTYPresetChange();
                            }}
                        >
                            [DB] Dashboard
                        </Button>
                        <Divider type="vertical"></Divider>
                        <Button
                            style={{marginBottom: "4px", marginRight: "2px"}}
                            size="small"
                            type="primary"
                            onClick={() => {
                                setSelectedColumnPreset("WS_REPLEN_PRESET");
                                setSelectedColumnPresetArray([]);
                                handleBuyQTYPresetChange();
                            }}
                        >
                            [DEF] Replens
                        </Button>
                        <Button
                            style={{marginBottom: "4px", marginRight: "2px"}}
                            size="small"
                            type="primary"
                            onClick={() => {
                                setSelectedColumnPreset("WS_SHIPLATER_REPLEN_PRESET");
                                setSelectedColumnPresetArray([]);
                                handleBuyQTYPresetChange();
                            }}
                        >
                            [DEF] ShipLater
                        </Button>
                        <Button
                            style={{marginBottom: "4px", marginRight: "2px"}}
                            size="small"
                            type="primary"
                            onClick={() => {
                                setSelectedColumnPreset("WS_CAIO_REPLEN_PRESET");
                                setSelectedColumnPresetArray([]);
                                handleBuyQTYPresetChange();
                            }}
                        >
                            [CAIO] Replens
                        </Button>
                    </Space>
                </CollapsePanel>
                <CollapsePanel key={4} header={"Filter presets - hover for descriptions"}>
                    <WholesaleFilterPresets
                        changeSellerSnap={changeSellerSnap}
                        selectedFilterPreset={selectedFilterPreset}
                        setSelectedFilterPreset={setSelectedFilterPreset}
                    />
                </CollapsePanel>
                <CollapsePanel key={5} header={`Bulk actions - hover for descriptions`}>
                    <WholesaleBulkActions
                        tableData={tableData}
                        filteredData={filteredData}
                        updateTableData={updateTableData}
                        modal={modal}
                    />
                </CollapsePanel>
            </Collapse>
            <Divider />
            <SeparatedTable
                setHiddenColumns={setHiddenColumns}
                tableData={tableData}
                allColumns={finalColumns}
                columns={finalColumns.filter((column) => !hiddenColumns.includes(getColumnTitle(column)))}
                setFilteredData={setFilteredData}
                setFilteredColumns={setFilteredColumns}
                setSelectedColumnPresetArray={setSelectedColumnPresetArray}
                selectedColumnPreset={selectedColumnPreset}
                selectedFilterPreset={selectedFilterPreset}
                key={selectedFilterPreset}
            />
        </>
    );
};

export default ExperimentalTable;
