import {
    DollarCircleOutlined,
    RetweetOutlined,
    VerticalAlignTopOutlined,
    VerticalAlignBottomOutlined,
    NumberOutlined,
} from "@ant-design/icons";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {Button, Col, Input, InputRef, notification, Row, Space, Switch, App} from "antd";
import dayjs from "dayjs";
import _ from "lodash";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useAuth} from "../../../contexts/AuthContext";
import {grabSellerSnap} from "../../../services/SellerSnapService";
import {getOMS, updateOMS} from "../../../services/WholesaleService";
import {OMSItem, OMSItemRenderType} from "../../../types/OmegaTypes";
import {CSVDownload} from "../../utilities/CSVDownload";
import {stage3Transformation, updateShipNow, updateShipTotal, updateStage3Values, updateToMap} from "../../utilities/OMSCalculations";
import {makeColumnsEditable, makeStage3Columns} from "../../utilities/OMSColumns";
import {validateOMSStage3Columns} from "../../utilities/TableDataValidation";
import {UnsavedChangesHandler} from "../../utilities/UnsavedChangesHandler";
import {
    BulkActionsButton,
    BulkActionType,
    makeCopyAsinsAction,
    useSelectMultipleColumnModal,
    useNumberColumnModal,
} from "../table/BulkActions";
import {EditableColumnType} from "../table/EditableCell";
import EditableTable, {EditableTableHandle} from "../table/EditableTable";
import {OMSTableTitle} from "../table/OMSTableTitle";
import * as dataForge from "data-forge";
import {confirmModal} from "../../wholesale/table/WholesaleUtilities";

export const Stage3Browser: React.FC = () => {
    const {modal} = App.useApp();
    const {currentUser} = useAuth();
    const queryClient = useQueryClient();
    const searchInputRef = useRef<InputRef>(null);
    const [tableData, setTableData] = useState<OMSItemRenderType[]>([]);
    const [fetchTimestamp, setFetchTimestamp] = useState<Date>(new Date());
    const [lastUpdateTimestamp, setLastUpdateTimestamp] = useState<Date>(fetchTimestamp);
    const [editable, setEditable] = useState(false);
    const posInputRef = useRef<InputRef>(null);
    const [pos, setPos] = useState<string | undefined>();
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const filteredTableData = useMemo(() => tableData.filter((item) => !item.deleted), [tableData]);
    const [selectMultipleModal, selectMultipleModalContext] = useSelectMultipleColumnModal(filteredTableData, selectedRowKeys);
    const [numberModal, numberModalContext] = useNumberColumnModal(filteredTableData, selectedRowKeys);
    const tableRef = useRef<EditableTableHandle<OMSItemRenderType>>(null);

    const {
        data: omsData,
        isLoading: omsDataLoading,
        isRefetching,
        refetch,
        error,
    } = useQuery({
        queryKey: ["oms_stage3_data", pos],
        queryFn: async () => {
            if (!pos) return [];

            const token = await currentUser!.getIdToken();
            const POs = pos
                .split(",")
                .map((po) => po.trim())
                .filter((po) => po.length > 0);

            if (token && POs.length > 0) {
                let products = await getOMS(token, pos, "stagethree");
                products = await grabSellerSnap(products.reverse());
                return updateStage3Values(
                    products.map((item, idx) => ({
                        ...item,
                        key: idx,
                    })),
                    []
                );
            }
            return [];
        },
        enabled: false,
        staleTime: Infinity,
        initialData: [],
    });

    useEffect(() => {
        if (error) {
            notification.error({
                message: "Fetch error",
                // @ts-ignore
                description: error.message,
            });
        }
    }, [error]);

    const updateData = (newData: OMSItem[], timestamp?: Date) => {
        setTableData(
            newData
                .map((item, idx) => ({
                    ...item,
                    key: idx,
                }))
                .map((item) => {
                    if (item.MAP && item.MAP > 0 && !item["Seasonal Tag"]?.includes("MAP")) {
                        item["Seasonal Tag"] = (item["Seasonal Tag"]?.length ?? 0) > 0 ? `${item["Seasonal Tag"]};MAP` : "MAP";
                    }

                    return item;
                })
        );
        setLastUpdateTimestamp(timestamp || new Date());
    };

    const omsMutation = useMutation({
        mutationFn: async (data: OMSItem[]) => {
            const token = await currentUser!.getIdToken();
            const dataToSave: OMSItem[] = data.map(({key, ...item}) => ({
                ...item,
                "S3_Pushed?": "YES",
                "S3_Pushed_By": currentUser!.email || "NA",
            }));
            await updateOMS(
                token,
                dataToSave.map((item) =>
                    // @ts-ignore
                    _.omit(item, [
                        "Ship_Total",
                        "ReferralFee",
                        "FulfillmentFee",
                        "ShipFee",
                        "Profit",
                        "ROI",
                        "MVSP",
                        "RoundUp",
                        "SellerSnapData",
                    ])
                )
            );
            return dataToSave;
        },
        onSuccess: (data) => {
            queryClient.setQueryData(
                ["oms_stage3_data", pos],
                data.map((item, idx) => ({...item, key: idx}))
            );
            setFetchTimestamp(new Date());
            // Automatically update data in PO Browser
            queryClient.refetchQueries({queryKey: ["oms_data"]});
            notification.success({
                message: "Success!",
            });
        },
        onError: (error) => {
            notification.error({
                message: "Upload error",
                // @ts-ignore
                description: error.message,
            });
        },
    });

    useEffect(() => {
        refetch();
    }, [pos, refetch]);

    const getPOs = () => {
        let posText = posInputRef.current?.input?.value;
        if (posText) {
            // Remove whitespaces
            // posText = posText.replace(/\s/g, '');

            // If the pos string isn't changed, force a refetch
            if (posText !== pos) {
                setPos(posText);
            } else {
                refetch();
            }
        }
    };

    const syncTableData = useCallback(() => {
        if (omsData) {
            const newData = updateStage3Values(omsData, []).map((item, idx) => ({
                ...item,
                key: idx,
            }));
            setTableData(newData);
            setLastUpdateTimestamp(fetchTimestamp);
        }
        // eslint-disable-next-line
    }, [omsData]);

    useEffect(() => {
        syncTableData();
    }, [omsData, syncTableData]);

    const handleSave = useCallback(
        (row: OMSItemRenderType, column?: keyof OMSItemRenderType) => {
            const newData = [...tableData];
            const index = newData.findIndex((item) => row.key === item.key);
            const item = newData[index];
            const newRow = stage3Transformation(item, row, column);
            newData.splice(index, 1, {
                ...item,
                ...newRow,
            });
            updateData(newData);
        },
        [tableData]
    );

    const columns = useMemo<EditableColumnType<OMSItemRenderType>[]>(() => {
        const stage3Columns = makeStage3Columns(searchInputRef);
        if (editable) {
            return makeColumnsEditable(stage3Columns, handleSave);
        } else {
            return stage3Columns;
        }
    }, [handleSave, editable]);

    const onSaveChanges = () => {
        if (validateOMSStage3Columns(tableData).length === 0) {
            const shipLaterData = tableData.filter((row) => row.Shipped && row.Shipped > 0 && row["Ship Later"] && row["Ship Later"] > 0);

            if (shipLaterData.length > 0) {
                confirmModal(
                    modal,
                    () => {
                        omsMutation.mutate(tableData);
                    },
                    `If you've edited ShipLater quantities, you must notify the Logistics channel since the items have already been shipped!`
                );
            } else {
                omsMutation.mutate(tableData);
            }
        }
    };

    const bulkActions: BulkActionType[] = [
        makeCopyAsinsAction(filteredTableData, selectedRowKeys),
        {
            label: "Set desired ROI",
            onClick: () =>
                numberModal.show({
                    columnName: "DesiredROI",
                    title: "desired ROI",
                    onUpdate: (newData) => {
                        // Update min price for every row according
                        // to the new desired ROI
                        updateData(updateStage3Values(newData, selectedRowKeys, "DesiredROI"));
                    },
                }),
            icon: <DollarCircleOutlined />,
            disabled: selectedRowKeys.length === 0,
        },
        {
            label: "Set Min Price",
            onClick: () =>
                numberModal.show({
                    columnName: "Min Price",
                    title: "Min Price",
                    onUpdate: (newData) => {
                        // Recalculate all values due to min price change
                        updateData(updateStage3Values(newData, selectedRowKeys));
                    },
                }),
            icon: <VerticalAlignBottomOutlined />,
            disabled: selectedRowKeys.length === 0,
        },
        {
            label: "Set Max Price/List",
            onClick: () =>
                numberModal.show({
                    columnName: "Max Price/List",
                    title: "Max Price/List",
                    onUpdate: (newData) => {
                        // Recalculate all values due to max price change
                        updateData(updateStage3Values(newData, selectedRowKeys));
                    },
                }),
            icon: <VerticalAlignTopOutlined />,
            disabled: selectedRowKeys.length === 0,
        },
        {
            label: "Fill Ship Now",
            onClick: () => {
                const newData = updateShipNow(filteredTableData, selectedRowKeys);
                updateData(newData.map((row) => updateShipTotal(row, row)));
            },
            icon: <NumberOutlined />,
            disabled: selectedRowKeys.length === 0,
        },
        {
            label: "Change seasonal tags",
            onClick: () => {
                selectMultipleModal.show({
                    columnName: "Seasonal Tag",
                    title: "seasonal tag",
                    onUpdate: updateData,
                });
            },
            icon: <RetweetOutlined />,
            disabled: selectedRowKeys.length === 0,
        },
        {
            label: "Set Min/Max to MAP",
            onClick: () => {
                const newData = updateStage3Values(updateToMap(filteredTableData, selectedRowKeys), selectedRowKeys);
                updateData(newData);
            },
            icon: <NumberOutlined />,
            disabled: selectedRowKeys.length === 0,
        },
    ];

    const tableTitle = () => (
        <OMSTableTitle
            tableData={filteredTableData}
            actions={
                <>
                    <UnsavedChangesHandler
                        isSaved={lastUpdateTimestamp <= fetchTimestamp}
                        isLoading={omsMutation.isPending}
                        disableWhenSaved={filteredTableData.length === 0}
                        onDiscardChanges={syncTableData}
                        onSaveChanges={onSaveChanges}
                    />
                    <CSVDownload
                        collection={`Stage_3_Export_${dayjs().format("MM-DD-YY")}`}
                        data={tableRef.current?.currentData || []}
                        isLoading={!tableRef.current}
                        parse={(data) => {
                            if (validateOMSStage3Columns(data).length > 0) return undefined;

                            return new dataForge.DataFrame(data).subset(columns.map((col) => col.dataIndex as string)).toArray();
                        }}
                    />
                    <Space>
                        Editable: <Switch checked={editable} onChange={setEditable} />
                    </Space>
                    <BulkActionsButton actions={bulkActions} />
                </>
            }
        />
    );

    useEffect(() => {
        const onBeforeUnload = (e: any) => {
            if (lastUpdateTimestamp > fetchTimestamp) {
                e.preventDefault();
                e.returnValue = "";
            }
        };

        window.addEventListener("beforeunload", onBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", onBeforeUnload);
        };
    }, [fetchTimestamp, lastUpdateTimestamp]);

    return (
        <Space direction="vertical" style={{width: "100%"}}>
            <Row>
                <Col span={22}>
                    <Input placeholder="Enter POs" allowClear ref={posInputRef} />
                </Col>
                <Col span={2}>
                    <Button loading={omsDataLoading || isRefetching} onClick={getPOs}>
                        Get
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <EditableTable<OMSItemRenderType>
                        ref={tableRef}
                        title={tableTitle}
                        tableData={filteredTableData}
                        columns={columns}
                        loading={omsDataLoading || isRefetching}
                        onRowSelectionChange={(rowKeys, availableRowKeys) =>
                            setSelectedRowKeys(rowKeys.length === 0 ? availableRowKeys : rowKeys)
                        }
                    />
                </Col>
            </Row>
            {numberModalContext}
            {selectMultipleModalContext}
        </Space>
    );
};
