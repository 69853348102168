import {
    ActiveSellersData,
    BelowMinData,
    CompetitivePriceData,
    InventoryHealthItem,
    NoteItem,
    OrderSummaryByDaysAggData,
    PectyRoot,
    SalesTrafficItem,
    SingularItem,
} from "../types/OmegaTypes";
import {BASE_URL} from "./WholesaleService";
import {SlackApiToken} from "./constants";

const DEVELOPMENT = false; // process.env.NODE_ENV === 'development'
export function GetSheet({token, spreadsheetId, range}: {token: string; spreadsheetId: string; range: string}) {
    return fetch(`${BASE_URL}/sheets?firebase_token=${token}&spreadsheetId=${spreadsheetId}&range=${range}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then((resp) => resp.json())
        .then((resp) => resp.result)
        .catch((err) => console.log(err));
}

export function getSalesTraffic(token: string): Promise<SalesTrafficItem[]> {
    return fetch(`${BASE_URL}/repricer?firebase_token=${token}&collection=sales_traffic&db=luOjCSlnssfwomE0Lic6gHzmDcw1`)
        .then((res) => res.json())
        .then((data) => (DEVELOPMENT ? [] : data.result));
}

export function postManageDB(token: string, db: string, collection: string, listings: any[], keys: string[]) {
    return fetch(`${BASE_URL}/managedb/add?firebase_token=${token}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            listings: listings,
            keys: keys,
            db: db,
            collection: collection,
        }),
    }).then((res) => res.json());
}
export function deleteManageDB(token: string, db: string, collection: string, master_key: string, query: string) {
    return fetch(
        `${BASE_URL}/managedb/delete?firebase_token=${token}&db=${db}&collection=${collection}&master_key=${master_key}&query=${query}`,
        {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
            },
        }
    ).then((res) => res.json());
}

export function getManageDB(token: string, db: string, collection: string) {
    return fetch(`${BASE_URL}/managedb/getAll?firebase_token=${token}&db=${db}&collection=${collection}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then((res) => res.json())
        .then((res) => res.result);
}

export function getFilteredManageDB(token: string, db: string, collection: string, master_key: string, query: string) {
    return fetch(
        `${BASE_URL}/managedb/getOne?firebase_token=${token}&db=${db}&collection=${collection}&master_key=${master_key}&query=${query}`,
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        }
    )
        .then((res) => res.json())
        .then((res) => res.result);
}

export function getAdidasUPC(token: string) {
    return getManageDB(token, "global_wholesale", "adidas_upcs");
}

export function getAdidasUPCNew(token: string) {
    return getManageDB(token, "global_wholesale", "adidas_pastweek");
}

export function getShipLaterExpanded(token: string) {
    return getManageDB(token, "prelisting", "shiplater_expanded");
}

export function postAdidasUPC(token: string, data: any[]) {
    return postManageDB(token, "global_wholesale", "adidas_upcs", data, ["Style", "Size"]);
}

export function postAdidasUPCNew(token: string, data: any[]) {
    return postManageDB(token, "global_wholesale", "adidas_pastweek", data, ["Style", "Size"]);
}

export function saveNote(token: string, note: any, productData: Omit<SingularItem, "notes">) {
    return postManageDB(token, "singular", "notes", [{...note, productData}], []);
}

const recreateInvHealth = (item: any): InventoryHealthItem => {
    const newObject = {
        "_id": item["_id"],
        "snapshot-date": item["snapshot_date"],
        "sku": item["sku"],
        "fnsku": item["fnsku"],
        "asin": item["asin"],
        "product-name": item["product_name"],
        "condition": item["condition"],
        "available": item["available"],
        "pending-removal-quantity": item["pending_removal_quantity"],
        "inv-age-0-to-90-days": item["inv_age_0_to_90_days"],
        "inv-age-91-to-180-days": item["inv_age_91_to_180_days"],
        "inv-age-181-to-270-days": item["inv_age_181_to_270_days"],
        "inv-age-271-to-365-days": item["inv_age_271_to_365_days"],
        "inv-age-365-plus-days": item["inv_age_365_plus_days"],
        "currency": item["currency"],
        "qty-to-be-charged-ltsf-9-mo": item["qty_to_be_charged_ltsf_9_mo"],
        "projected-ltsf-9-mo": item["projected_ltsf_9_mo"],
        "qty-to-be-charged-ltsf-12-mo": item["qty_to_be_charged_ltsf_12_mo"],
        "estimated-ltsf-next-charge": item["estimated_ltsf_next_charge"],
        "units-shipped-t7": item["units_shipped_t7"],
        "units-shipped-t30": item["units_shipped_t30"],
        "units-shipped-t60": item["units_shipped_t60"],
        "units-shipped-t90": item["units_shipped_t90"],
        "alert": item["alert"],
        "your-price": item["your_price"],
        "sales-price": item["sales_price"],
        "lowest-price-new-plus-shipping": item["lowest_price_new_plus_shipping"],
        "lowest-price-used": item["lowest_price_used"],
        "recommended-action": item["recommended_action"],
        "healthy-inventory-level": item["healthy_inventory_level"],
        "recommended-sales-price": item["recommended_sales_price"],
        "recommended-sale-duration-days": item["recommended_sale_duration_days"],
        "recommended-removal-quantity": item["recommended_removal_quantity"],
        "estimated-cost-savings-of-recommended-actions": item["estimated_cost_savings_of_recommended_actions"],
        "sell-through": item["sell_through"],
        "item-volume": item["item_volume"],
        "volume-unit-measurement": item["volume_unit_measurement"],
        "storage-type": item["storage_type"],
        "storage-volume": item["storage_volume"],
        "marketplace": item["marketplace"],
        "product-group": item["product_group"],
        "sales-rank": item["sales_rank"],
        "days-of-supply": item["days_of_supply"],
        "estimated-excess-quantity": item["estimated_excess_quantity"],
        "weeks-of-cover-t30": item["weeks_of_cover_t30"],
        "weeks-of-cover-t90": item["weeks_of_cover_t90"],
        "featuredoffer-price": item["featuredoffer_price"],
        "sales-shipped-last-7-days": item["sales_shipped_last_7_days"],
        "sales-shipped-last-30-days": item["sales_shipped_last_30_days"],
        "sales-shipped-last-60-days": item["sales_shipped_last_60_days"],
        "sales-shipped-last-90-days": item["sales_shipped_last_90_days"],
        "inv-age-0-to-30-days": item["inv_age_0_to_30_days"],
        "inv-age-31-to-60-days": item["inv_age_31_to_60_days"],
        "inv-age-61-to-90-days": item["inv_age_61_to_90_days"],
        "inv-age-181-to-330-days": item["inv_age_181_to_330_days"],
        "inv-age-331-to-365-days": item["inv_age_331_to_365_days"],
        "estimated-storage-cost-next-month": item["estimated_storage_cost_next_month"],
        "inbound-quantity": item["inbound_quantity"],
        "inbound-working": item["inbound_working"],
        "inbound-shipped": item["inbound_shipped"],
        "inbound-received": item["inbound_received"],
        "no-sale-last-6-months": item["no_sale_last_6_months"],
        "reserved-quantity": item["reserved_quantity"],
        "unfulfillable-quantity": item["unfulfillable_quantity"],
    };

    return newObject;
};

export function GetSingularItems(token: string, active: boolean, asin?: string) {
    return fetch(
        `https://v2.api.repricer.projectsuite.io/stats/singular_items?token=${token}&active=${active}${asin ? `&asin=${asin}` : ``}`,
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        }
    )
        .then((res) => res.json())
        .then((res) => {
            return res
                .map(
                    ({
                        OutOfSeason,
                        CostSale,
                        OurSeassionsSales,
                        ExistsInOMS,
                        RecommendedBuy,
                        Sold30D,
                        Velocity30D,
                        EstSales30D,
                        DaysInStock,
                        CurrBBPrice,
                        AvgSellPrice,
                        MinPrice,
                        MaxPrice,
                        ListedPrice,
                        ShipLater,
                        ...item
                    }: any) => ({
                        ...item,
                        "Listed Price": ListedPrice,
                        "Min Price": MinPrice,
                        "Max Price": MaxPrice,
                        "Avg Sell Price": AvgSellPrice,
                        "CurrBB Price": CurrBBPrice,
                        "Days In Stock": DaysInStock,
                        "Sold (30D)": Sold30D,
                        "Velocity (30D)": Velocity30D,
                        "EstSales (30D)": EstSales30D || 0,
                        "Recommended Buy": RecommendedBuy,
                        "Exists in OMS": ExistsInOMS,
                        "OurSessions-Sales": OurSeassionsSales,
                        "Cost / Sale": CostSale,
                        "Ship Later": ShipLater,
                    })
                )
                .map((prod: SingularItem) => {
                    if (prod.invHealthItem) {
                        prod.invHealthItem = recreateInvHealth(prod.invHealthItem);
                    }

                    prod.MAP = prod.ssItem.map;
                    prod.repricing = prod.ssItem.repricing;

                    if (prod.ordersData?.["7"].units > 0) {
                        prod["Velocity (7D)"] = prod.ordersData?.["7"].units / prod["Days In Stock"];
                    } else {
                        prod["Velocity (7D)"] = prod.ssItem.velocity_7 ?? 0;
                    }

                    if (prod.orderData?.["30"].units > 0) {
                        prod["Velocity (30D)"] = prod.ordersData?.["30"].units / prod["Days In Stock"];
                    }

                    prod["Sold (7D)"] = prod.ordersData?.["7"].units ?? 0;
                    prod["Sold (30D)"] = prod.ordersData?.["30"].units ?? 0;
                    prod["EstSales (30D)"] =
                        prod.ordersData?.["30"].units && prod["Days In Stock"] > 0 && prod.BBShare > 0
                            ? prod.ordersData?.["30"].units * (30 / prod["Days In Stock"]) * (100 / prod.BBShare)
                            : 0;
                    prod["Sold (14D)"] = prod.ordersData?.["14"].units ?? 0;
                    prod["Sold (60D)"] = prod.ordersData?.["60"].units ?? 0;
                    prod["Sold (90D)"] = prod.ordersData?.["90"].units ?? 0;

                    return prod;
                });
        });
}

export function GetSingularItemList(token: string, active: boolean) {
    return fetch(
        `https://v2.api.repricer.projectsuite.io/stats/singular_items?token=${token}&active=${active}&ss_extract_time=3&only_skus=true`,
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
            },
        }
    ).then((res) => res.json());
}

export function getNotes(token: string, sku?: string): Promise<NoteItem[]> {
    return fetch(`${BASE_URL}/omega/getRecentNotes?firebase_token=${token}${sku ? `&sku=${sku}` : ``}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then((res) => res.json())
        .then((res) => res.result);
}

export function getNoteDetails(token: string, id: string): Promise<NoteItem[]> {
    return fetch(`${BASE_URL}/omega/getNoteDetails?firebase_token=${token}&id=${id}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    })
        .then((res) => res.json())
        .then((res) => res.result);
}

export function requestKeepaData(token: string, asin: string) {
    return fetch(`${BASE_URL}/keepa/getEstSales?asin=${asin}&firebase_token=${token}`, {
        method: "GET",
    }).then((res) => res.json());
}

export function pushSellerSnap(updateData: any[]) {
    return fetch(`https://api.repricer.projectsuite.io/sellersnap`, {
        method: "POST",
        body: JSON.stringify({
            updateData: updateData,
        }),
        headers: {
            "Authorization": "maximumrepricer",
            "Content-Type": "application/json",
        },
    }).then((res) => res.json());
}

export function requestPectyData(asins: string[], collection: string = "singular", mgrequestid: string = "random") {
    return fetch(`https://api.repricer.projectsuite.io/quantity/${collection}/asins`, {
        method: "POST",
        body: JSON.stringify({
            asins: asins,
            mgrequestid: mgrequestid,
        }),
        headers: {
            "Authorization": "maximumrepricer",
            "Content-Type": "application/json",
        },
    }).then((res) => res.json());
}

export function grabPectyData(collection: string = "singular"): Promise<PectyRoot[]> {
    return fetch(`https://api.repricer.projectsuite.io/quantity/${collection}/dump_fresh?days=3`, {
        method: "GET",
        headers: {
            "Authorization": "maximumrepricer",
            "Content-Type": "application/json",
        },
    })
        .then((res) => res.json())
        .then((res) => res.result);
}

export function getCompetitivePrice(asin: string): Promise<CompetitivePriceData[]> {
    return fetch(`https://v2.api.repricer.projectsuite.io/stats/competitive_suggested_prices?key=maximumrepricer&days_num=3&asin=${asin}`, {
        method: "GET",
    })
        .then((res) => res.json())
        .catch((ex) => console.log(ex));
}

export function getTimeBelowMin(): Promise<BelowMinData[]> {
    return fetch(`https://v2.api.repricer.projectsuite.io/stats/buybox_below_min_fast?key=maximumrepricer`, {
        method: "GET",
    })
        .then((res) => res.json())
        .catch((ex) => {
            console.log(ex);
            return [];
        });
}

export function getSellerCount(): Promise<ActiveSellersData[]> {
    return fetch(`https://v2.api.repricer.projectsuite.io/stats/active_sellers?key=maximumrepricer&changes_num=1&days_num=7`, {
        method: "GET",
    })
        .then((res) => res.json())
        .catch((ex) => {
            console.log(ex);
            return [];
        });
}

export function getBackordered(): Promise<any[]> {
    return fetch(`https://v2.api.repricer.projectsuite.io/stats/backordered_products?key=maximumrepricer&days_num=7`, {
        method: "GET",
    })
        .then((res) => res.json())
        .catch((ex) => {
            console.log(ex);
            return [];
        });
}

export function getReturnData(token: string, categoryLevel: boolean = false): Promise<any[]> {
    return GetSheet({
        token,
        spreadsheetId: "1XeUtdYwsCC-06vZf1Ewuwyo6Bb_Ibu6ZqSso9McMeZI",
        range: `${categoryLevel ? "Category" : "ASIN"}!A:G`,
    });
}

export function getOrderSummariesForIDs(
    token: string,
    idType: string,
    idList: string[],
    includePending: boolean = false,
    includeExtended: boolean = false
): Promise<OrderSummaryByDaysAggData[]> {
    return fetch(
        `https://v2.api.repricer.projectsuite.io/stats/order_stats_summary?include_pending=${includePending}&id_type=${idType}&token=${token}&extended=${includeExtended}`,
        {
            method: "POST",
            body: JSON.stringify({
                identifiers: idList,
            }),
            headers: {
                "Content-Type": "application/json",
            },
        }
    )
        .then((res) => res.json())
        .catch((ex) => {
            console.log(ex);
            return [];
        });
}

export function GetASINForSKU(sku: string, mode: string = "omega"): Promise<any[]> {
    return fetch(`${BASE_URL}/sku_asin?sku=${sku}&mode=${mode}`, {
        method: "GET",
    })
        .then((res) => res.json())
        .then((res) => res.result);
}

export function GetSKUForASIN(asin: string): Promise<any[]> {
    return fetch(`${BASE_URL}/sku_asin?asin=${asin}`, {
        method: "GET",
    })
        .then((res) => res.json())
        .then((res) => res.result);
}

export async function UploadToSlack(channel: string, content: any, filename: string, comment: string): Promise<any> {
    const bodyFormData = new FormData();

    console.log("buffer", content);
    bodyFormData.append("channels", channel);
    bodyFormData.append("file", content, filename);
    bodyFormData.append("filename", filename);
    bodyFormData.append("filetype", "xlsx");
    bodyFormData.append("initial_comment", comment);
    bodyFormData.append("token", SlackApiToken);

    return fetch(`https://slack.com/api/files.upload`, {
        method: "POST",
        body: bodyFormData,
    });
}

export async function SendToSlackBlocks(channel: string, blocks: any): Promise<any> {
    const bodyFormData = new FormData();
    bodyFormData.append("channel", channel);
    bodyFormData.append("blocks", JSON.stringify(blocks));
    bodyFormData.append("token", SlackApiToken);

    return fetch(`https://slack.com/api/chat.postMessage`, {
        method: "POST",
        body: bodyFormData,
    }).then((res) => res.json());
}

export async function JoinSlackChannel(channel: string, blocks: any): Promise<any> {
    const bodyFormData = new FormData();
    bodyFormData.append("channel", channel);
    bodyFormData.append("token", SlackApiToken);

    return fetch(`https://slack.com/api/conversations.join`, {
        method: "POST",
        body: bodyFormData,
    }).then((res) => res.json());
}

export async function SendToSlack(channel: string, content: string): Promise<any> {
    const bodyFormData = new FormData();
    bodyFormData.append("channel", channel);
    bodyFormData.append("text", content);
    bodyFormData.append("token", SlackApiToken);

    return fetch(`https://slack.com/api/chat.postMessage`, {
        method: "POST",
        body: bodyFormData,
    });
}
