import { Button, InputRef, Modal, notification, Space, Switch } from 'antd';
import {
    DeleteFilled,
    RetweetOutlined,
    VerticalAlignTopOutlined,
    VerticalAlignBottomOutlined,
    NumberOutlined,
    FileTextOutlined,
    PullRequestOutlined,
    CheckOutlined,
    AuditOutlined,
    ApartmentOutlined,
} from '@ant-design/icons';
import * as dataForge from 'data-forge';
import dayjs from 'dayjs';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
    OMSItem,
    OMSItemRenderType,
    ORDER_TYPES,
    VENDOR_TYPES,
} from '../../../types/OmegaTypes';
import { CSVDownload, csvDownloadFunction } from '../../utilities/CSVDownload';
import { EditableColumnType } from '../table/EditableCell';
import {
    makeColumnsEditable,
    makeMasterColumns,
} from '../../utilities/OMSColumns';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
    deleteOMSByIds,
    generateTrackerData,
    updateOMS,
} from '../../../services/WholesaleService';
import { validateOMSMasterColumns } from '../../utilities/TableDataValidation';
import { UnsavedChangesHandler } from '../../utilities/UnsavedChangesHandler';
import { useAuth } from '../../../contexts/AuthContext';
import {
    BulkActionsButton,
    BulkActionType,
    makeCopyAsinsAction,
    useDateColumnModal,
    useNumberColumnModal,
    useSelectColumnModal,
    useSelectMultipleColumnModal,
} from '../table/BulkActions';
import { OMSTableTitle } from '../table/OMSTableTitle';
import EditableTable, { EditableTableHandle } from '../table/EditableTable';
import { GenerateBuysheetModal } from './GenerateBuysheetModal';
import {
    masterStageTransformation,
    updatePrelisted,
    updateShipNow,
} from '../../utilities/OMSCalculations';
import { GenerateScanpowerModal } from './GenerateScanpowerModal';

const defaultItem: OMSItem = {
    _id: undefined,
    Supplier_Name: '',
    Supplier_Date: new Date(),
    Supplier_PO: '',
    Supplier_SO: '',
    Supplier_SKU: '',
    Supplier_Title: '',
    Supplier_Notes: '',
    UPC: '',
    Quantity: 0,
    Cost: 0,
    Ship_Requested: new Date(),
    MAP: undefined,
    "Replenishable?" : undefined,
    'S1_Pushed?': undefined,
    S1_Pushed_By: undefined,
    'Checked?': undefined,
    'Upc Match?': undefined,
    ASIN: undefined,
    AMZ_Title: undefined,
    AMZ_SizeTier: undefined,
    AMZ_Orphan: undefined,
    'S2_Pushed?': undefined,
    S2_Pushed_By: undefined,
    'Min Price': undefined,
    'Max Price/List': undefined,
    'Seasonal Tag': undefined,
    OrderType: undefined,
    VendorType: undefined,
    'Req_Cancel?': undefined,
    'Listing Issues?': undefined,
    'Ship Now': undefined,
    'Ship Later': undefined,
    Shipped: undefined,
    Unshipped: undefined,
    'S3_Pushed?': undefined,
    S3_Pushed_By: undefined,
    'Prelisted IL?': undefined,
    'S4 Pushed?': undefined,
    S4_Pushed_By: undefined,
};

const POBrowserTable: React.FC<{
    tableData: OMSItemRenderType[];
    onDataChange: (data: OMSItemRenderType[]) => void;
    onDiscardChanges: () => void;
    isSaved: boolean;
    isDataLoading: boolean;
    currentQueryKey: string[]
}> = ({ tableData, onDataChange, isSaved, onDiscardChanges, isDataLoading, currentQueryKey }) => {
    const { currentUser } = useAuth();
    const searchInputRef = useRef<InputRef>(null);
    const queryClient = useQueryClient();
    const [editable, setEditable] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const tableRef = useRef<EditableTableHandle<OMSItemRenderType>>(null);
    const [buysheetModalOpen, setBuysheetModalOpen] = useState(false);
    const [scanpwoerModalOpen, setScanpowerModalOpen] = useState(false);
    const [modal, modalContext] = Modal.useModal();

    const filteredTableData = useMemo(
        () => tableData.filter(item => !item.deleted),
        [tableData]
    );
    const [seasonalTagsModal, seasonalTagsModalContext] =
        useSelectMultipleColumnModal(filteredTableData, selectedRowKeys);
    const [orderTypeModal, orderTypeModalContext] =
        useSelectMultipleColumnModal(
            filteredTableData,
            selectedRowKeys,
            ORDER_TYPES
        );
    const [vendorTypeModal, vendorTypeModalContext] = useSelectColumnModal(
        filteredTableData,
        selectedRowKeys,
        VENDOR_TYPES
    );
    const [numberModal, numberModalContext] = useNumberColumnModal(
        filteredTableData,
        selectedRowKeys
    );
    const [dateModal, dateModalContext] = useDateColumnModal(
        tableData,
        selectedRowKeys
    );

    const omsMutation = useMutation({
        mutationFn: async (data: OMSItem[]) => {
            const token = await currentUser!.getIdToken();
            if (token) {
                const itemsToUpdate = data.filter(item => !item.deleted);
                const itemsToRemove = data.filter(item => item.deleted);
                if (itemsToUpdate.length > 0) {
                    await updateOMS(token, itemsToUpdate);
                }
                if (itemsToRemove.length > 0) {
                    await deleteOMSByIds(
                        token,
                        itemsToRemove.map(item => item._id) as string[]
                    );
                }
                generateTrackerData(token, false).catch(error => {
                    notification.error({
                        message: 'Upload error',
                        // @ts-ignore
                        description: error.message,
                    });
                });
                return data;
            }
            return [];
        },
        onSuccess: data => {
            const omsData = queryClient.getQueryData<OMSItem[]>(currentQueryKey);

            if (omsData) {
                const newDataMap = Object.fromEntries(
                    data.map(nd => [nd._id, nd])
                );
                const newOmsData = omsData.map(item => ({
                    ...item,
                    ...newDataMap[item._id || ''],
                }));

                queryClient.setQueryData(currentQueryKey, newOmsData);
                notification.success({
                    message: 'Success!',
                });
            }
        },
        onError: error => {
            notification.error({
                message: 'Upload error',
                // @ts-ignore
                description: error.message,
            });
        },
    });

    const handleSave = useCallback(
        (row: OMSItemRenderType, column?: keyof OMSItemRenderType) => {
            const newData = [...tableData];
            const index = newData.findIndex(item => row.key === item.key);
            const item = newData[index];
            const newRow = masterStageTransformation(item, row, column);
            newData.splice(index, 1, {
                ...item,
                ...newRow,
            });
            onDataChange(newData);
        },
        [tableData, onDataChange]
    );

    const columns = useMemo<EditableColumnType<OMSItemRenderType>[]>(() => {
        const masterColumns = makeMasterColumns(searchInputRef);
        if (editable) {
            masterColumns.push({
                title: 'Action',
                dataIndex: '',
                key: 'x',
                render: (_, record) => (
                    <Button
                        style={{ marginLeft: 10 }}
                        danger
                        icon={<DeleteFilled />}
                        onClick={() => {
                            const dataCopy = [...tableData];
                            const deletedItem = dataCopy.find(
                                item => item._id === record._id
                            );
                            if (deletedItem) {
                                deletedItem.deleted = true;
                                tableRef.current?.setSelectedRowKeys(
                                    selectedRowKeys.filter(
                                        key => key !== deletedItem.key
                                    )
                                );
                            }
                            onDataChange(dataCopy);
                        }}
                    />
                ),
                fixed: 'right',
                width: '60px',
            });
            return makeColumnsEditable(masterColumns, handleSave);
        } else {
            return masterColumns;
        }
    }, [tableData, onDataChange, handleSave, editable, selectedRowKeys]);

    const bulkActions: BulkActionType[] = [
        makeCopyAsinsAction(filteredTableData, selectedRowKeys),
        {
            label: 'Change seasonal tags',
            onClick: () =>
                seasonalTagsModal.show({
                    columnName: 'Seasonal Tag',
                    title: 'seasonal tag',
                    onUpdate: onDataChange,
                }),
            icon: <RetweetOutlined />,
            disabled: selectedRowKeys.length === 0,
        },
        {
            label: 'Change order type',
            onClick: () =>
                orderTypeModal.show({
                    columnName: 'OrderType',
                    title: 'order type',
                    onUpdate: onDataChange,
                }),
            icon: <AuditOutlined />,
            disabled: selectedRowKeys.length === 0,
        },
        {
            label: 'Change vendor type',
            onClick: () =>
                vendorTypeModal.show({
                    columnName: 'VendorType',
                    title: 'vendor type',
                    onUpdate: onDataChange,
                }),
            icon: <ApartmentOutlined />,
            disabled: selectedRowKeys.length === 0,
        },
        {
            label: 'Set Min Price',
            onClick: () =>
                numberModal.show({
                    columnName: 'Min Price',
                    title: 'Min Price',
                    onUpdate: onDataChange,
                }),
            icon: <VerticalAlignBottomOutlined />,
            disabled: selectedRowKeys.length === 0,
        },
        {
            label: 'Set Max Price/List',
            onClick: () =>
                numberModal.show({
                    columnName: 'Max Price/List',
                    title: 'Max Price/List',
                    onUpdate: onDataChange,
                }),
            icon: <VerticalAlignTopOutlined />,
            disabled: selectedRowKeys.length === 0,
        },
        {
            label: 'Generate BuySheet',
            icon: <FileTextOutlined />,
            onClick: () => {
                setBuysheetModalOpen(true);
            },
        },
        {
            label: 'Generate ScanPower Export',
            icon: <FileTextOutlined />,
            onClick: () => {
                setScanpowerModalOpen(true);
            },
        },
        {
            label: 'Fill Ship Now',
            onClick: () => {
                const newData = updateShipNow(
                    filteredTableData,
                    selectedRowKeys
                );
                onDataChange(newData);
            },
            icon: <NumberOutlined />,
            disabled: selectedRowKeys.length === 0,
        },
        {
            label: 'Set Ship Requested',
            onClick: () =>
                dateModal.show({
                    columnName: 'Ship_Requested',
                    title: 'Ship Requested',
                    onUpdate: onDataChange,
                }),
            icon: <PullRequestOutlined />,
            disabled: selectedRowKeys.length === 0,
        },
        {
            label: 'Mark as prelisted',
            onClick: () =>
                modal.confirm({
                    title: `Are you sure you want to mark ${selectedRowKeys.length} item(s) as prelisted?`,
                    onOk: () =>
                        onDataChange(
                            updatePrelisted(filteredTableData, selectedRowKeys)
                        ),
                }),
            icon: <CheckOutlined />,
            disabled: selectedRowKeys.length === 0,
        },
        {
            label: 'Export PrepInstructions',
            onClick: () =>
                modal.confirm({
                    title: `Are you sure you want to export ${selectedRowKeys.length} item(s)?`,
                    onOk: () => {
                        csvDownloadFunction(
                            filteredTableData
                                .filter(
                                    item =>
                                        selectedRowKeys.length === 0 ||
                                        selectedRowKeys.includes(item.key)
                                )
                                .map(item => ({
                                    Supplier_SKU: item.Supplier_SKU,
                                    UPC: item.UPC,
                                    Description: item.Supplier_Title,
                                    ASIN: item.ASIN,
                                    Quantity: item.Quantity,
                                })),
                            `PrepInstructionExport-${dayjs().format(
                                'MM-DD-YYYY'
                            )}`
                        );
                    },
                }),
            icon: <FileTextOutlined />,
            disabled: selectedRowKeys.length === 0,
        },
    ];

    const tableTitle = () => (
        <OMSTableTitle
            tableData={filteredTableData}
            actions={
                <>
                    <UnsavedChangesHandler
                        isSaved={isSaved}
                        isLoading={omsMutation.isPending}
                        onDiscardChanges={onDiscardChanges}
                        disableWhenSaved
                        onSaveChanges={() => {
                            if (
                                validateOMSMasterColumns(filteredTableData)
                                    .length === 0
                            ) {
                                omsMutation.mutate(tableData);
                            }
                        }}
                    />
                    <Space>
                        Editable:{' '}
                        <Switch defaultChecked={false} onChange={setEditable} />
                    </Space>
                    <CSVDownload
                        collection={`PO_Export_${dayjs().format('MM-DD-YY')}`}
                        data={tableRef.current?.currentData || []}
                        isLoading={!tableRef.current}
                        dateColumns={['Ship_Requested', 'Supplier_Date']}
                        parse={data => {
                            if (validateOMSMasterColumns(data).length > 0)
                                return undefined;

                            return new dataForge.DataFrame(
                                data.map(item => ({ ...defaultItem, ...item }))
                            )
                                .dropSeries([
                                    'key',
                                    'Fill1',
                                    'Fill2',
                                    'Fill3',
                                    'Row',
                                    'deleted',
                                    // 'S1_Pushed?',
                                    // 'S1_Pushed_By',
                                    // 'S2_Pushed?',
                                    // 'S2_Pushed_By',
                                    // 'S3_Pushed?',
                                    // 'S3_Pushed_By',
                                    // 'S4_Pushed?',
                                    // 'S4_Pushed_By',
                                ])
                                .toArray();
                        }}
                    />
                    <BulkActionsButton actions={bulkActions} />
                </>
            }
        />
    );

    // Get POs and SOs from the current data
    const currentPos = useMemo(() => {
        const pos = tableData
            .map(item => item.Supplier_PO || item.Supplier_SO)
            .filter(po => po !== undefined) as string[];
        return Array.from(new Set(pos));
    }, [tableData]);

    return (
        <>
            <EditableTable<OMSItemRenderType>
                ref={tableRef}
                scroll={{ y: '75vh', x: 'max-content' }}
                title={tableTitle}
                tableData={filteredTableData}
                columns={columns}
                onRowSelectionChange={(rowKeys, availableRowKeys) =>
                    setSelectedRowKeys(
                        rowKeys.length === 0 ? availableRowKeys : rowKeys
                    )
                }
                loading={isDataLoading}
            />
            <GenerateBuysheetModal
                pos={currentPos}
                open={buysheetModalOpen}
                onClose={() => setBuysheetModalOpen(false)}
            />
            <GenerateScanpowerModal
                pos={currentPos}
                open={scanpwoerModalOpen}
                onClose={() => setScanpowerModalOpen(false)}
            />
            {modalContext}
            {numberModalContext}
            {seasonalTagsModalContext}
            {orderTypeModalContext}
            {vendorTypeModalContext}
            {dateModalContext}
        </>
    );
};

export default POBrowserTable;
